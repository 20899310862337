.occupation-explorer .custom-tabs {
  margin-top: 5px;
  .mat-tab-label-active {
    border-bottom: none !important;
    &::before {
      content: "";
      border-bottom: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: nth($white-shades, 1);
      @include z-index(minus1);
      @include breakpoint(lg-down) {
        background-color: transparent;
      }
    }
  }
  .mat-tab-label {
    min-width: 33%;
    height: 39px;
    opacity: 0.9;
    width: 95px;
    padding: 0;
    white-space: normal;
    font-size: nth($font-small-point, 1);
    @include breakpoint(sm) {
      height: 40px !important;
    }
    .mat-tab-label-content {
      width: 100% !important;
    }

  }
  .mat-tab-label[aria-setsize="1"] {
    width: 100%;
  }
  .mat-tab-label[aria-setsize="1"] {
    width: 100%;
  }
  .mat-tab-labels{
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .mat-tab-group.mat-primary .mat-tab-label:focus,
  .mat-tab-group.mat-primary .mat-tab-link:focus,
  .mat-tab-nav-bar.mat-primary .mat-tab-label:focus,
  .mat-tab-nav-bar.mat-primary .mat-tab-link:focus {
    background-color: nth($white-shades, 1);
  }
  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: nth($white-shades, 1);
  }
  .mat-tab-header,
  .mat-tab-nav-bar {
    border-bottom: none;
  }
  .mat-tab-body {
    border-top: none !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: nth($white-shades, 1);
    &.mat-tab-body-active{
      overflow-y: inherit !important;
      @include breakpoint(lg-down){
        overflow-y: initial !important;
      }
    }
    .circle-image-list-status {
      .mat-checkbox-layout {
        margin-bottom: 0;
      }
    }
  }

  .mat-ink-bar {
    height: 1px;
  }
  .tab-button .tab-name {
    color: nth($white-shades, 1);
    font-size: nth($font-small-point, 1);
    @include breakpoint(sm){
      font-size: nth($font-small-point, 6);
    }
    &.tab-pane-title {
      font-size: nth($font-small-point, 3);
    }
  }
  .thought-box-input {
    resize: none;
    border-width: 2px;
    color: nth($black-shades, 4);
    padding: 10px;
    &:focus {
      outline: none;
    }
    @include breakpoint(lg-up){
      width: 340px;
    }
  }

  .explorer {
    .thought-box-input {
      border-color: nth($yellow-shades, 3);
      &:focus {
        border-color: nth($yellow-shades, 3);
      }
    }
  }
  .pathfinder {
    .thought-box-input {
      border-color: nth($blue-shades, 14);
      &:focus {
        border-color: nth($blue-shades, 14);
      }
    }
  }
  .careertrekker {
    .thought-box-input {
      border-color: nth($pink-shades, 3);
      &:focus {
        border-color: nth($pink-shades, 3);
      }
    }
  }
  .scout {
    .thought-box-input {
      border-color: nth($green-shades, 11);
      &:focus {
        border-color: nth($green-shades, 11);
      }
    }
  }
  .navigator {
    .thought-box-input {
      border-color: nth($red-shades, 9);
      &:focus {
        border-color: nth($red-shades, 9);
      }
    }
  }
  @include breakpoint(lg-down){
    mat-tab-group {
      .mat-tab-body-wrapper {
        max-height: 250px;
      }
    }
  }
}