.mat-menu-panel {
  max-height: 392px !important;

  // Align the menu items identification mark
  &::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background: #ffff;
    top: calc(100% - 92%);
    transform: rotate(45deg);
    left: 4px;

    @media(min-width:599px) {
      display: none;
    }
  }
}
.save-own-occ-parent {
  .mat-horizontal-stepper-content .mat-raised-button {
    margin: 5px;
  }
  @include breakpoint(sm) {
    padding: 5px 5px 30px;
    &.save-stepper-image{
      margin-top: 80px;
      padding: 0 10px;
    }
    .mobile-bg {
      // background-color: nth($black-shades, 3);
      // padding: 5px 0;
      // height: 40px;
      p {
        margin-bottom: 0;
        text-align: center !important;
      }
      .save-occ-btn{
        position: absolute;
        right: 25px;
        top: 130px;
        @include z-index(sidemenu);
      }
    }
  }
  /**********   tabs    ********************/
  .tabs-card {
    width: 95%;
    box-shadow: none !important;
    background-color: nth($white-shades, 1) !important;
    border-radius: 0 !important;
    border: 0 !important;
    height: 100%;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3) !important;
    margin: 5px auto;
    .card-body {
      height: 180px;
      overflow: hidden;
      background-color: nth($grey-shades, 7);
      .mat-tab-body-wrapper {
        height: 140px;
      }
    }
    .mat-tab-label {
      opacity: 1 !important;
      height: 39px !important;
      padding: 0 !important;
      min-width: 85px;
      width: 33%;
      margin: 0px 2px;
      background-color: none !important;
      .mat-tab-label-content {
        width: 100%;
      }
    }
  }
  .tab-button {
    color: nth($white-shades, 1);
    border-radius: 0px;
    padding: 0 0px;
    min-height: 25px;
    width: 99%;
    border: none;
    box-shadow: none;
    &.green {
      background-color: nth($green-shades, 7);
    }
    &.red {
      background-color: nth($orange-shades, 2);
    }
    &.purple {
      background-color: nth($indigo-shades, 1);
    }
  }
  .mat-tab-header {
    border: none !important;
  }
  .mat-tab-labels {
    display: flex !important;
    justify-content: center !important;
  }
  .mat-tab-label-active .green::before {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid nth($green-shades, 7);
    content: " ";
    position: absolute;
    bottom: 0px;
    left: 35%;
  }
  .mat-ink-bar {
    visibility: hidden !important;
  }
  .circle-img {
    @include custom-circle ($border-color: nth($white-shades, 1), $width: 60px, $height: 60px, $border-radius: 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 1px;
    @include breakpoint(sm){
      margin-top:4px;
    }
    &.trekker-icons{
      img {
        width: 45px;
      }
    }
    img {
      width: 25px;
    }
    &.green {
      background-color: nth($green-shades, 3);
    }
    &.red {
      background-color: nth($orange-shades, 2);
    }
    &.purple {
      background-color: nth($indigo-shades, 1);
    }
  }
  .mat-tab-label-active .red::before {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid nth($orange-shades, 2);
    content: " ";
    position: absolute;
    bottom: 0px;
    left: 35%;
  }
  .mat-tab-label-active .purple::before {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid nth($indigo-shades, 1);
    content: " ";
    position: absolute;
    bottom: 0px;
    left: 35%;
  }
  /************************************/
  .mat-stepper-horizontal,
  .mat-stepper-vertical {
    background-color: transparent !important;
  }
  .save-own-occ {
    padding: 15px 10px;
    .card {
      box-shadow: none;
      background-color: nth($white-shades, 3);
      padding: 10px !important;
    }
    .default-green-btn-bg{
      background-color: nth($green-shades, 2);
    }
    .mandatory-icon {
      color: nth($red-shades, 1);
      font-size: nth($font-small-point, 3);
    }
    .career-creator-nav .next-level {
      top: 30%;
      // @include breakpoint(sm) {
      //   top: 10%;
      // }
      @include breakpoint((sm, lg)) {
        top: 26%;
      }
      &.custom-green-forward{
        background-color: nth($green-shades, 2);
      }
    }
    @include breakpoint(sm) {
      padding: 10px 0 30px;
      // .card {
      //   padding: 0 !important;
      //   background-color: transparent !important;
      //   border: none !important;
      //   box-shadow: none !important;
      // }
    }
    .text-feild {
      background-color: nth($white-shades, 3);
      width: 100%;
      border: 1px solid nth($grey-shades, 1);
      padding: 5px;
      border-radius: 5px;
      box-shadow: 0 0 10px 1px nth($grey-shades, 1);
      @include breakpoint(sm) {
        background-color: nth($white-shades, 1) !important;
      }
      &::placeholder {
        color: nth($grey-shades, 8);
        font-style: italic;
      }
    }
    .save {
      @include position($position: absolute, $bottom:10px, $right:10px);
      @include breakpoint(sm) {
        width:40px !important;
        height: 40px !important;
      }
    }
    .canves-block {
      position: relative;
      &.image-section{
        border-radius: 10px;
        background-color: nth($white-shades, 1);
      }
      .canvas-board-img{
        width: 100%;         
      }

      .canvas-board canvas {
        border-width: 2px;
        border-style: dashed;
        border-color: nth($grey-shades, 8);
        background-color: nth($white-shades, 1);
        border-radius: 10px;
        @include breakpoint(xs) {
          width: 280px;
        }
      }
      .cursor-url {
        cursor: url("https://images.intocareers.org/careertrek/png/eraserone.png") 10 20, auto; 
      }
      .canves-text {
        color: nth($grey-shades, 8);
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          font-size: nth($font-small-point, 2);
          @include breakpoint(sm) {
            font-size:nth($font-small-point, 4);
          }
        }
        i {
          font-size: nth($font-large-points, 2);
          @include breakpoint(sm) {
            font-size: nth($font-small-point, 2);
          }
        }
        img{
          width: 55px;
          @include breakpoint(sm) {
            width: 32px;
          }
        }
      }
    }
    .board-tools {
      ul {
        float: right;
        li {
          justify-content: flex-end;
          .red-bg{
            background-color:nth($board-box-colors,1)!important;
            // important is mentioned as the background-color is picking primarily from sass class li:nth-child(1)  ;
          }
        }
        li button {
          margin: 5px 0px;
          padding: 5px 0;
          text-align: center;
          box-shadow: 2px 3px 2px 1px nth($grey-shades, 13);
          display: flex;
          align-items: center;
          justify-content: center;
          &.done-button{
            background-color: nth($board-box-colors-coo, 1);
          }
        }
        li span,
        i {
          font-size: 0.85rem;
          font-weight: normal;
        }
        img{
          width: 15px;
        }
        @include breakpoint(sm) {
          float: left;
          // width: 100%;
          // display: flex;
          // justify-content: center;
          li {
            margin:6px 2px;
            & button {
              padding: 5px 2px;
              margin: 0;
            }
            & span,
            i {
              font-size: 0.73rem;
            }
          }
        }
      }
      @for $i from 2 through length($board-box-colors-coo) {
        li:nth-child(#{$i}) button {
          color: nth($white-shades, 1);
          background-color: nth($board-box-colors-coo, $i);
        }
      }
    }
    .board-tool-tip {
      background-color: nth($grey-shades, 10);
      padding: 2px 5px;
      border-radius: 5px;
      white-space: normal;
      color: nth($white-shades, 1);
      margin-right: 10px;
      &.white-space-nowrap{
        white-space: nowrap;
      }
    }
    .upload-btn {
      background-color: nth($board-box-colors, 7) !important;
      color: nth($white-shades, 1) !important;
      padding: 0px 6px;
    }
    @include breakpoint(lg-up){
      .drawing-block {
        width: 76%;
        margin: auto;
        height: 330px;
      }
      .draw-edit {
        width: 110px;
        display: flex;
      }
    }
  }
  .saved-canvas-img{
    @include breakpoint(sm){
        width: 100%;
    }
  }
  .save-occ-explorer {
    border: 1px solid nth($black-shades, 11);
    .card-high-data .card-header {
      background-color: nth($green-shades, 3);
    }
    .card-medium-data .card-header {
      background-color: nth($orange-shades, 1);
    }
    .card-low-data .card-header {
      background-color: nth($purple-shades, 1);
    }
    &.explorer-occ {
      .occupation-heading {
        background-color: nth($yellow-shades, 3);
      }
      .image-content {
        .canves-block {
          @include breakpoint(lg-up) {
            border: 1px solid nth($yellow-shades, 3);
          }
        }
      }
      .task-section {
        background-color: nth($yellow-shades, 11);
      }
      .question-block {
        background-color: nth($yellow-shades, 3);
        .question-block-circle-arrow {
          button i{
            color: nth($yellow-shades, 3);
          }
        }
      }
      .questions-section {
        .status-circle {
          border: 2px solid nth($yellow-shades, 3);
        }
      }
    }
    &.trekker-occ {
      .occupation-heading {
        background-color: nth($pink-shades, 3);
      }
      .image-content {
        .canves-block {
          @include breakpoint(lg-up) {
            border: 1px solid nth($pink-shades, 3);
          }
        }
      }
      .task-section {
        background-color: nth($pink-shades, 5);
      }
      .question-block {
        background-color: nth($pink-shades, 3);
        .question-block-circle-arrow {
          button i{
            color: nth($pink-shades, 3);
          }
        }
      }
      .questions-section {
        .status-circle {
          border: 2px solid nth($pink-shades, 3);
        }
      }
    }
    .mat-tab-body-content {
      overflow: inherit;
    }
    .mat-fab.orange-arrow {
      background-color: nth($orange-shades, 2);
      margin: 0px 0px;
    }
    .explorer-task {
      .task-section {
        padding: 20px 50px;
        p {
          text-align: center !important;
        }
        .icon-sec {
          @include breakpoint(sm) {
            margin: 5px 0px !important;
          }
          .circle-icon img{
            width: 45px;
          }
          &.status-block{
            @include breakpoint(sm){
              width: 90%;
            }
          }
          .question-block-text{
            color: nth($white-shades, 1);
            font-weight: bold;
            text-align: left;
            margin-left: 10px;
            @include breakpoint(sm){
              width: 60%;
            }
          }
        }
        .circle-icon{
          height:50px;
        }
        .levels-section {
          justify-content: center;
          .pointer-image {
            width: 40px;
            @include breakpoint(sm) {
              top: -14px;
            }
          }
          .icon-sec {
            position: relative;
          }
        }
      }
      .questions-section {
        ul {
          min-height: 60px;
          &.status-block{
            height: 60px;
            min-height: 60px;
            padding: 0 5px;
            .pointer-icon {
              top: -32px;
            }
          }
          @include breakpoint(sm) {
            min-height: 40px;
            &.status-block{
              border: 1px solid nth($black-shades, 1);
              justify-content: space-evenly;
            }
          }
          .status-circle {
            background-color: transparent;
          }
        }
        .pointer-icon {
          @include breakpoint(sm) {
            top:-15px
          }
        }
        .rating-cards .card {
          background-color: nth($white-shades, 1);
          border: 2px solid nth($white-shades, 1);
        }
      }
    }
    .explorer-task .questions-section {
      .icon-section {
        margin: 10px 0px !important;
      }
    }
    @include breakpoint(sm) {
      .explorer-occuptation .questions-section .icon-sec.status-block li {
        padding: 0 !important;
        margin: 2px;
        .mat-fab{
          line-height: 1px;
        }
      }
      .explorer-task,
      .task-section {
        padding: 0 !important;
      }
    }
  }
  .save-occ-pathfinder {
    border: 1px solid nth($black-shades, 11);
    .card {
      .card-body {
        min-width: auto;
        @include breakpoint(sm-up) {
          min-height: 120px !important;
          height: 120px !important;
        }
      }
    }
    .top-skills-layout {
      padding: 0;
      @include breakpoint(sm-up) {
        padding: 20px;
      }
      .select-cards {
        height: auto;
      }
      .pathfinder-cards {
        .card {
          position: relative;
          .save-career {
            position: absolute;
            right: 5px;
            bottom: 5px;
            img {
              width: 35px;
              height: 30px;
            }
          }
          p {
            color: nth($blue-shades, 1) !important;
            width: 100% !important;
          }
        } 
      }
    }
    
    @include breakpoint(sm-up) {
      // .pathfinder-cards .card-header {
      //   background-color: nth($blue-shades, 1);
      // }
      .explorer-task .task-section p {
        margin-bottom: 0;
      }
    }
    @include breakpoint(sm) {
      .explorer-task .task-section,
      .explorer-task {
        padding: 0 !important;
      }
      .top-skills-layout .select-cards .card {
        height: 60px !important;
        img {
          width: 30px;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
    .mobile-selected-cards {
      &>.card {
        background-color: nth($black-shades, 3) !important;
      }
      .selected-card p {
        color: nth($blue-shades, 1) !important;
      }
    }
    .task-section {
      background-color: nth($blue-shades, 15);
      .image-content {
        @include breakpoint(sm) {
          height: 200px;
        }
        @include breakpoint(xs) {
          height: 150px;
        }
      }
    }
    .occupation-heading {
      background-color: nth($blue-shades, 14);
    }
    .image-content {
      .canves-block {
        @include breakpoint(lg-up) {
          border: 1px solid nth($blue-shades, 14);
        }
      }
    }
  }
  .explorer-task {
    padding: 0;
  }
  .image-content {
    background-color: nth($white-shades, 1);
  }
  .explorer-task {
    padding: 0;
  }

  .image-content {
    background-color: nth($grey-shades, 2);
    .canves-block {
      background-color: nth($white-shades, 1);
      .saved-canvas-img {
        background-color: nth($white-shades, 1);
        margin: 0 auto;
      }
    }
  }

  .save-occ-scout {
    border: 1px solid nth($black-shades, 11);
    .occupation-heading {
      background-color: nth($green-shades, 11);
    }
    .canves-block {
      @include breakpoint(lg-up) {
        border: 1px solid nth($green-shades, 11);
      }
    }
    .questions-section {
      box-shadow: none;
    }
    .task-section {
      background-color: nth($green-shades, 12);
      padding: 30px 0;
      .career-cards {
        margin: 0 15px;
        position: relative;
        h4 {
          padding-top: 20px;
          color: nth($green-shades, 11);
        }
        img {
          width: 100px;
          height: 100px;
          &.ideas-img{
            width: 130px;
          }
        }
        .save-career {
          position: absolute;
          right: 5px;
          bottom: 5px;
          img {
            width: 35px;
            height: 30px;
          }
        }
      } 
    }
  }
  .save-occ-navigator {
    border: 1px solid nth($black-shades, 11);
    .occupation-heading {
      background-color: nth($red-shades, 9);
    }
    .canves-block {
      @include breakpoint(lg-up) {
        border: 1px solid nth($red-shades, 9);
      }
    }
    .questions-section {
      box-shadow: none;
    }
    .task-section {
      background-color: nth($red-shades, 8);
      padding: 30px 0;
      .career-cards {
        margin: 5px;
        position: relative;
        height: 132px;
        h4 {
          padding: 10px 0;
          color: nth($red-shades, 9);
        }
        img {
          margin-top: 10px;
          width: 70px;
          height: 80px;
        }
        .save-career {
          position: absolute;
          right: 5px;
          bottom: 5px;
          img {
            width: 35px;
            height: 30px;
          }
        }
      } 
    }
  }
}

.occupation-heading {
  padding: 10px;
  color:nth($white-shades, 1);

  h2, p {
    color:nth($white-shades, 1);
  }
}

.occ-w-80{
  width: 80px;
}
.delete-occ-btn {
  margin-top: 100px;
  @include breakpoint(sm-up) {
    margin-top: 80px;
  }
  img {
    width: 20px;
  }
}
.custom-occ-header {
  .landing-page-heading {
    margin-top: 30px;
  }
}