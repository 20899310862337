@include breakpoint(sm) {
  .mat-button-wrapper {
    font-weight: bold;
  }
  .mat-bottom-sheet-container {
    min-width: 90vw !important;
  }
  .readmore-mobile {
    padding: 2px;
    border-radius: 5px !important;
    span {
      color: nth($white-shades, 1);
      font-size: nth($font-small-point, 5);
      line-height: 20px !important;
    }
  }
  .circle-eye {
    @include custom-circle ($width: 20px, $height: 20px, $border-radius: 50%);
    margin: 0 3px;
    position: absolute;
    top:-3px;
    background-color: nth($white-shades, 1);
    border: none !important;
  }
}
.qp-save-btn{
  margin-top: 20px;
}
/* tab content position css start*/
.hightab-class{
  padding-left: 15px;
  div{
    justify-content: flex-start;
  }
}
.lowtab-class{
padding-right: 15px;
div{
  justify-content: flex-end;
}
}
/* tab content position css end*/
.readmore-button {
  display: flex !important;
  padding: 0px 4px !important;
  line-height: 20px !important;
  min-width: 95px !important;
  span {
    font-size: nth($font-small-point, 1);
  }
  i {
    font-size: $default-font;
    margin: 0px 4px;
  }
  .circle-eye {
    @include custom-circle ($width: 25px, $height: 25px, $border-radius: 50%);
    position: absolute;
    box-shadow: 0 1px 5px 3px rgba(0, 0, 0, 0.2);
  }
}

.quickpic-intro {
  padding: 10px 5px;
  .restore-button {
    @include position($position: absolute, $top:2%, $right:1%);
  }
  .next-level {
    right: 0px;
  }
  .sub-card {
    padding: 30px 40px !important;
  }
  .data-card {
    border-radius: 5px;
    border: 2px solid #ccc;
    background-color: nth($white-shades, 5);
    width: 100%;
    padding: 10px;

    h4 {
      color: nth($black-shades, 2);
      font-size: $font-small-point;
      margin-bottom: 15px;
    }
    .smile-icons li {
      width: width-calculation(100%, 4);
      text-align: center;
      i {
        font-size: nth($font-large-points, 3);
        @include breakpoint(sm) {
          font-size: nth($font-medium-point, 2) !important;
        }
      }
      span {
        font-size: nth($font-small-point, 9);
      }
    }
  }
  @include breakpoint(sm) {
    padding: 10px 0;
    &.content-box {
      margin-top: 20px;
    }
    p {
      margin-bottom: 10px;
    }
    .menu-button {
      padding: 0px !important;
    }
    .sub-card {
      padding: 0 !important;
      background-color: transparent !important;
      box-shadow: none !important;
      border: none !important;
    }
    .data-card {
      background-color: nth($white-shades, 1); 
    }
    .quick-arrow {
      position: absolute;
      top: 0;
      right: 15px;
    }
  }
  .mat-card:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }
}


/***** Quick Pic Questions page *****/
.quickpic-questions {
  padding: 10px 10px;
  .save-button {
    @include position ($position: absolute, $top:2%, $right:1%);
    line-height: 30px !important;
    @include breakpoint(lg-down) {
      top:1%;
    }
  }
  .smile-icons li {
    margin: 25px 2px;
    position: relative;
    text-align: center;
    .smileys {
      font-size: nth($font-large-points, 2) !important;
      display: inline; 
      @include breakpoint(sm) {
        font-size: nth($font-medium-point, 2) !important;
      }
    }
    .check-tick {
      font-size: nth($font-small-point, 2) !important;
      @include position ($position: absolute,$top:-20px); 
    }
    p {
      font-size: nth($font-small-point, 4) !important;
      margin: 5px 0px !important;
      @include breakpoint(lg-down) {
        font-size: nth($font-small-point, 1) !important;
        text-align: center;
      }
    }
  }
  .score-data {
    margin: 5px;
    @include breakpoint(sm) {
      margin: 0px;
      .progress-bar {
        width:100%;
      }
    }
    // .progress-bar {
    //   background-color: nth($white-shades, 1);
    // }
    p {
      color: nth($purple-shades, 2);
    }
  }
  .qp-purple-clr{
    color: nth($purple-shades, 2);
  }
  .image-frame {
    border: 2px solid nth($grey-shades, 1);
    overflow: hidden;
  }
  .assessment-content-card {
    border-radius: 5px;
    min-height: 300px;
    border: 2px solid rgb(3, 3, 3);
    overflow: hidden;
    background-color: nth($grey-shades, 2);
    padding: 5px;
    @include breakpoint(sm) {
      border-radius:0px;
    }
    .mat-progress-bar-fill::after {
      background-color: nth($purple-shades, 2);
    }
  }
  .question-section {
    padding: 20px 25px 10px;
  }
  .question-card {
    border: 2px solid nth($white-shades, 1);
    border-left: 0px;
    border-top-left-radius: 49px;
    border-bottom-left-radius: 46px;
    box-shadow: 0 -1px 3px 1px rgba(0, 0, 0, 0.3);
    margin: 10px 0;
    p {
      margin: 10px 5px;
      color: nth($white-shades, 1);
      width: 70%;
    }
    .audio-box {
      margin-left: 5px;
      border: 1px solid nth($white-shades, 1) !important; //This mat-button background color is seperated the inside card same background-color
    }
  }
  .circle-icon {
    @include custom-circle ($border-color: nth($white-shades, 1), $width: 70px, $height: 70px, $border-radius: 50%);
    img {
      width: 40px;
    }
    &.extra-css {
      box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12); //for quickpic like buttons.
      margin: auto!important;
      justify-content: center;
     
      @include breakpoint(lg-up){
        @include width-height(100px!important, 100px!important);
      }
    }
    &.active {
      background-color: nth($purple-shades, 2);
      i {
        color: nth($white-shades, 1) !important;
      }
    }
  }
  @include breakpoint(sm) {
    padding: 5px 0px;
    &.content-box {
      margin-top: 20px;
    }
    .question-section {
      padding: 2px 0;
    }
    .assessment-content-card {
      border: 0px solid;
      background-color: transparent;
    }
    .circle-icon {
      @include width-height(40px, 40px);
      display: flex;
      line-height: 36px;
      align-items: center;
      img {
        width: 30px;
      }
    }
    .smile-icons p {
      margin-bottom: 0;
    }
    .smile-icons li {
      margin: 10px 0;
    }
  }
}

.quickpic-result {
  padding:10px;
  .quickpic-result-text{
    width: 250px;
  }
  @include breakpoint(xl-down){
    .quickpic-result-text{
      width: 210px;
    }
  }
  .mat-tab-header-pagination {
    display: none !important; /* No need of left and right pagination-chevron arrows for this block */
  }
  @include breakpoint(sm) {
    &.content-box {
      margin-top: 20px;
    }
    .circle-icon-block{
      flex-wrap: wrap;
      .circle-img {
        margin: 5px 2px;
        border: 2px solid nth($white-shades, 1) !important; //Style for override the attribute dot theme outline color
        &.green {
          background-color: nth($green-shades, 3);
        }
        &.orange {
          background-color: nth($orange-shades, 2);
        }
        &.purple {
          background-color: nth($purple-shades, 1);
        }
      }
    }
  }
  h2 {
    @include breakpoint(lg-down) {
      font-size:nth($font-small-point, 3);
    }
  }
  h4 {
    color: nth($black-shades, 2);
    margin: 15px 0px 5px 0;
  }
  .image-size{
    width: 100px;
    @include breakpoint(lg-down) {
      padding: 5px 0;
      width: 50px;
    }
  }
  .list-scroll-card {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .second-block {
    padding: 10px;
    background-color: nth($grey-shades, 2);
    height: auto;
    width: 100%;
    @include breakpoint ((sm, lg)) {
      padding: 5px;
    }
    h2 {
      @include breakpoint(sm) {
        margin-top: 5px;
      }
    }
    .career-group {
      .card {
        border: 1px solid nth($green-shades, 11);
        .career-card {
          min-height: 70px;
          @include breakpoint(lg-up) {
            min-height: 110px;
          }
        }
      }
      img{
        width: 85px;
        margin-right: 5px;
      }
      p {
        font-size: nth($font-small-point, 2);
        color: nth($black-shades, 2);
      }
      @include breakpoint(lg-up) {
        background-color: nth($green-shades, 12);
        color: nth($white-shades, 1);
        padding: 5px;
        .card {
          border: none;
        }
        img{
          width: 100px;
        }
      }
    }
    .navigator-group{
      text-align: center;
      .card {
        border: 1px solid nth($red-shades, 9);
      }
      .group-work-card {
        padding: 10px 0;
        display: flex;
        align-items: center;
      }
      img{
        width: 32px;
        height: 50px;
        margin-right: 5px;
      }
      p {
        color: nth($black-shades, 2);
        @include breakpoint(lg-down) {
          font-size: nth($font-small-point, 4);
        }
      }
      @include breakpoint(lg-up) {
        background-color: nth($red-shades, 8);
        padding: 5px;
        .card {
          border: none;
        }
        img{
          width: 100px;
          height: 90px;
        }
      }
    }
    .status-card {
      background-color: nth($yellow-shades, 11);
      padding: 5px;
      .card {
        .card-header {
          font-weight: 600;
          &.green {
            background-color: nth($green-shades, 7);
          }
          &.red {
            background-color: nth($orange-shades, 2);
          }
          &.purple {
            background-color: nth($purple-shades, 1);
          }
        }
        .icon-sec li {
          margin: 3px 0;
          width: 80px;
        }
      }
    }
    .personality-block {
      background-color: nth($blue-shades, 15);
      padding: 5px;
      .card {
        color: nth($black-shades, 2);
        p {
          font-size: nth($font-small-point, 2);
        }
      }
    }
    .skill-block {
      background-color: nth($pink-shades, 5);
      padding: 5px;
      .card-header {
        font-weight: 600;
        &.green {
          background-color: nth($green-shades, 7);
        }
        &.red {
          background-color: nth($orange-shades, 2);
        }
        &.purple {
          background-color: nth($purple-shades, 1);
        }
      }
      .status-circle img {
        width: 50px;
      }
    }
    .tabs-status-tabs {
      .tabs-card {
        box-shadow: none !important;
        background-color: transparent !important;
        width: 100%;
        .mat-tab-label {
          min-width: inherit;
          flex: 1;
          .mat-tab-label-content {
            display: block;
          }
        }
      }
    }
    .img-title{
      font-size: nth($font-small-point,4);
      margin-top: 4px;
    }
  }
  .icon-sec{
    &.status-block{
      li {
        height: 115px;
         width: 90px;
       }
    }
  } 
  @include breakpoint(sm) {
    padding: 10px 5px;
    .status-circle {
      font-size: nth($font-small-point, 4);
      @include custom-circle ($border-color: nth($white-shades, 1), $width: 60px, $height: 60px, $background-color: nth($white-shades, 1), $border-radius: 50%);
      img {
        width: 45px;
      }
      margin: 0 3px;
      overflow: hidden;
    }
  }
  @include breakpoint(sm-up) {
    .status-circle {
      font-size: nth($font-small-point, 4);
      @include custom-circle ($border-color: nth($white-shades, 1), $width: 60px, $height: 60px, $background-color: nth($white-shades, 1), $border-radius: 50%);
      // margin: 0 3px;
      overflow: hidden;
    }
    .next-level {
      top: inherit;
      bottom: 0;
    }
    .image-border img {
      width: 250px;
    }
  }
  @include breakpoint(sm) {
    .card .card-body {
      min-height: 90px;
      .mat-tab-header {
        border: none !important;
      }
    }
    .card.tabs-card {
      padding: 0 !important;
      width: 100% !important;
      border: 1px solid nth($purple-shades, 2);
      margin-bottom: 4px;
      .card-header {
        padding: 5px 0px !important;
      }
      .mat-tab-label {
        min-width: inherit !important;
        height: 37px !important;
        flex: 1;
        .mat-tab-label-content {
          display: block;
        }
      }
      .tab-button {
        min-height: 25px;
        height: 25px;
        box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.2);
      }
      .personality-types {
        width: 50%;
        p {
          font-size: nth($font-small-point, 1);
        }
      }
    }
    .mat-stepper-horizontal {
      background-color: transparent !important;
    }
  }
  /**********   tabs    ********************/
  .tabs-card {
    width: 95%;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2) !important;
    background-color: nth($grey-shades, 2) !important;
    .mat-tab-label {
      opacity: 1 !important;
      height: 50px !important;
      padding: 0 !important;
      min-width: 85px;
      background-color: none !important;
      @include breakpoint ((sm, lg)) {
        height: 40px !important;
      }
    }
  }
  .tab-button {
    color: nth($white-shades, 1);
    border-radius: 0px;
    padding: 2px 0px;
    min-height: 35px;
    height: 35px;
    border: none;
    box-shadow: none;
    margin: 0 3px;
    @include breakpoint ((sm, lg)) {
      min-height: 25px;
      height: 25px;
    }
    &.green {
      background-color: nth($green-shades, 7);
    }
    &.red {
      background-color: nth($orange-shades, 2);
    }
    &.purple {
      background-color: nth($purple-shades, 1);
    }
  }
  .mat-tab-labels {
    display: flex !important;
    justify-content: center !important;
  }
  .mat-tab-label-active .green::before {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid nth($green-shades, 7);
    content: " ";
    position: absolute;
    bottom: 0px;
  }
  .mat-ink-bar {
    visibility: hidden !important;
  }
  .circle-img {
    @include custom-circle ($border-color: nth($white-shades, 1), $width: 55px, $height: 55px, $border-radius: 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    img {
      width: 35px;
    }
    @include breakpoint ((sm, lg)) {
      @include custom-circle ( $width: 50px, $height: 50px);
      img {
        width: 30px;
      }
    }
  }
  .mat-tab-label-active .red::before {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid nth($orange-shades, 2);
    content: " ";
    position: absolute;
    bottom: 0px;
  }
  .mat-tab-label-active .purple::before {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid nth($purple-shades, 1);
    content: " ";
    position: absolute;
    bottom: 0px; // left: 35%;
  }
  /************************************/
  .side-task-list .image-card.active::after,
  .side-task-list .image-card.active::before {
    right: -1px;
    left: inherit;
    padding-left: 4.68%;
    @include breakpoint(xl-down) {
      padding-left: 6.6%;
    } 
  }
  .side-task-list .image-card.active::before {
    transform-origin: 35% 100%;
    transform: skewy(-71deg);
  }
  .side-task-list .image-card.active::after {
    transform-origin: 30% 100%;
    transform: skewy(71deg);
  }
  @include breakpoint(xl-up) {
    .side-task-list .image-card.active::after,
    .side-task-list .image-card.active::before {
      transform-origin: 28% 100%;
    }
  }
  @include breakpoint(xl-down) {
    .side-task-list .image-card.active::before {
      transform: skewy(-68deg);
    }
    .side-task-list .image-card.active::after {
      transform: skewy(70deg);
      transform-origin: 35% 100%;
    }
  }
  .image-card.active {
    border-radius: 5px 0 0 4px;
    .quickpic-result-text p {
      height: auto;
      padding: 4px 12px 4px 4px;
    }
    &.active {
      .opacity-text {
        position: unset;
        &.quickpic-result-text{
          height: auto;
        }
        p,
        p:before {
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
  .mat-card:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }
  .readmore-button {
    border: 1px solid nth($purple-shades, 2);
  }
  
}

.quickpic-restore {
  padding: 10px 5px;
  margin-top: 80px;
  @include breakpoint(sm) {
    margin-top: 80px;
  }
  .no-data-div{
    padding:10px 0;
  }
  h2 {
    @include breakpoint(lg-down) {
      font-size: nth($font-small-point, 3);
    }
    @include breakpoint(sm) {
      font-size: nth($font-small-point, 4);
    }
  }
  .answer-set-card {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 445px;
    @include breakpoint (sm) {
      height: calc(76vh - 50px);
    }
  }
  .answer-set-list {
    .card {
      width: 92%;
      margin: 5px;
      display: flex;
      position: relative;
      min-height: 65px;
      height: 65px;
      &.green {
        // kept important to override the default border-width of card.
        border-left-width: 5px !important;
        background-color: nth($white-shades, 7);
        border-left-color:  nth($green-shades, 7);
      }
      &.brown {
        // kept important to override the default border-width of card.
        border-left-width: 5px !important;
        border-left-color: nth($dark-grey, 5);
        background-color: nth($white-shades, 8);
      }
      @include breakpoint(sm-up) {
        &.active {
          &.green:after  {
            border-left-color: nth($white-shades, 7);
          }
          &.brown:after  {
            border-left-color: nth($white-shades, 8);
          }
        }
        &:after,
        &:before {
          top: 0;
          bottom: 0;
          margin: auto;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        &.active:after {
          right: -11px;
          border-left-width: 10px;
          border-bottom-width: 20px;
          border-top-width: 20px;
        }
        &.active:before {
          right: -13px;
          border-left-color: #3f51b5;
          border-left-width: 10px;
          border-bottom-width: 20px;
          border-top-width: 20px;
        }
      }
      &.active {
        outline: 0;
        border: 2px solid nth($purple-shades, 2);
      }
      @include breakpoint(sm) {
        width: auto;
      }
      p {
        margin: 0;
        @include breakpoint(sm) {
          margin-left: 10px;
        }
      }
      i {
        color: nth($green-shades, 7);
        @include z-index(sidemenu);
      }
    }
  }
  .answer-save-section {
    position: relative;
    @include breakpoint(sm){
      padding: 5px !important;
      .light-gray {
        font-weight: bold;
      }
    }
    .answer-text {
      font-size: nth($font-small-point, 3);
      margin: 5px 0px;
      color: nth($black-shades, 5);
    }
    .answer-card {
      margin: 0 0 15px;
      .image-of-questionstatus {
        width: 300px;
        @include breakpoint ((sm, lg)) {
          width: 200px;
        }
      }
      .image-of-allcompleted {
        width: 125px;
      }
      h4 {
        color: nth($black-shades, 5);
      }
      h2 i {
        color: nth($green-shades, 7);
        position: relative;
        top: 5px;
      }
      .answer-set-text {
        @include breakpoint(lg-down) {
          font-size: nth($font-small-point, 4);
          margin: 0px 5px;
          width: auto;
        }
      }
      .mat-raised-button.go-to {
        background-color: nth($green-shades, 7);
        color: nth($white-shades, 1);
        min-width: 50px;
      }
      textarea {
        border: 1px solid nth($grey-shades, 12);
        box-shadow: 0 0 5px 1px nth($grey-shades, 1);
        background-color: transparent;
        padding: 10px;
        resize: none;
        &:focus {
          box-shadow: 0 0 5px 1px nth($purple-shades, 2);
        }
      }
      .light-gray {
        padding: 0 5px 0 5px;
        color: nth($black-shades, 5);
        font-weight: bold;
      }
      .green {
        color: nth($green-shades, 7);
      }
    }
    @include breakpoint(sm) {
      .answer-card {
        background-color: nth($white-shades, 1) !important;
      }
      .mat-raised-button.go-to {
        min-height: 35px !important;
        height: 35px !important;
        line-height: 1 !important;
      }
      .mat-raised-button {
        line-height: 35px !important;
      }
      .answer-buttons {
        margin: 6px 0px;  
        .mat-raised-button {
          margin: 0px 5px;
        }             
      }
    }
    .create-section-block {
      margin-bottom: 0;
      .create-section-content {
        font-size: nth($font-small-point, 4);
        @include breakpoint (lg-up) {
          display: flex;
          align-items: center;
        }
      }
    }
    
  }
  .mobile-design-show {
    .answer-save-section {
      .image-of-questionstatus {
        width: 200px;
      }
      .image-of-allcompleted {
        width: 125px;
      }
      textarea {
        border: 1px solid nth($grey-shades, 12);
        box-shadow: 0 0 5px 1px nth($grey-shades, 1);
        resize: none;
      }
    }
  }
  .delete-button {
    width: 40px;
    height: 35px;
    background-color: nth($red-shades, 6);
    border-radius: 5px; 
    border: 0;
    img {
      width: 20px;
    }
  }
  .text-nav-link {
    cursor: pointer;
    text-decoration: underline;
    color: nth($black-shades, 5);
  }
  .answer-card-info {
    padding-top: 10px;
  }
}
.content-inner-header {
  margin-top: 80px;
  .save-btn {
    height: 40px;
  } 
  .landing-page-heading {
    margin-top: 40px;
  }
  @include breakpoint(sm) {
    margin-top: 100px;
    .save-btn .mat-raised-button{
      line-height: 30px;
    }
    .restore-button {
      line-height: 30px;
    }
    .landing-page-heading {
      margin-top: 30px;
    }
  }
}
.circle-eye {
  @include width-height(25px !important, 25px !important);
}

.readmore-mobile {
  border-radius: 0 !important;
  padding: 0 22px !important;
  margin: 0 15px;
  box-shadow: none !important;
}

.mat-bottom-sheet-container {
  min-width: 100% !important;
  padding: 8px 0px !important;
}
.qp-noresults{
  position: absolute;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}
.quickpic-restore{
   .ct-smiley{
    font-size: 100px;
   }
  .restore-no-assesment{
    font-size: 24px;
  }
}
mat-card.card.mat-card.active {
  outline: 2px solid nth($indigo-shades, 1);
}
.qc-answer.active:before {	
  border-left:14px solid nth($indigo-shades, 1)!important;	
  // important  is mentioned as getting css from card active	
} 
.qp-yellow{
background-color: nth($yellow-shades,2);
}
.qp-pink{
  background-color: nth($pink-shades,12);
}
.qp-sky-blue{
  background-color: nth($blue-shades,15);
}
.qp-sm-header-career-group{
  background-color: nth($green-shades,11);
}
.qp-career-group{
  background-color: nth($green-shades,12);
}
.qp-sm-header-navigator-work-group{
  background-color: nth($red-shades,9);
}
.qp-navigator-work-group{
  background-color: nth($red-shades,8);
  display: flex !important; // This style for override the block area element of mat-content-card
  justify-content: center;
  padding: 10px 0;
  .Career-group {
    flex: 1;
    .image-size {
      height: 50px;
      margin-bottom: 5px;
    }
  }
}
.qp-sm-header-sky-blue{
  background-color: nth($blue-shades,14);
}
.qp-sm-header-yellow,.qp-career-heading .yellow-bg{
background-color: nth($yellow-shades,3);
}
.qp-sm-header-pink,.qp-career-heading .pink-bg{
  background-color: nth($pink-shades,3);
}
.qp-career-heading .sky-blue-bg{
  background-color: nth($blue-shades,14);
}
.qp-career-tab-heading{
  padding: 4px;
}
.qp-career-tab-body{
  background-color: nth($white-shades, 1);
  &.outline-yellow{
    border: 1px solid nth($yellow-shades,3);
  }
  &.outline-sky-blue{
    border: 1px solid nth($blue-shades,14);
  }
  &.outline-pink{
    border: 1px solid nth($pink-shades,3);
  }
}
//success toast styles
.success-snackbar {
  background-color: nth($green-shades,1);
  color: nth($white-shades,1);
}
// Below style properties are need to design custom tooltop looks same as hover text on circle icon
.mat-tooltip.custom-tooltip {
  margin-top: 4px;
  padding: 5px;
  font-size: nth($font-small-point, 5);
  font-weight: 500;
  white-space: nowrap;
  color: nth($black-shades, 1);
  border-radius: 50px;  
  background-color: nth($white-shades, 1);
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 
              0 6px 10px 0 rgba(0,0,0,.14), 
              0 1px 18px 0 rgba(0,0,0,.12);
}
