/**** ********   Explorer Intro Start  ********* ****/
.explorer-intro {
  padding: 20px 20px;
  .card button {
    border-radius: 4px !important;
  }
  .card-header {
    font-weight: 600;
  }
  .card-body {
    min-height: calc(24vh - 50px) !important;
    .box-img {
      width: calc(20vh - 50px);
      margin: 2px;
    }
    span {
      margin: 2px 5px;
      text-align: center;
    }
  }
  .card-body-text span {
    font-size: nth($font-small-point, 4);
    text-align: center;
    margin-top: 8px;
  }
  .star-icons i {
    width: 25px;
    &.green {
      color: nth($green-shades, 1);
    }
  }
  @include breakpoint(sm) {
    padding: 10px 0px;
    &.content-box {
      margin-top: 20px;
    }
    .card {
      display: flex;
      button {
        width: 50%;
      }
      .button-card {
        width: 50%;
        margin: auto !important;
        .card-body-text span {
          margin: 1px;
          font-size: nth($font-small-point, 1);
          color: nth($black-shades, 5)
        }
      }
      .card-body {
        min-height: calc(25vh - 50px) !important;
        border-radius: 4px;
        .box-img {
          width: calc(25vh - 50px);
          margin-bottom: 15px;
        }
        .card-footer {
          position: absolute;
          bottom: 0;
          width: 100%;
          padding: 5px;
          left: 0px;
        }
      }
      .mobile-score {
        p,
        h3,
        span {
          margin: 0;
          font-size: nth($font-small-point, 1);
        }
        button {
          // background-color: nth($orange-shades, 1);
          width: 70%;
          padding: 0px;
          display: flex;
          height: 25px;
          span {
            color: nth($white-shades, 1) !important;
            font-size: nth($font-small-point, 1);
          }
        }
      }
    }
  } 
  width: auto; 
}
/**** ********   Explorer Intro End  ********* ****/

/************* explorer information start ********* ****/

.explorer-information {
  padding: 30px 20px;
  .next-level{
    right:0px !important;    
  }
  @include breakpoint ((sm, lg)) {
      .next-level{
        top:7%;
      }
    }
  .mat-list-text,
  .mat-list-item-content {
    padding: 3px !important;
  }
  .card button {
    border-radius: 4px !important;
  }
  .next-btn{
    @include breakpoint(sm-max){
      line-height: 0;
    } 
  }  
  @include breakpoint(sm) {
    padding: 10px 0px;
    .card .card-body {
      min-height: 100px;
    }
    .card-header {
      text-align: left !important;
      padding: 10px 20px !important;
    }
  }  
}
/************ explorer information end*************/

/**** ********   About Occupation Start  ********* ****/

.explorer-occuptation {
  @include breakpoint(lg-up){
    .expl-status-btn-styles{
      float: right;
    }
   }
  padding: 10px 5px;
  @include breakpoint(sm) {
    padding: 10px 0px;
    &.content-box {
      margin-top: 30px;
    }
  }
  label {
    margin: 0;
  } 
  .image-card .sprite-img-border {
    overflow: hidden;
  }
  .opacity-text p {
    padding: 4px;
    @include breakpoint(sm){
      height: 40px;
    }
    span {
      font-size: nth($font-small-point, 4);
      padding: 0px 5px;
      @include breakpoint(lg-down) {
        font-size: nth($font-small-point, 4);
        width: 80%;
      }
    }
  }
  .image-content {
    overflow: hidden;
    border-bottom: 1px solid nth($white-shades, 1);
    position: relative;
    .pagecode-image {
      position: absolute;
      bottom: 0;
      left: 5px;
      width: 60px;
      @include breakpoint(sm){
        width: 38px;
      }
    }
    @include breakpoint(xl-down) {
      height: auto;
    }
    img {
      border-radius: 5px 5px 0 0;
    }
    @include breakpoint(sm){
      border: 1px solid nth($grey-shades,1);
    }
  }
  .questions-section {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    @include breakpoint(sm) {
      border: none !important;
      box-shadow: none !important;
    }
    .questions-section-princlipals {
      padding: 15px 0 0;
      .explorer-top-heading{
        margin-bottom: 5px;
      }
      p {
        margin: 0;
        line-height: 1;
        font-size: nth($font-small-point,10);
        min-height: 30px;

      }
      @include breakpoint(sm) {
        padding: 2px 0px;
        .card .card-header {
          padding: 2px 5px;
          background-color: rgba(0, 0, 0, 0.25);
        }
      } 
    }
    .question-sec-body {
      background-color: nth($yellow-shades, 2);
      padding: 5px 15px 15px;
      .circle-icon-text {
        .mat-raised-button {
          &.mat-primary {
            background-color: nth($white-shades, 1);
          }
          .mat-button-wrapper {
            color: nth($yellow-shades, 3);
          }
        }
      }
    }
    .rating-cards {
      .circle-rating-card {
        .card-header {
          &.green {
            background-color: nth($green-shades, 7);
          }
          &.orange {
            background-color: nth($orange-shades, 2);
          }
          &.purple {
            background-color: nth($indigo-shades, 1);
          }
        }
        @include breakpoint(sm) {
          .card {
            &.green-shade-high{
              .mat-checkbox-checked.mat-accent .mat-checkbox-background,
              .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
                border: 1px solid nth($green-shades, 3);
              }              
            }
            &.orange-shade-medium{
              .mat-checkbox-checked.mat-accent .mat-checkbox-background,
              .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
                border: 1px solid nth($orange-shades, 1);
              }
            }
            &.purple-shade-low{
              .mat-checkbox-checked.mat-accent .mat-checkbox-background,
              .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
                border: 1px solid nth($purple-shades, 1);
              }
            }
          }
        }
      }
    }
    .circle-icon {
      font-size: nth($font-small-point, 5);
      margin: 3px;
      @include custom-circle ($border-color: nth($white-shades, 1), $width: 55px, $height: 55px, $background-color: nth($white-shades, 1), $border-radius: 50%);
    }
    @include breakpoint(sm-up) {
      .status-circle {
        font-size: nth($font-small-point, 4);
        @include custom-circle ($border-color: nth($white-shades, 1), $width: 60px, $height: 60px, $background-color: nth($white-shades, 1), $border-radius: 50%);
        margin: 0 3px 3px;
        overflow: hidden;
        .explorer-occ-icons{
          img{
            width: 40px;
          }
          .occ-question-icon img{
            width: 52px;
          }
        }
        .custom-circle-img-width{
          img{
            width: 40px;
            &.trekker-image{
              width: 50px;
            }
          }
        }
      }
    }
    .pointer-image {
      @include position ($position: absolute, $top: -32px, $right: -34px);
      outline: 0;
      @include breakpoint(sm) {
        width: 15px;
        @include position ($position: absolute, $top: -25px);
      }
      &:focus {
        outline: 0;
      }
    }
    .pointer-icon {
      @include position ($position: absolute, $top: -25px, $left: 10px);
      @include breakpoint(sm) {
        width: 15px;
        @include position ($position: absolute, $top: -20px, $left: 15px);
      }
    }
    .task-section{
      justify-content: flex-end;
      @include breakpoint(lg-down){
        justify-content: center;
      }
	 .levels-section {
	        justify-content: center !important
	  }
    }
      .icon-sec {
        margin-top: 10px ;
        margin-bottom: 10px !important;
        @include breakpoint(lg-down) {
          margin: 5px 0 !important;
        }
        .light-red {
          margin-right: 5px;
          line-height: inherit;
          @include breakpoint(sm) {
            .pointer-image {
              top: -25px !important;
              left: 10px !important;
            }
          }
        }
        &.question-block {
          border-top-left-radius: 35px;
          border-bottom-left-radius: 35px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          text-align: center;
          border: 2px solid nth($white-shades, 1);
          &.dark-yellow {
            background-color: nth($yellow-shades, 3);
            color: nth($white-shades, 1);
            @include breakpoint(sm-up) {
              border:2px solid nth($white-shades, 1);
            }
            .circle-icon-text {
              // padding: 0px 4px;
              margin-left:8px;
              text-align:left; 
              .audio-box {
                margin: 0 10px;
              }            
            }
          }
          .circle-icon img{
            width: 42px;
          }
          .question-text{
            width: 75%;
            text-align: left;
            margin-left: 8px;
            color: nth($white-shades, 1);
            .audio-box{
              margin: 0 10px;
            }
            .mat-raised-button {
              &.mat-primary {
                background-color: nth($white-shades, 1);
              }
              .mat-button-wrapper {
                color: nth($yellow-shades, 3);
              }
            }
          }
          .question-block-circle-arrow {
            position: relative;
            button {
              background-color: nth($white-shades, 1);
              i {
                color: nth($pink-shades, 3);
              }
            }
            .pointer-image {
              top: -38px;
              width: 40px;
              @include breakpoint(sm) {
                top: -22px;
                right: -36px;
              }
            }
            @include breakpoint(sm) {
              right: 30px;
            }
          }
        }
        &.result-block {
          background-color: nth($yellow-shades, 3);
          text-align: center;
          border-radius: 2px;
          min-height: 65px;
          height: 65px;
          font-weight: bold;
          font-size: nth($font-small-point, 3);
          width: 100%;
          margin: 5px auto !important;  
          border-radius: 5px;
          border: 2px solid nth($white-shades, 1);
          .custom-question-text{
            span{
              margin-left: 8px;
              font-size: nth($font-small-point, 13);
              color: nth($white-shades, 1);
              font-weight: bold;
              @include breakpoint(sm) {
                font-size: $default-font;
              }
            }
          }
          @include breakpoint(sm) {
            border-width: 0px;
            .mat-fab {
              @include width-height(30px !important, 30px !important);
              span.mat-button-wrapper {
                display: block;
              }
            }
          }
        }
        &.status-block {
          li {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 2px 0;
            position: relative;
            .mat-fab {
              @include width-height(45px !important, 45px !important);
              span {
                font-size: nth($font-small-point, 6);
                color: nth($white-shades, 1);
              }
            }
            &:focus {
              outline: none;                
            }
          }
          .question-icon{
            display: block;
          }
          @include breakpoint(sm-up) {
            .low-icon-bg{
              border: 2px solid nth($purple-shades, 1);
            }
            .medium-icon-bg{
              border: 2px solid nth($orange-shades, 1);
            }
            .high-icon-bg{
              border: 2px solid nth($green-shades, 3);
            }
          }
          ul {
            @include breakpoint ((sm, lg)) {
              margin-top: 30px;
            }
            @include breakpoint(sm) {
              margin-top: 20px;
              .done {
                color: nth($black-shades, 5);
                font-weight: bold;
              }
            }
          }
        }
      }      
  } 
  .side-task-list {
    .image-card {
      border-radius: 5px;
    }
    .sprite-img-border {
      height: 175px;
      img{
        min-height: 146px;
        width: 250px;
      }
    }
  }
  .rating-cards .card {
    box-shadow: none;
    .mat-checkbox-frame {
      border-color: nth($white-shades, 1);
      border-radius: 50%;
    }
    .mat-checkbox-checked.mat-accent .mat-checkbox-background,
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
      background-color: nth($white-shades, 1);
      border-radius: 50%;
      .mat-checkbox-checkmark-path {
        stroke: rgba(0, 0, 0, 0.43) !important;
      }
    }
    &.explorer-theme-bg {
      background-color: nth($yellow-shades, 11);
      .card-header {
        background-color: nth($yellow-shades, 3);
      }
    }
    &.career-trekker-bg {
      background-color:nth($pink-shades, 5);
      .card-header {
        background-color:nth($pink-shades, 3);
      }
    }
  }
  .rating-cards .icon-sec.status-block li{
    padding: 0px;
  }
  .you-are-text {
    padding: 20px 0;
  }
  .challenge-button {
    font-size: nth($font-small-point, 12);
    padding: 5px 20px;
  }
}
.expl-tittle {
  text-align: center;
  margin-top: 100px;
  span {
    font-size: nth($font-small-point, 2);
    @include breakpoint(sm) {
      font-size: $default-font;
    }
  }
  p {
    margin: 10px 0px;
  }
}
/**** ********   About Occupation End  ********* ****/

/**** ********   Explorer Task Start  ********* ****/

.explorer-task {
  padding: 10px;
  @include breakpoint(sm) {
    .challenge-principle {
      .card .card-header {
        background-color: rgba(0, 0, 0, 0.25);
      }
    }
    &.content-box {
      margin-top: 30px;
    }
  }
  @include breakpoint(lg-up) {
    .image-content {
      height: 405px;
    }
  }
  .score-data {
    margin-top: 5px;
    .score {
      margin:0px 3px;
    }
    li {
      color: nth($black-shades, 1);
    }    
    p {
      margin-bottom: 10px;
      font-size: nth($font-small-point,1);
      color: nth($yellow-shades, 3);
    }
    @include breakpoint(sm-up) {
      height: 55px;
    }
  } 
  .task-section {
    padding: 5px 0;
    p {
      width: 95%;
      margin: 0px auto 10px;
    }
    @include breakpoint(lg-down) {
      padding: 5px 5px;
    }
    @include breakpoint(sm) {
      padding: 5px 5px;
      .icon-sec.question-block.light-grey {
        background-color: nth($black-shades, 5);
        color: nth($white-shades, 1);
      }
      .question-block .circle-icon {
        background-color: nth($white-shades, 1) !important;
      }
      &[dark-bg] {
        background-color: nth($grey-shades, 7);
      }
    }
  }
  .status-block ul {
    @include breakpoint ((sm, lg)) {
      margin-top: 0px !important;
    }
  }
  @include breakpoint ((sm, lg)) {
    padding: 10px;
    .card-header {
      padding: 4px 0px !important;
    }
  }
}
.stable-btn{
  width: 50px!important;
}

.status-circle {
  margin: 0 auto;
  img {
    width : 45px;
  }
  @include breakpoint(sm){
    .sign-icon {
      width: 18px;
      position: absolute;
      left: 1px;
      top: 1px; // These styles for support the question icon alignments in circle icon
    }
  }
}
@include breakpoint(sm){
.common-row-sub{
  flex-wrap: wrap;
 }
}
.question-icon {
  .icon-char_math{ 
    background-position:-624px -821px!important;
    margin-left: 4px;
  }
  .icon-char_abc{
    background-position:-475px -819px!important;
    margin-left: 4px;
  }
  .icon-char_education{
    background-position:-570px -823px!important;
  }
  .icon-char_money{
    background-position:-673px -820px!important;
  }
  .icon-char_outdoors{
    background-position:-720px -819px!important;
  }
  .icon-char_activity{
    background-position:-520px -816px!important;
  }
}

// mentioned important as these values are ovverding from respective  classes

/**** ********   Explorer Task End  ********* ****/