/****  Wellcom screen ****/

.career-trek-bg {
  background: url("/assets/images/bg-ct.jpg") no-repeat;
  @include width-height(100%,
  100%);
  position: absolute;
  background-size: cover;
  background-position: 57% 1%;
  @include breakpoint(sm-max) {
    background-position: 48% 0;
  }
  .career-trek-logo {
    margin: auto;
    @include position ($position: absolute, $top: 0, $bottom: 0, $left: 0, $right: 0);
    width: 600px;
    @include breakpoint(sm-max) {
      width: 315px;
    }
  }
}
