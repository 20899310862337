.side-menu-bg{
    background-color: rgba(0, 0, 0, 0.49019607843137253);
    @include width-height(100%, 100%);
    position: fixed;
    @include z-index(sidemenu);

    .side-menu{
        position: absolute;
        top:0;
        right: 0;
        @include width-height(310px, 100%);
        padding: 20px;
        background-color: nth($grey-shades,3);
        color: nth($black-shades,5);
        visibility: visible;
        transition: width 2s;
        -webkit-transition: width 2s;

    }
    .profil-section{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px 0;
        .profile-img{
            @include width-height(120px, 120px);
            border-radius: 50%;
            background-color: nth($white-shades,1);
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            i{
               font-size: nth($font-large-points,1);
            }
        }
        .sidemenu-btn{
            ul{
                margin: 40px 0;
                padding:0;
            }
            li{
                border-bottom: 2px solid nth($white-shades,1);
                text-align: center;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                .mat-button {
                    color: nth($black-shades,5);
                    font-size: $default-font;
                    .mat-button-focus-overlay {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
//Aligned the language dropdown while scrolling
.lang-dropdown-menu{
    &.mat-menu-panel{
        &::before{
            top: 30px;
            left: 38px;
        }
    }
    // .mat-menu-content {
    //     // height: auto;
    //     // overflow-y: auto;
    //     // border-radius: 0;
    //     // position: absolute;
    //     // background: white;
    //     // top: 30px;
    //     // width: 100px;
    //     // left: 5px;
    //     // border-radius: 9px;
    //     //     @media(max-width:767px){
    //     //         top: 32px;
    //     //         left: 4px;
    //     //     }
    //     //     .mat-menu-item{
    //     //         @media(max-width:767px) {
    //     //             height: 35px;
    //     //         }
    //     //     }
    // }

}
// .mat-menu-content {  //Aligned the drawing elements dropdown while scrolling 
//     position: absolute;
//     background: white;
//     top: calc(100% - 96%);
//     width: 130px;
//     left: 54px;
//     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
//     height: 215px;
//     overflow-y: scroll;
//     border-radius: 9px;
// // //Aligned the drawing elements dropdown while scrolling for small resolution
//     @media(max-width:767px) {
//         height: 146px;
//         width: 95px;
//         top: calc(100% - 90%);
//         left: -3px;
//     }

//     .mat-menu-item {
//         font-weight: bold;

//         @media(max-width:767px) {
//             height: 30px;
//         }

//     }
// }
// Align the menu items and drawing board side by side as per resolutions
.menu-item_align {
    display: block;

    @media(max-width:599px) {
        display: flex;
    }
}

.lang-scroll{
    overflow-y: hidden;
}