.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

.content-box {
  background-color: nth($white-shades, 1);
  box-shadow: 0 0 20px 4px nth($grey-shades, 20);
  margin-top: 61px;
  margin-bottom: 45px;

  @include breakpoint ((sm, lg)) {
    margin-top: 80px;
    margin-bottom: 65px;
  }
}

@include breakpoint(sm-up) {
  .mobile-design-show {
    display: none !important;
  }
}

@include breakpoint(sm) {
  .content-box {
    background-color: nth($grey-shades, 7);
    box-shadow: none;

    &.top-skills-box {
      margin-top: 0;
    }
  }

  .desktop-design-show {
    display: none !important;
  }
}

@include breakpoint(lg-up) {
  .tab-design-show {
    display: none;
  }
}

@include breakpoint(lg-down) {
  .largedevice-design-show {
    display: none;
  }
}

.success-tick {
  @include position ($position: relative, $right: 5px, $top: 0px);
  background-color: nth($green-shades, 1);
  color: nth($white-shades, 1);
  padding: 2px;
  margin: 0px 2px;
  border-radius: 50%;
  font-size: nth($font-small-point, 4);
}

.volume-up,
.quickpic-vol-btn {
  border-radius: 50% !important; // important is mentioned as apply radius style to mat-button
  border: none !important;
  background-color: nth($green-shades, 7);
}

@include breakpoint(sm-up) {
  .container {
    width: 575px;
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media(max-width:599px){
.profilepage-sm{
  width: 293px;
  max-width: 100%;
}
}

@include breakpoint(lg-up) {
  .container {
    width: 949px;
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@include breakpoint(xl-up) {
  .container {
    width: 1109px;
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.list-circle-icon {
  font-size: nth($font-small-point, 3) !important;
  box-shadow: 0 0 1px 1px #ccc;
  @include custom-circle ($border-color: nth($white-shades, 1), $width: 40px !important, $height: 40px !important);
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  @include breakpoint(lg-down) {
    .common-row {
      display: flex;
    }
  }

  @include breakpoint(sm) {
    .common-row-sub {
      display: flex;
    }
  }
}

/*** intro mobiles headding ****/

@include breakpoint(sm) {
  .mobile-heading {
    position: relative;
    margin-bottom: 5px;

    h1 {
      font-size: $default-font;
      margin: 0;
      padding: 5px;
      line-height: 1;
      text-align: center;
      font-weight: bold;
    }

    .trekker-mobile-heading {
      font-size: 1.1rem;
    }

    hr {
      width: 40px;
      margin: 1px auto;
      border: 1px solid nth($black-shades, 5);
    }

    .next-level {
      position: absolute;
      top: -10px;
      @include width-height(30px !important, 30px !important);
    }

    img {
      @include width-height(40px, 40px);
      margin: 5px;
    }
  }
}

.mobile-selected-cards {
  .card-body {
    min-height: 35px !important;
  }

  .selected-card {
    background: none;
    box-shadow: none !important;
    margin: 3px;
    min-height: 34px;
    height: 34px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

.opacity-text {
  @include position ($position: absolute, $bottom: 0px, $left: 0px);
  width: 100%;
  text-align: center;

  p {
    position: relative;
    width: 100%;
    min-height: 40px;
    padding: 5px;
    color: nth($white-shades, 1) !important;
    display: flex;
    align-items: center;
    margin: 0;
    justify-content: center;

    &::before {
      content: "";
      opacity: 0.9;
      bottom: 0;
      @include width-height(100%, 100%);
      @include position ($position: absolute, $left: 0px);
      background-color: rgba(0, 0, 0, 0.6);
    }

    span,
    img {
      position: relative;

      @include breakpoint(lg-down) {
        font-size: nth($font-small-point, 4);
      }
    }
  }

  .img-icon {
    position: relative;
  }
}

.careertrekker {
  .circle-image-list-status .circle-img img {
    width: 92%;
  }
}

.circle-image-list-status {
  margin-bottom: 3px;

  &.custom-library-circle {
    .circle-img {
      margin: 5px 10px;
      background-color: nth($white-shades, 1);
    }
  }

  .circle-img {
    @include custom-circle ($border-color: nth($pink-shades, 3), $width: 55px, $height: 55px, $border-radius: 50%);
    overflow: hidden;
    // box-shadow: 0 0 11px 1px nth($grey-shades, 1); Removed dropshadow for non clickable cards
    margin-left: 10px;

    img {
      width: 75%;
      height: 65%;
    }
  }

  .radio-options {
    margin: 0 0 0 5px;
    width: 65%;

    label {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
      padding: 3px 0px;
      font-weight: 600;
      color: nth($black-shades, 4);

      @include breakpoint(lg-down) {
        font-size: nth($font-small-point, 1);
      }

      @include breakpoint(sm) {
        font-size: nth($font-small-point, 5);
      }
    }

    ul {
      width: 100%;

      li {
        text-align: center;
        font-size: nth($font-small-point, 1);
        width: width-calculation(100%, 3);
      }
    }

    .mat-radio-label {
      white-space: normal;
      display: block;
    }

    .mat-radio-label-content {
      padding: 0;
      margin: 3px 0px;
    }

    .mat-radio-container {
      @include width-height(15px, 15px);
    }
  }
}

.filter-option {
  background-color: nth($white-shades, 1);
  border-radius: 5px;
  min-height: 40px;
  border: 1px solid nth($grey-shades, 1);
  box-shadow: 0 0 10px 1px nth($grey-shades, 1);

  p {
    padding: 0 5px;
    font-size: nth($font-small-point, 3);
    text-align: center;
  }

  .mat-mini-fab {
    background-color: nth($white-shades, 1) !important;
    line-height: 0 !important;
    box-shadow: none !important;
    margin: 4px 3px;

    @include breakpoint(xs) {
      margin: 0;
    }

    @include width-height(35px !important, 35px !important);

    i {
      font-size: $default-font;
    }

    span {
      line-height: 0 !important;
      padding: 0 !important;
    }

    img {
      width: 22px;
    }
  }
}

.card .image-card {
  position: relative;
  margin: 10px 5px;
}

.no-data-image {
  width: 170px;
  margin: auto;

  @include breakpoint(sm) {
    width: 80px !important;
    margin: auto;
  }
}

.explorer-theme .occupation-explorer .mat-tab-label-active .tab-button {
  background-color: nth($yellow-shades, 4);
}

.pathfinder-theme .occupation-explorer .mat-tab-label-active .tab-button {
  background-color: nth($blue-shades, 9);
}

.navigator-theme .occupation-explorer .mat-tab-label-active .tab-button {
  background-color: nth($red-shades, 9);
}

.navigator-theme,
.pathfinder-theme {
  .common-popup-list {
    li img {
      margin-right: 5px;
    }
  }
}

.careertrekker-theme,
.explorer-theme,
.pathfinder-theme {
  .filter-option p {
    font-size: $default-font  !important;
  }

  .custom-tabs .careertrekker .mat-tab-label-active::before {
    border-top: 2px solid nth($grey-shades, 9);
    border-left: 2px solid nth($grey-shades, 9);
    border-right: 2px solid nth($grey-shades, 9);
  }

  .mat-tab-label,
  .occupation-explorer .mat-tab-label-active .tab-button {
    opacity: 1 !important;

    //  background-color: nth($pink-shades, 9) !important;
    span {
      color: nth($white-shades, 1) !important;
    }
  }

  // .custom-tabs .mat-tab-body {
  //   border: 2px solid nth($grey-shades, 9) !important;
  // }
}

.side-task-list {
  .image-card.active {
    margin: 5px;
    overflow: hidden;
    position: relative;

    &::after,
    &::before {
      content: '';
      position: absolute;
      left: 0;
      height: 62%;
      background-color: nth($white-shades, 1);
      padding-left: 3.8%;
      @include z-index(nine);

      @include breakpoint(xl-up) {
        padding-left: 2.8%;
      }
    }

    &::before {
      top: 62%;
      transform-origin: 70% 100%;
      transform: skewy(66deg);

      @include breakpoint(xl-up) {
        transform-origin: 80% 100%;
      }
    }

    &::after {
      bottom: 62%;
      transform-origin: 70% 100%;
      transform: skewy(-70deg);

      @include breakpoint(xl-up) {
        transform-origin: 80% 100%;
      }
    }

    .success-tick {
      background-color: nth($white-shades, 1);
      color: nth($green-shades, 1);
    }

    img {
      vertical-align: middle;
      @include position ($position: relative);
    }
  }
}

.three-dots-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-step-header,
.mat-stepper-horizontal-line,
.mat-step-icon,
.mat-step-icon-not-touched {
  display: none !important;
}

.profile-stepper {
  .mat-step-header {
    display: block !important;
  }
}

.mat-horizontal-content-container {
  padding: 0 !important;
}

.career-trekker-result,
.occupation-explorer {
  .mat-radio-label-content {
    display: block;
  }
}

.pathfinder-theme .mat-button,
.pathfinder-theme .mat-icon-button,
.pathfinder-theme .mat-stroked-button {
  color: nth($white-shades, 1);
}

.mat-snack-bar-container {
  position: fixed;
  bottom: 25px;
  left: 50%;
  margin: 0;
}

.mat-snack-bar-container.success-snackbar {
  background-color: nth($green-shades, 4);
  color: nth($white-shades, 1);
}

.mat-snack-bar-container.failure-snackbar {
  background-color: nth($red-shades, 2);
  color: nth($white-shades, 1);
}

// The below style apply's for the snackBar when the max limit for career creation is reached.
.mat-snack-bar-container.careerCreatorfailure-snackbar {
  background-color: nth($red-shades, 2);
  color: nth($white-shades, 1);
  margin-top: 450px !important;
}

.mat-tab-label-content {
  width: 100% !important;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  .lds-roller {
    top: 0;
    left: 0;
  }
}

.mat-tab-header {
  border-bottom: none !important;
}

.mat-tab-body-content {
  overflow: initial !important;
}

.mat-expansion-panel-header {
  background-color: nth($blue-shades, 6);
  height: 40px !important;

  .mat-expansion-panel-header-title,
  .mat-expansion-indicator::after {
    color: nth($white-shades, 1) !important;
  }
}

.mat-expansion-panel-body {
  padding: 5px;
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}

.mat-expansion-panel:nth-child(1) .mat-expansion-panel-header,
.mat-expansion-panel {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.mat-expansion-panel:nth-last-child(1) .mat-expansion-panel-header,
.mat-expansion-panel {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  &.mat-expanded {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.cdk-mouse-focused,
.cdk-focused,
.cdk-program-focused {
  outline: 0 !important;
}

.mat-list-item {
  cursor: pointer;
}

.challenge-block {
  transform: translate(-19px, 0px);

  img {
    width: 30px;
    margin-right: 10px;
  }
}

.d-none {
  display: none !important; // The important style hide the block of area at respected situations on flex property.
}