.discoverer-intro {
    padding: 50px 0;
    .forward-arrow{
        margin-bottom: 27px;

    }
    .video-block {
        width: 400px;
        margin: auto;
        height: 225px;
        .videobox {
            display: block;
            height: 100%;
            width: 100%;
        }
        .material-icons{
            position: relative;
            font-size: 80px;
            bottom: 165px;  
            left: 155px;
            color: nth($white-shades,1);
            z-index: 9;
            cursor: pointer;
        }
    }
}
.container{
    .content-box{
        @include breakpoint(sm) {
        margin-top: 25px;
    }
}}

.discoverer-activity {
    .mat-checkbox-layout {
        position: relative;
        margin-bottom: 0;
        .mat-checkbox-inner-container {
            position: absolute;
            z-index: 99;
            right: 30px;
            bottom: 20px;
            width: 35px;
            height: 35px;
            .mat-checkbox-frame {
                border-width: 0;
            }
        }
    }
    .mat-checkbox-checked .mat-checkbox-checkmark {
        opacity: 0;
    }
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: transparent !important;
        background-image: url(/assets/images/cardclick.png);
        width: 38px;
        height: 28px;
        background-size: cover;
    }
    .carrer-frame-footer {
        padding-top: 10px;
        .audio-box {
            min-width: 26px !important;
            height: 26px;
            .material-icons {
                font-size: $default-font;
            }
        }
    }
}