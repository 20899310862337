/********* ***** Path Finder Intro Start ********* *****/
.pathfinder-intro {
  padding: 30px 20px;
  .mat-card {
    border: 1px solid nth($blue-shades, 14);
  }
  .pathfinder-cards {
    margin: 5px;
    .mat-card {
      box-shadow: none;
    }
    .mat-card:not([class*=mat-elevation-z]) {
      box-shadow: none;
    }
  }
   .card-body {
    img{
      width: 70px;
    }

}
  @include breakpoint(sm) {
    padding: 20px 0px;
    &.content-box {
      margin-top: 0;
    }
    .card .card-body {
      min-height: 80px;
      img{
        width: 50px;
      }
    }
    .pathfinder-cards {
      margin: 1px 0;
    }
  }
}

/********* ***** Path Finder Intro End ********* *****/

/********* ***** Path Finder Questions Start ********* *****/

.pathfinder-questions {
  padding: 30px 20px;
  @include breakpoint(sm) {
    padding: 10px 0px;
    &.content-box {
      margin-top: 20px;
    }
  }
  .card {
    overflow: hidden;
    overflow-x: hidden;
    border: 1px solid nth($white-shades, 1);
    border-radius: 8px;
    position: relative;
    @include breakpoint(sm) {
      .card-header {
        text-align: left !important;
        padding: 10px 20px;
      }
    }
  }
  .question-icon {
    text-align: center;
    padding: 0 0 20px 0;
    p {
      color: nth($black-shades, 5);
    }
    .audio-box {
      @include breakpoint(sm-up) {
        margin-left: 10px;
      }
    }
  }
  .active-card {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    p {
      color: nth($white-shades, 1);
      padding: 5px 0;
      background-color: nth($black-shades, 12);
    }
    .active-card-bar {
      background-color: nth($powder-blue, 13);
    }
  }
  .moblie-item-text {
    p {
      margin: 10px 0px;
    } 
    .card {
      margin: 0px auto 7px;
      width: 90%;
      border: none !important;
    }
  }
  .mat-button {
   //override the default material button 
    //for that purpose we have written important
    color: nth($white-shades, 1)!important;
    min-width: 20% !important;
    @include breakpoint(sm) {
     min-width: 40% !important;
     max-width: 49%;
     white-space: normal;
     line-height: 1.5;
      padding: 3px !important;
      .mat-button-wrapper {
        font-size: nth($font-small-point, 1) !important;
      }
    }
  }
  .mat-list-item-content {
    padding: 0px !important;
  }

  .pf-assessment-save-btn {
    .save-text {
      display: inline-block;
      padding-top: 2px;
    }
    img {
      width: 30px;
      margin-right: 10px;
    }
    &.question-active,
    &:hover {
      .mat-button-focus-overlay {
        opacity: 0.3;
      }
    }
    &.question-inactive
    {
      .mat-button-focus-overlay {
        opacity: 0.5;
      }
    }
  }

}

/********* ***** Path Finder Questions End ********* *****/

/********* ***** Path Finder Top Skills Layout Start ********* *****/

.top-skills-layout {
  padding: 30px 20px;
  @include breakpoint(sm) {
    &.content-box {
      margin-top: 20px;
    }
    padding: 10px 0 0;
  }
  .select-cards {
    height: 100%;
    padding: 8px;
    border-radius: 5px;
    .card {
      margin: 2px;
      // min-height: calc(22vh - 100px) !important;
      display: flex;
         @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 85px;
      }
    }
    @include breakpoint(sm) {
      border: none;
      h4 {
        margin: 2px 0px 15px 2px;
        .next-level {
          width: 30px !important;
          height: 30px !important;
        }
      }
      .card {
        padding: 0px !important;
        margin: 2px;
        // min-height: calc(23vh - 50px) !important;
        // height:calc(23vh - 50px) ;
        .selectable-icon {
          width: 50px;
        }
      }
    }
  }
  .two-cards {
    .top-two-cards {
      width: 90%;
      margin: auto;
      box-shadow: none;
      border: 1px solid nth($blue-shades, 14);
      @include breakpoint(sm) {
        width: auto;
      }
      .card-header{
        margin: -1px;
      }
    }
    .card {
      img {
        width: 80px;
        margin: 15px
      }
      @include breakpoint(sm) {
        .card-body {
          min-height: 150px !important;
          min-width: 250px;
        }
      }
      @include breakpoint((sm, lg)) {
        .card-body {
          min-height: 110px !important;
        }
      }
    }
    p {
      font-size: nth($font-small-point, 2);
      text-align: center;
      @include breakpoint(lg-down) {
        font-size: nth($font-small-point, 3);
      }
    }
    .top-two-title {
      img {
        width: 30px;
      }
    }
  }
  .mobile-design-show {
    .next-level {
      @include position ($position: absolute !important, $top: 0% !important, $right: -20px);
      @include z-index(nine);
      @include breakpoint(sm) {
        right: 5px;
      }
    }
  }
  .success-tick {
    @include position ($position: absolute, $top: 3px, $right: 0px);
    width: 24px;
    height: 24px;
    &.no-background {
      background-color: transparent;
    }
  }
  .pathfinder-cards{
    .mobile-selected-cards{
      .card-header {
        background-color: nth($blue-shades, 14);
      }
      .card-body{
        @include breakpoint(sm){
          min-height: auto !important;
          height: auto !important;
        }
      }
    }
  }
}
.pathfinder-cards{
  .success-tick{
    font-size: nth($font-small-point, 3);
  }
}
/********* ***** Path Finder Top Skills Layout End ********* *****/

/********* ***** Path Finder Result Start ********* *****/

.pathfinder-result {
  padding: 30px 20px;
  @include breakpoint(sm) {
    padding: 0;
    &.content-box {
      margin-top: 20px;
    }
  }
  .pathfinder-cards {
    h3 {
      margin-top: 5px;
    }
    .image-card {
      min-height: 40px;
      height: 130px;
      overflow: hidden;
      @include breakpoint(sm) {
        box-shadow: none !important;
        background-color: nth($white-shades, 1);
        height: calc(18vh - 50px) !important;
      }
    }
  }
  .success-tick{
    color: nth($green-shades, 1);
    background-color: nth($white-shades, 1);
    
  }
  .card {
    @include breakpoint(sm-up) {
      .image-card {
        position: relative;
        margin: 0px 5px 15px;
        width: 250px;
        border: none;
      }
    }
    .image-card .opacity-text img {
      width: 30px;
    }
  }
  .shared-occ {
    .selected-icons {
      img {
        width: 45px;
        margin: 7px 10px;
      }
    }
    .right,
    .left {
      .material-icons {
        font-weight: bold;
        font-size: nth($font-large-points, 2);
      }
    }
    .right{
		position: absolute;
		right: 0;
		top: 190px;
		overflow: hidden;
		width: 47px;
    @include z-index(zero);
    }
    .left{
      position: absolute;
      left: 0;
      top: 190px;
      overflow: hidden;
      width: 45px;
      @include z-index(zero);
    }
  }
  .carousel-card {
	overflow: hidden;
  @include z-index(one);
	position: relative;
    .carousel-width{
      width: 280px;
	  overflow: hidden;
    @include z-index(one);
	  position: relative;
    }
    .image-card {
      display: flex;
      justify-content: center;
      @include z-index(one);
      margin: 10px auto;
    }
  }
  .carousel-card-layer{
    position: absolute;
    height: 180px;
    width: 250px;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .shared-card-carousel{
    height: 190px;
    width: 280px;
    overflow: hidden;
    margin: auto;
  }
  .carousel {
    position:relative;
    button {
      width: auto !important;
      &:disabled {
        opacity: 0.5;
      }
    }
    .right,
    .left {
      .material-icons {
        font-weight: bold;
        font-size: nth($font-large-points, 2);
      }
    }
    .right{
      position: absolute;
    right: 0;
    top: 140px;
    }
    .left{
      position: absolute;
      left: 0;
      top: 65px;
    }
  }
  .carousel p {
    color: nth($black-shades, 2);
    font-size: nth($default-font, 1);
    margin: 0;
  }
  .you-are-text {
    padding: 6px 90px;
    font-size: nth($default-font, 1);
    font-weight: bold;
    margin-bottom: 10px;
  }
  .challenge-button {
    font-size: nth($font-small-point, 12);
    padding: 5px 20px;
    width: auto;
    border-radius: 4px;
    color: nth($white-shades, 1) !important; //this style for override the mat-raised-button base color
    @include breakpoint(sm) {
      font-size: nth($font-small-point, 3);
      padding: 2px 10px;
    }
  }
  .mobile-selected-cards {
    .card-body {
      background-color: nth($white-shades, 1);
      min-height: 55px !important;     
    }
    @include breakpoint(sm) {
      .card-header {
        font-size: nth($font-small-point, 4) !important;
      }
    }
  }
}

/********* ***** Path Finder Result End ********* *****/

/********* ***** Path Finder Challenge Start ********* *****/

.pathfinder-challenge {
  padding: 30px 20px;
  @include breakpoint(sm) {
    padding: 10px 0px;
    &.content-box {
      margin-top: 20px;
    }
  }
  .card-header {
    @include breakpoint(lg-down) {
      font-size: $default-font !important;
    }
    @include breakpoint(sm){
      margin-top: 10px;
    }
    .filled-star{
      margin-top:-4px;
    }
  }
  .flip-cards {
    margin: 5px;
    .image-card {
      margin: 0 !important;
      min-height: 100px;
      img {
        width: 60px;
      }
    }
    /****   Flip cards css start  ***/
    .filp_container.card {
      box-shadow: none;
      background-color: transparent;
    }
    #filp_card {
      height: 116px;
      transition: all 1.5s ease 0s;
       box-shadow: 0px 1px 0px 1px nth($grey-shades, 1);
      @include breakpoint(sm){
        height: calc(15vh - 25px) !important;
        display: block;
        backface-visibility: visible;
      }
      @include breakpoint((sm, lg)) {
        height: 130px !important;
      }
      transform-style: preserve-3d;
      // background-color:nth($grey-shades,16);
      .challange-lock-icon {
        width: 70px;
      }
      .opacity-icon {
        width: 25px !important;
        position: relative;
        @include breakpoint((sm, lg)) {
          width: 60px!important;
          height: 60px;
          top: -20px;
        }
      }
      &.Flipped {
        transform: rotateY(180deg);
      }
      .front_card {
        position: absolute;
        backface-visibility: hidden;
        cursor: pointer;
        @include width-height(100%, 100%);
        .material-icons {
          color: nth($white-shades, 1);
          @include breakpoint(lg-down) {
          font-size: $default-font;
          }
        }
      }
      .lock-bg-light-blue{
        background-color:nth($blue-shades,15);
      }
      .back_card {
        display: block;
        transform: rotateY(-180deg);
        backface-visibility: hidden;
        @include width-height(100%, 100%);
        overflow: hidden;
        cursor: pointer;
        background-color: nth($white-shades, 1);
        border: 1px solid nth($blue-shades, 3);
      }
      @include breakpoint(lg-up) {
        .opacity-text p {
            height: 40px;
        }
        .below-tab-show{
          display: none!important;
        }
      }
      @include breakpoint(lg-down) {
      .above-tab-show{
        display: none!important;
      }

        img {
          @include width-height(25px, 25px);
          position: relative;
          @include breakpoint(xs){
            @include width-height(20px, 20px);
          }
          @include breakpoint((sm, lg)) {
            width: 60px!important;
            height: 60px;
            top: -20px;
          }
        }
        .front_card,
        .back_card {
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          backface-visibility: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          p {
            font-size: nth($font-small-point, 1);
            span {
              @include breakpoint(sm) {
                font-size: nth($font-small-point, 1);
              }
              @include breakpoint(xs) {
                font-size: nth($font-small-point, 8);
              }
            }
          }
        }
      }
    }
    /****   Flip cards css end  ***/
    
  }
  .pf-score-data{
    font-size: $default-font;
    .pf-score{
      font-weight: bold;
      margin-top: 30px;
      font-size: 0.85rem;
      line-height: 1;
      border: 2px solid nth($white-shades,1);
      background-color:nth($blue-shades, 14);
      color: nth($white-shades,1);
      width: 30px;
      height: 30px;
      padding: 4px 8px;
      border-radius: 50%;
      @include breakpoint(sm){
        margin-top: 40px;
      }
    }
    p{
      color:nth($blue-shades, 14);
      @include breakpoint(sm){
        margin-top: 10px;
      }
     
    }
    .number-score{
      margin-top: -20px;
    }
    @include breakpoint(sm){
      .number-score{
        margin-top: -40px;
      }
    }
  }
  .progress-bar-content {
    padding: 10px 5px;
    h2 {
      margin-top: 30px;
      @include breakpoint(lg-down) {
        font-size: $default-font;
      }
    }
    .matches-text{
      display: flex;
      margin-bottom: 0px;
    }
    .progress-bar {
      list-style: none;
      display: flex;
      position: relative;
      top: 0;
      padding: 4px;
      // background:nth($grey-shades,1);
      // border-radius: 10px;
      width: 100%;
      margin: 0;
      // @include breakpoint(sm){
      //   width: 90%;
      // }
      &.mobile-view{
        margin-top: 4px;
      }
      li {
        width: 40%;
        font-weight: bold;
      }
    }
    .mat-progress-bar {
      height: 8px;
      border-radius: 5px;
      @include breakpoint(sm){
        margin-top: 4px;
      }
    }
    .mat-progress-bar-buffer {
      background-color: nth($grey-shades, 1);
    }
    h3 {
      margin: 30px 0;
    }
    .score-text {
      margin: 20px auto;
      @include custom-circle ($border-color: nth($white-shades, 1), $width: 150px, $height: 150px, $border-radius: 50%);
      border: 3px solid nth($white-shades, 1);
      // box-shadow: 0 0 16px 5px nth($grey-shades, 1);
      font-size: nth($font-medium-point, 1);
      font-weight: bold;
      p {
        font-size: nth($font-small-point, 2);
        color: nth($white-shades, 1);
      }
      @include breakpoint((sm, lg)) {
        @include custom-circle ($width: 100px, $height: 100px, $border-radius: 50%);
      }
    }
  }
  .timer-panal {
    height: 30px;
    margin: 10px 0;
    position: relative;
    @include breakpoint(sm) {
      margin:0px;
    }
    p {
      margin-bottom: 0;
      font-size: nth($font-small-point, 4);
      width: 100%;
    }
    .timer {
      @include width-height( $width: 100px, $height:30px);
      background-color: nth($orange-shades, 1) !important;
      @include position ($position: absolute, $top: 0px, $right: 0px);
      font-size: nth($font-small-point, 4);
      color: nth($white-shades, 1);
    }
  }
  .score-card {
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .card-body {
        width: 100%;
      }
    }
  }
}
@include breakpoint(sm) {
  .card-header{
    &.matches-mobile{
      margin-top: 20px;
      margin-bottom: 0;
      }
    }
}
/********* ***** Path Finder Challenge Start ********* *****/
/*** Flip Card Styles For IE ***/

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Flipped {
    transform: rotate(0deg) !important;
    perspective: 500;
    transition: all 1s ease-in !important;
    .front_card-ie {
      transform: rotateY(180deg) !important;
      -ms-transform:rotateY(180deg) !important;
      -moz-transform: rotateY(180deg) !important;
    }
    .back_card-ie {
      transform: rotateY(0deg) !important;
      -ms-transform:rotateY(0deg) !important;
      -moz-transform: rotateY(0deg) !important;
    }
  }
  .front_card-ie {
  transition: all 0.2s ease-in !important;
     box-sizing: border-box;
     transform: rotateY(0deg) !important;
     -ms-transform:rotateY(0deg) !important;
    -moz-transform: rotateY(0deg) !important;
    display: flex;
  }
  .back_card-ie {
    transition: all 0.2s ease-in !important;
    box-sizing: border-box;
    display: flex;
    transform: rotateY(180deg) !important;
    -ms-transform:rotateY(180deg) !important;
    -moz-transform: rotateY(180deg) !important;
  
  }
}
.pf-challange-tries,.pf-challange-matches{
  color:nth($grey-shades, 8)
}
.progress-bar-content {
  .pathfinder-matches-text.card-header{
    padding: 4px 0px;
  }
  &.mobile-design-show{
      margin-top: 5px;
      line-height: 4px;
  }
}
/*** icon hover title ***/


.common-occ-popup .occ-popup-body .common-occ-text .icon-hovertitle {
  margin: 0px !important;
  span {
    margin: 5px 10px;
    top: 45px;
  }
}
@media (max-width:599px) {
  .common-occ-popup .occ-popup-body .common-occ-text .mobile-design-show .mobile-popup-image{
    //Override with display: none
   display: flex !important;
  }
  }


  .w-50{	
    width: 50px;	/*** icon hover title ***/
  }	
  .w-20{	
    width:20px;
  }	
  .pf-w-40{	
    width:40px;
  }	
  .question-icon{	
    .path-finder-name{	
    padding-right: 10px;	   
  
    }
  }
