.header-white-bg {
  background-color: nth($white-shades, 1);
  @include position ($position: fixed, $top: 0px);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  width: 100%;
  @include z-index(modal);
  box-shadow: 0 0 10px 1px nth($grey-shades, 1);
  .container {
    padding: 0 !important;
  }
  .info-icon-styles{
    background-color: nth($white-shades,1);
    display: flex;
    align-items: center;
    border-radius: 3px;
    height: 27px;
    &.helping-box {
      button {
        color: nth($green-shades, 2);
      }
    }
    button{
      border-radius: 0;
      min-width: auto;
      @include breakpoint(sm) {
        padding: 0;
      }
    }
    .first-btn{
      border-right:1px solid; 
    }
  }
  .header {
    background: nth($green-shades, 2);
    color: nth($white-shades, 1);
    @include z-index(two);
    &.mat-toolbar-multiple-rows {
      min-height: 50px;
    }
    .career-trek-logo img {
      float: right;
      width: 95px;
      padding: 10px 0;
      @include breakpoint(sm) {
        float: left;
        width: 90px;
      }
    }
    .header-menu-list {
      justify-content: flex-end;
      .icon-btn {
        background: none;
        box-shadow: none;
        border: none;
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: center;
        color: nth($white-shades, 1);
        .mat-icon:not([color]) {
          color: nth($white-shades, 1);
        }
      }
      ul {
        list-style: none;
        float: right;
        margin: 0;
        padding: 0;
        height: 100%;
        li {
          padding: 0 5px;
          .menu-button {
            min-width: 70px;
            background-color: nth($white-shades, 1);
          }
          @include breakpoint(lg-down) {
            padding: 0px 3px;
          }
        }
      }
    }
    .mat-toolbar-row {
      height: 50px;
    }
    //override the default material button 
    //for that purpose we have written important
    .mat-button {
      line-height: 25px;
      color: nth($green-shades, 2);
      @include breakpoint(lg-down) {
        font-size: nth($font-small-point, 1);
        padding: 0px 3px;
        margin: 0px!important;
      }
    }
  }
  .mobile-logout {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none !important;
  }
  .mobile_menu {
    .mat-menu-content {
      @include breakpoint(sm) {
        display:none;
      }
      @include breakpoint(sm) {
        display: block;
      }
    }
  }
  .deskTop_menu {
    .mat-menu-content {
      @include breakpoint(sm) {
        display: none;
      }
      @include breakpoint(sm) {
        display: block;
      }
    }
  }
  .icon-list li {
    padding: 2px 3px;
    display: flex;
    align-items: center;
    .mat-icon {
      font-size: nth($font-small-point, 3);
      height: auto;
    }
  }
  .mat-icon-button {
    color: nth($green-shades, 2);
  }
  .back-button {
    border: 2px solid nth($white-shades, 1) !important;
    background-color: nth($grey-shades, 7) !important;
    display: flex;
    margin: 0 4px;
    @include width-height(30px !important, 30px !important);
    i {
      color: nth($black-shades, 4) !important;
    }
    @include breakpoint(sm)  {
      @include width-height(20px !important, 20px !important);
      i {
        font-size: $default-font;
      }
    }
  }
  p.title {
    margin: 0px 8px;
    color: nth($white-shades, 1);
    font-size: nth($font-small-point, 3);
    font-weight: bold;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    @include breakpoint(lg-down) {
      font-size: nth($font-small-point, 4);
      margin: 0px 3px 0px 3px;
    }
    @include breakpoint(xs-max) {
      font-size: nth($font-small-point, 5);
      margin: 0px 3px 0px 3px;
    }
  }
}
.profile-btn {
  display: flex!important;
  align-items: center;
  i{
    margin-left: 5px !important;
  }
 }
 .menu-icon-button,
 .help-button {
    color:nth($white-shades,1);
  }
 .sidemenu-btn i {
  font-size: 28px;
 }
 .header-component-icon {
   img {
    width: 30px;
    height: 30px;
    &.navigator-component-icon {
      width: 40px;
      height: 40px;
     }
     &.pathfinder-component-icon {
      width: 27px;
     }
   }
 }
