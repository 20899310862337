.scout-intro {
    padding: 20px 0;
    @include breakpoint(sm) {
        &.content-box {
            margin-top: 0;
        }
    }
    .intro-block-header {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid nth($white-shades, 10);
        padding-bottom: 30px;
        margin-bottom: 30px;
        @include breakpoint(sm-up){
            .ideaimg{
                width: 180px;
            }
        }
        img {
            width: 150px;
        }
        .page-title {
            font-size: nth($font-small-point, 3);
            color: nth($orange-shades, 5);
        }
        p {
            font-size: nth($font-small-point, 2);
            font-weight: bold;
            padding-left: 20px;
            margin-bottom: 0;
        }
        @include breakpoint(sm) {
            img {
                width: 100px;
            }
            p {
                font-size: nth($font-small-point, 3);
            }
         }
    }
    .scout-intro-body {
        @include breakpoint(xl-down) {
            width: 95%;
            margin: auto;
        }
    }
    .scout-intro-cards {
        border: 1px solid nth($green-shades, 11);
        height: 300px;
        display: flex;
        align-items: center;
        margin: 0 5px;
        justify-content: center;
        .card-body {
            img {
                width: 100px;
                @include breakpoint(lg-up) {
                    width: 130px;
                    height: 100px;
                }
            }
            .card-title {
                padding-top: 10px;
            }
        }
        &.mat-card:not([class*=mat-elevation-z]) {
            box-shadow: none;
        }
        @include breakpoint(lg-down) {
            padding: 10px;
            height: 120px;
            margin: 0 5px 10px;
            flex: auto !important; //This style for override the flex block value in mobile screen
        }
    }
    .scout-intro-activity-block {
        @include breakpoint(sm) {
            display: block !important; //This style for override the flex block value in mobile screen
         }
        .scout-intro-activity-cards {
            margin: 0 15px;
            @include breakpoint(sm) {
                width: 300px;
                margin: auto auto 10px;
             }
            .card {
                border: 0;
                box-shadow: none;
            }         
            .scout-activity-card-body {
                border: 1px solid nth($green-shades, 11);
                border-radius: 4px;
                box-shadow: 0 0 10px 2px nth($grey-shades, 20);
                .active-image {
                    position: relative;
                    .opacity-text {
                        text-align: left;
                        p {
                            height: 40px;
                            padding-left: 20px;
                        }
                    }
                    .active-check {
                        position: absolute;
                        left: 45%;
                        right: 50%;
                        top: 25%;
                        transform: translate(-50%, 50%);
                        img {
                            width: 60px;
                        }
                        @include breakpoint(xl-up) {
                            top: 20%;
                            img {
                                width: 80px;
                            }
                        }
                        @include breakpoint((sm,lg)) {
                            left: 42%;
                            top: 10%;
                        }
                    }
                }
            }        
            .scout-intro-header {
                margin-bottom: 0;
                padding: 0 10px;
                border-bottom: 1px solid nth($green-shades, 11);
                img {
                    width: 80px;
                    height: 70px;
                }
            } 
            .scout-intro-body {
                padding: 10px 10px 0;
                @include breakpoint(lg-down) {
                    display: flex;
                    padding: 10px 0 0 10px;
                 }
                .active-image {
                    position: relative;
                    @include breakpoint(lg-down) {
                       margin-right: 10px;
                    }
                    .opacity-text {
                        text-align: left;
                        p {
                            height: 40px;
                            font-size: $default-font;
                            padding-left: 20px;
                        }
                    }
                    img {
                        border-radius: 4px;
                    }
                }
            }
            .mat-card-actions {
                text-align: right;
                margin: 0 !important;
                padding: 0;
                .mat-button {
                    min-width: 60px;
                    border-radius: 4px;
                    padding: 0;
                    color: nth($white-shades, 1);
                    &.active-set {
                        &::before {
                            position: absolute;
                            content: "";
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0,0,0,.12);
                            box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2),
                                        0 6px 10px 0 rgba(0,0,0,0.14),
                                        0 1px 18px 0 rgba(0,0,0,0.12);
                        }
                    }
                }
                .btn-yes {
                    background-color: nth($green-shades, 13);
                }
                .btn-no {
                    background-color: nth($red-shades, 10);
                }
            }
        }
    }
    @include breakpoint(sm) {
        &.content-box {
            margin-top: 0;
        }
        .next-level {
            top: -7%;
        }
        .intro-block-header {
            border-bottom: inherit;
        }
    }
}
.scout-activity {
    padding: 70px 0;
    .activity-header {
        text-align: center;
        padding: 0 10px 40px;
        .audio-box {
            margin-left: 5px;
            vertical-align: middle;
        }
    }
    @include breakpoint(sm) {
        padding: 30px 0;
        &.content-box {
            margin-top: 0;
        }
        .activity-header {            
            padding-bottom: 10px;
        }
        .next-level {
            top: 13%;
            right: 15px;
        }
    }
    .activity-cards-body {
        position: relative;
        @include breakpoint(sm-up) {
            .mat-radio-group {
                display: flex;
                justify-content: center;
            }
        }
        .activity-cards {
            position: relative;
            margin: 0 20px;
            width: 300px;
            margin: auto;
            @include breakpoint(sm-up) {
                width: 40%;
                margin: inherit;
            }
            @include breakpoint(lg-up) {
                width: 30%;
            }
            .mat-card {
                padding: 0;
                border-radius: 0;
                box-shadow: none;
                background-color: transparent;
            }
            .activity-body {
                border: 2px solid nth($green-shades, 11);
            }
            .mat-card-footer {
                margin: 0;
                color: nth($black-shades, 5);
                font-size: nth($font-small-point, 12);
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;
                position: relative;
                @include z-index(nine);
                @include breakpoint(sm) {
                    margin-bottom: 20px;
                }
                .audio-box {
                    margin-left: 15px;
                    min-width: 26px !important;
                    height: 26px;
                    i {
                        font-size: $default-font;
                    }
                }
            }
            .mat-radio-button {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 9;
            }
            .mat-radio-outer-circle {
                border-width: 0;
            }
            .mat-radio-ripple {
                display: none;
            }
            .mat-radio-label {
                width: 100%;
                height: 100%;
                .mat-radio-container {
                    position: absolute;
                    left: 20%;
                    top: 10%;
                    transform: translate(-50%, -50%);
                    @include breakpoint(xl-up) {
                        top: 13%;
                    }
                    @include breakpoint(sm) {
                        left: 28%;
                        top: 20%;
                    }
                }
            }
            .mat-radio-button.mat-accent .mat-radio-inner-circle {
                background-color: transparent;
                background-image: url(/assets/images/cardclick.png);
                width: 210px;
                height: 170px;
                background-size: cover;
                @include breakpoint(lg-down) {
                    width: 150px;
                    height: 120px;
                }
            }
            &.inner-block {
                .or-block {
                    text-align: center;
                    padding-bottom: 20px;
                }
                &:last-child {
                    .or-block {
                        display: none;
                    }
                }
                @include breakpoint(sm-up) {
                    display: flex;
                    .or-block {
                        right: -56px;
                        top: 34%;
                        position: absolute;
                        padding: 0;
                    }
                    .or-text-spanish {
                        right: -50px;
                    }
                    &:last-child {
                        margin-left: 80px;
                    }
                }
            }
        }
        @include breakpoint(sm) {
            .mobile-design-show {
                height: 35px;
                .next-level {
                    top: 0;
                }
            }
        }
    }
    &.navigator-challenge {
        .activity-cards-body .activity-cards {
            .activity-body {
                border: 2px solid nth($red-shades, 9);
            }
        }
    }
    &.scout-tie {
        @include breakpoint(sm) {
            .activity-header {
                font-size: nth($font-small-point, 3);
            }
        }
        .activity-cards {
            margin: 0;
            .self-survery-block {
                text-align: center;
                padding: 50px 0;
                img {
                    height: 106px;
                    width: 150px;
                }
            }
            @include breakpoint(xs-max) {
                flex: auto !important; //This style for override the flex block value in mobile screen
                width: 100%;
                .self-survery-block {
                    padding: 20px 0;
                    img {
                        width: 100px;
                    }
                }
            }
        }        
    }
}

.scout-results {
    padding: 0 30px 30px;
    text-align: center;
    .result-image img {
        width: 150px;
        padding-top: 20px;
    }
    .scout-result-header {
        margin-bottom: 20px;
    }
    .scout-result-block {
        @include breakpoint(lg-up) {
            display: flex;
            justify-content: center;
        }
        .result-cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .career-creator-nav {
            padding: 0 0 0 10px;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .scout-result-cards {
            @include breakpoint(sm-up) {
                width: 50%;
            }
            .card {
                border: 1px solid nth($green-shades, 11);
                margin: 5px;
                img {
                    min-height: 272px;
                    border-radius: 4px;
                }
                img{
                    @include breakpoint(xl-down){
                        min-height: 213px;
                    }
                }
                img{
                    @include breakpoint(lg-down){
                        min-height: 172px;
                    }
                }
                img{
                    @include breakpoint(sm){
                        min-height: 172px;
                        width: 250px;
                    }
                }
            }
            .scout-result-body {
                .opacity-text img {
                    width: 30px;
                }
                .opacity-text p {
                    height: 40px;
                }
                .active-bar {
                    .opacity-text p {
                        &::before {
                            background-color: nth($green-shades, 11);
                        }
                    }
                }
            }
        }
    }
}