// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$-e-s-decision-name: 'ES_decision';
$-e-s-decision-x: 225px;
$-e-s-decision-y: 675px;
$-e-s-decision-offset-x: -225px;
$-e-s-decision-offset-y: -675px;
$-e-s-decision-width: 150px;
$-e-s-decision-height: 150px;
$-e-s-decision-total-width: 1150px;
$-e-s-decision-total-height: 1150px;
$-e-s-decision-image: '/img/icons-sprites.png';
$-e-s-decision: (225px, 675px, -225px, -675px, 150px, 150px, 1150px, 1150px, '/img/icons-sprites.png', 'ES_decision', );
$-e-s-honest-name: 'ES_honest';
$-e-s-honest-x: 900px;
$-e-s-honest-y: 150px;
$-e-s-honest-offset-x: -900px;
$-e-s-honest-offset-y: -150px;
$-e-s-honest-width: 150px;
$-e-s-honest-height: 150px;
$-e-s-honest-total-width: 1150px;
$-e-s-honest-total-height: 1150px;
$-e-s-honest-image: '/img/icons-sprites.png';
$-e-s-honest: (900px, 150px, -900px, -150px, 150px, 150px, 1150px, 1150px, '/img/icons-sprites.png', 'ES_honest', );
$-e-s-listen-name: 'ES_listen';
$-e-s-listen-x: 0px;
$-e-s-listen-y: 900px;
$-e-s-listen-offset-x: 0px;
$-e-s-listen-offset-y: -900px;
$-e-s-listen-width: 150px;
$-e-s-listen-height: 150px;
$-e-s-listen-total-width: 1150px;
$-e-s-listen-total-height: 1150px;
$-e-s-listen-image: '/img/icons-sprites.png';
$-e-s-listen: (0px, 900px, 0px, -900px, 150px, 150px, 1150px, 1150px, '/img/icons-sprites.png', 'ES_listen', );
$-e-s-manage-name: 'ES_manage';
$-e-s-manage-x: 900px;
$-e-s-manage-y: 300px;
$-e-s-manage-offset-x: -900px;
$-e-s-manage-offset-y: -300px;
$-e-s-manage-width: 150px;
$-e-s-manage-height: 150px;
$-e-s-manage-total-width: 1150px;
$-e-s-manage-total-height: 1150px;
$-e-s-manage-image: '/img/icons-sprites.png';
$-e-s-manage: (900px, 300px, -900px, -300px, 150px, 150px, 1150px, 1150px, '/img/icons-sprites.png', 'ES_manage', );
$-e-s-math-name: 'ES_math';
$-e-s-math-x: 900px;
$-e-s-math-y: 450px;
$-e-s-math-offset-x: -900px;
$-e-s-math-offset-y: -450px;
$-e-s-math-width: 150px;
$-e-s-math-height: 150px;
$-e-s-math-total-width: 1150px;
$-e-s-math-total-height: 1150px;
$-e-s-math-image: '/img/icons-sprites.png';
$-e-s-math: (900px, 450px, -900px, -450px, 150px, 150px, 1150px, 1150px, '/img/icons-sprites.png', 'ES_math', );
$-e-s-read-name: 'ES_read';
$-e-s-read-x: 900px;
$-e-s-read-y: 600px;
$-e-s-read-offset-x: -900px;
$-e-s-read-offset-y: -600px;
$-e-s-read-width: 150px;
$-e-s-read-height: 150px;
$-e-s-read-total-width: 1150px;
$-e-s-read-total-height: 1150px;
$-e-s-read-image: '/img/icons-sprites.png';
$-e-s-read: (900px, 600px, -900px, -600px, 150px, 150px, 1150px, 1150px, '/img/icons-sprites.png', 'ES_read', );
$-e-s-solve-name: 'ES_solve';
$-e-s-solve-x: 900px;
$-e-s-solve-y: 750px;
$-e-s-solve-offset-x: -900px;
$-e-s-solve-offset-y: -750px;
$-e-s-solve-width: 150px;
$-e-s-solve-height: 150px;
$-e-s-solve-total-width: 1150px;
$-e-s-solve-total-height: 1150px;
$-e-s-solve-image: '/img/icons-sprites.png';
$-e-s-solve: (900px, 750px, -900px, -750px, 150px, 150px, 1150px, 1150px, '/img/icons-sprites.png', 'ES_solve', );
$-e-s-speak-name: 'ES_speak';
$-e-s-speak-x: 375px;
$-e-s-speak-y: 675px;
$-e-s-speak-offset-x: -375px;
$-e-s-speak-offset-y: -675px;
$-e-s-speak-width: 150px;
$-e-s-speak-height: 150px;
$-e-s-speak-total-width: 1150px;
$-e-s-speak-total-height: 1150px;
$-e-s-speak-image: '/img/icons-sprites.png';
$-e-s-speak: (375px, 675px, -375px, -675px, 150px, 150px, 1150px, 1150px, '/img/icons-sprites.png', 'ES_speak', );
$-e-s-workothers-name: 'ES_workothers';
$-e-s-workothers-x: 525px;
$-e-s-workothers-y: 675px;
$-e-s-workothers-offset-x: -525px;
$-e-s-workothers-offset-y: -675px;
$-e-s-workothers-width: 150px;
$-e-s-workothers-height: 150px;
$-e-s-workothers-total-width: 1150px;
$-e-s-workothers-total-height: 1150px;
$-e-s-workothers-image: '/img/icons-sprites.png';
$-e-s-workothers: (525px, 675px, -525px, -675px, 150px, 150px, 1150px, 1150px, '/img/icons-sprites.png', 'ES_workothers', );
$-e-s-write-name: 'ES_write';
$-e-s-write-x: 900px;
$-e-s-write-y: 0px;
$-e-s-write-offset-x: -900px;
$-e-s-write-offset-y: 0px;
$-e-s-write-width: 150px;
$-e-s-write-height: 150px;
$-e-s-write-total-width: 1150px;
$-e-s-write-total-height: 1150px;
$-e-s-write-image: '/img/icons-sprites.png';
$-e-s-write: (900px, 0px, -900px, 0px, 150px, 150px, 1150px, 1150px, '/img/icons-sprites.png', 'ES_write', );
$box-e-s-decision-name: 'box-ES_decision';
$box-e-s-decision-x: 300px;
$box-e-s-decision-y: 1050px;
$box-e-s-decision-offset-x: -300px;
$box-e-s-decision-offset-y: -1050px;
$box-e-s-decision-width: 100px;
$box-e-s-decision-height: 100px;
$box-e-s-decision-total-width: 1150px;
$box-e-s-decision-total-height: 1150px;
$box-e-s-decision-image: '/img/icons-sprites.png';
$box-e-s-decision: (300px, 1050px, -300px, -1050px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-ES_decision', );
$box-e-s-honest-name: 'box-ES_honest';
$box-e-s-honest-x: 200px;
$box-e-s-honest-y: 1050px;
$box-e-s-honest-offset-x: -200px;
$box-e-s-honest-offset-y: -1050px;
$box-e-s-honest-width: 100px;
$box-e-s-honest-height: 100px;
$box-e-s-honest-total-width: 1150px;
$box-e-s-honest-total-height: 1150px;
$box-e-s-honest-image: '/img/icons-sprites.png';
$box-e-s-honest: (200px, 1050px, -200px, -1050px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-ES_honest', );
$box-e-s-listen-name: 'box-ES_listen';
$box-e-s-listen-x: 100px;
$box-e-s-listen-y: 1050px;
$box-e-s-listen-offset-x: -100px;
$box-e-s-listen-offset-y: -1050px;
$box-e-s-listen-width: 100px;
$box-e-s-listen-height: 100px;
$box-e-s-listen-total-width: 1150px;
$box-e-s-listen-total-height: 1150px;
$box-e-s-listen-image: '/img/icons-sprites.png';
$box-e-s-listen: (100px, 1050px, -100px, -1050px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-ES_listen', );
$box-e-s-manage-name: 'box-ES_manage';
$box-e-s-manage-x: 0px;
$box-e-s-manage-y: 1050px;
$box-e-s-manage-offset-x: 0px;
$box-e-s-manage-offset-y: -1050px;
$box-e-s-manage-width: 100px;
$box-e-s-manage-height: 100px;
$box-e-s-manage-total-width: 1150px;
$box-e-s-manage-total-height: 1150px;
$box-e-s-manage-image: '/img/icons-sprites.png';
$box-e-s-manage: (0px, 1050px, 0px, -1050px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-ES_manage', );
$box-e-s-math-name: 'box-ES_math';
$box-e-s-math-x: 1050px;
$box-e-s-math-y: 900px;
$box-e-s-math-offset-x: -1050px;
$box-e-s-math-offset-y: -900px;
$box-e-s-math-width: 100px;
$box-e-s-math-height: 100px;
$box-e-s-math-total-width: 1150px;
$box-e-s-math-total-height: 1150px;
$box-e-s-math-image: '/img/icons-sprites.png';
$box-e-s-math: (1050px, 900px, -1050px, -900px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-ES_math', );
$box-e-s-read-name: 'box-ES_read';
$box-e-s-read-x: 1050px;
$box-e-s-read-y: 800px;
$box-e-s-read-offset-x: -1050px;
$box-e-s-read-offset-y: -800px;
$box-e-s-read-width: 100px;
$box-e-s-read-height: 100px;
$box-e-s-read-total-width: 1150px;
$box-e-s-read-total-height: 1150px;
$box-e-s-read-image: '/img/icons-sprites.png';
$box-e-s-read: (1050px, 800px, -1050px, -800px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-ES_read', );
$box-e-s-solve-name: 'box-ES_solve';
$box-e-s-solve-x: 1050px;
$box-e-s-solve-y: 700px;
$box-e-s-solve-offset-x: -1050px;
$box-e-s-solve-offset-y: -700px;
$box-e-s-solve-width: 100px;
$box-e-s-solve-height: 100px;
$box-e-s-solve-total-width: 1150px;
$box-e-s-solve-total-height: 1150px;
$box-e-s-solve-image: '/img/icons-sprites.png';
$box-e-s-solve: (1050px, 700px, -1050px, -700px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-ES_solve', );
$box-e-s-speak-name: 'box-ES_speak';
$box-e-s-speak-x: 1050px;
$box-e-s-speak-y: 600px;
$box-e-s-speak-offset-x: -1050px;
$box-e-s-speak-offset-y: -600px;
$box-e-s-speak-width: 100px;
$box-e-s-speak-height: 100px;
$box-e-s-speak-total-width: 1150px;
$box-e-s-speak-total-height: 1150px;
$box-e-s-speak-image: '/img/icons-sprites.png';
$box-e-s-speak: (1050px, 600px, -1050px, -600px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-ES_speak', );
$box-e-s-workothers-name: 'box-ES_workothers';
$box-e-s-workothers-x: 1050px;
$box-e-s-workothers-y: 500px;
$box-e-s-workothers-offset-x: -1050px;
$box-e-s-workothers-offset-y: -500px;
$box-e-s-workothers-width: 100px;
$box-e-s-workothers-height: 100px;
$box-e-s-workothers-total-width: 1150px;
$box-e-s-workothers-total-height: 1150px;
$box-e-s-workothers-image: '/img/icons-sprites.png';
$box-e-s-workothers: (1050px, 500px, -1050px, -500px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-ES_workothers', );
$box-e-s-write-name: 'box-ES_write';
$box-e-s-write-x: 1050px;
$box-e-s-write-y: 400px;
$box-e-s-write-offset-x: -1050px;
$box-e-s-write-offset-y: -400px;
$box-e-s-write-width: 100px;
$box-e-s-write-height: 100px;
$box-e-s-write-total-width: 1150px;
$box-e-s-write-total-height: 1150px;
$box-e-s-write-image: '/img/icons-sprites.png';
$box-e-s-write: (1050px, 400px, -1050px, -400px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-ES_write', );
$box-char-abc-name: 'box-char_abc';
$box-char-abc-x: 150px;
$box-char-abc-y: 900px;
$box-char-abc-offset-x: -150px;
$box-char-abc-offset-y: -900px;
$box-char-abc-width: 100px;
$box-char-abc-height: 100px;
$box-char-abc-total-width: 1150px;
$box-char-abc-total-height: 1150px;
$box-char-abc-image: '/img/icons-sprites.png';
$box-char-abc: (150px, 900px, -150px, -900px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-char_abc', );
$box-char-activity-name: 'box-char_activity';
$box-char-activity-x: 1050px;
$box-char-activity-y: 200px;
$box-char-activity-offset-x: -1050px;
$box-char-activity-offset-y: -200px;
$box-char-activity-width: 100px;
$box-char-activity-height: 100px;
$box-char-activity-total-width: 1150px;
$box-char-activity-total-height: 1150px;
$box-char-activity-image: '/img/icons-sprites.png';
$box-char-activity: (1050px, 200px, -1050px, -200px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-char_activity', );
$box-char-education-name: 'box-char_education';
$box-char-education-x: 1050px;
$box-char-education-y: 100px;
$box-char-education-offset-x: -1050px;
$box-char-education-offset-y: -100px;
$box-char-education-width: 100px;
$box-char-education-height: 100px;
$box-char-education-total-width: 1150px;
$box-char-education-total-height: 1150px;
$box-char-education-image: '/img/icons-sprites.png';
$box-char-education: (1050px, 100px, -1050px, -100px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-char_education', );
$box-char-math-name: 'box-char_math';
$box-char-math-x: 1050px;
$box-char-math-y: 0px;
$box-char-math-offset-x: -1050px;
$box-char-math-offset-y: 0px;
$box-char-math-width: 100px;
$box-char-math-height: 100px;
$box-char-math-total-width: 1150px;
$box-char-math-total-height: 1150px;
$box-char-math-image: '/img/icons-sprites.png';
$box-char-math: (1050px, 0px, -1050px, 0px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-char_math', );
$box-char-money-name: 'box-char_money';
$box-char-money-x: 250px;
$box-char-money-y: 900px;
$box-char-money-offset-x: -250px;
$box-char-money-offset-y: -900px;
$box-char-money-width: 100px;
$box-char-money-height: 100px;
$box-char-money-total-width: 1150px;
$box-char-money-total-height: 1150px;
$box-char-money-image: '/img/icons-sprites.png';
$box-char-money: (250px, 900px, -250px, -900px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-char_money', );
$box-char-outdoors-name: 'box-char_outdoors';
$box-char-outdoors-x: 400px;
$box-char-outdoors-y: 1050px;
$box-char-outdoors-offset-x: -400px;
$box-char-outdoors-offset-y: -1050px;
$box-char-outdoors-width: 100px;
$box-char-outdoors-height: 100px;
$box-char-outdoors-total-width: 1150px;
$box-char-outdoors-total-height: 1150px;
$box-char-outdoors-image: '/img/icons-sprites.png';
$box-char-outdoors: (400px, 1050px, -400px, -1050px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-char_outdoors', );
$box-char-question-name: 'box-char_question';
$box-char-question-x: 1050px;
$box-char-question-y: 300px;
$box-char-question-offset-x: -1050px;
$box-char-question-offset-y: -300px;
$box-char-question-width: 100px;
$box-char-question-height: 100px;
$box-char-question-total-width: 1150px;
$box-char-question-total-height: 1150px;
$box-char-question-image: '/img/icons-sprites.png';
$box-char-question: (1050px, 300px, -1050px, -300px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-char_question', );
$box-globe-name: 'box-globe';
$box-globe-x: 350px;
$box-globe-y: 900px;
$box-globe-offset-x: -350px;
$box-globe-offset-y: -900px;
$box-globe-width: 100px;
$box-globe-height: 100px;
$box-globe-total-width: 1150px;
$box-globe-total-height: 1150px;
$box-globe-image: '/img/icons-sprites.png';
$box-globe: (350px, 900px, -350px, -900px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-globe', );
$box-hc-a-name: 'box-hc_A';
$box-hc-a-x: 450px;
$box-hc-a-y: 900px;
$box-hc-a-offset-x: -450px;
$box-hc-a-offset-y: -900px;
$box-hc-a-width: 100px;
$box-hc-a-height: 100px;
$box-hc-a-total-width: 1150px;
$box-hc-a-total-height: 1150px;
$box-hc-a-image: '/img/icons-sprites.png';
$box-hc-a: (450px, 900px, -450px, -900px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-hc_A', );
$box-hc-c-name: 'box-hc_C';
$box-hc-c-x: 550px;
$box-hc-c-y: 900px;
$box-hc-c-offset-x: -550px;
$box-hc-c-offset-y: -900px;
$box-hc-c-width: 100px;
$box-hc-c-height: 100px;
$box-hc-c-total-width: 1150px;
$box-hc-c-total-height: 1150px;
$box-hc-c-image: '/img/icons-sprites.png';
$box-hc-c: (550px, 900px, -550px, -900px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-hc_C', );
$box-hc-e-name: 'box-hc_E';
$box-hc-e-x: 650px;
$box-hc-e-y: 900px;
$box-hc-e-offset-x: -650px;
$box-hc-e-offset-y: -900px;
$box-hc-e-width: 100px;
$box-hc-e-height: 100px;
$box-hc-e-total-width: 1150px;
$box-hc-e-total-height: 1150px;
$box-hc-e-image: '/img/icons-sprites.png';
$box-hc-e: (650px, 900px, -650px, -900px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-hc_E', );
$box-hc-i-name: 'box-hc_I';
$box-hc-i-x: 750px;
$box-hc-i-y: 900px;
$box-hc-i-offset-x: -750px;
$box-hc-i-offset-y: -900px;
$box-hc-i-width: 100px;
$box-hc-i-height: 100px;
$box-hc-i-total-width: 1150px;
$box-hc-i-total-height: 1150px;
$box-hc-i-image: '/img/icons-sprites.png';
$box-hc-i: (750px, 900px, -750px, -900px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-hc_I', );
$box-hc-r-name: 'box-hc_R';
$box-hc-r-x: 850px;
$box-hc-r-y: 900px;
$box-hc-r-offset-x: -850px;
$box-hc-r-offset-y: -900px;
$box-hc-r-width: 100px;
$box-hc-r-height: 100px;
$box-hc-r-total-width: 1150px;
$box-hc-r-total-height: 1150px;
$box-hc-r-image: '/img/icons-sprites.png';
$box-hc-r: (850px, 900px, -850px, -900px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-hc_R', );
$box-hc-s-name: 'box-hc_S';
$box-hc-s-x: 950px;
$box-hc-s-y: 900px;
$box-hc-s-offset-x: -950px;
$box-hc-s-offset-y: -900px;
$box-hc-s-width: 100px;
$box-hc-s-height: 100px;
$box-hc-s-total-width: 1150px;
$box-hc-s-total-height: 1150px;
$box-hc-s-image: '/img/icons-sprites.png';
$box-hc-s: (950px, 900px, -950px, -900px, 100px, 100px, 1150px, 1150px, '/img/icons-sprites.png', 'box-hc_S', );
$char-abc-name: 'char_abc';
$char-abc-x: 225px;
$char-abc-y: 0px;
$char-abc-offset-x: -225px;
$char-abc-offset-y: 0px;
$char-abc-width: 225px;
$char-abc-height: 225px;
$char-abc-total-width: 1150px;
$char-abc-total-height: 1150px;
$char-abc-image: '/img/icons-sprites.png';
$char-abc: (225px, 0px, -225px, 0px, 225px, 225px, 1150px, 1150px, '/img/icons-sprites.png', 'char_abc', );
$char-activity-name: 'char_activity';
$char-activity-x: 0px;
$char-activity-y: 675px;
$char-activity-offset-x: 0px;
$char-activity-offset-y: -675px;
$char-activity-width: 225px;
$char-activity-height: 225px;
$char-activity-total-width: 1150px;
$char-activity-total-height: 1150px;
$char-activity-image: '/img/icons-sprites.png';
$char-activity: (0px, 675px, 0px, -675px, 225px, 225px, 1150px, 1150px, '/img/icons-sprites.png', 'char_activity', );
$char-education-name: 'char_education';
$char-education-x: 675px;
$char-education-y: 450px;
$char-education-offset-x: -675px;
$char-education-offset-y: -450px;
$char-education-width: 225px;
$char-education-height: 225px;
$char-education-total-width: 1150px;
$char-education-total-height: 1150px;
$char-education-image: '/img/icons-sprites.png';
$char-education: (675px, 450px, -675px, -450px, 225px, 225px, 1150px, 1150px, '/img/icons-sprites.png', 'char_education', );
$char-math-name: 'char_math';
$char-math-x: 675px;
$char-math-y: 225px;
$char-math-offset-x: -675px;
$char-math-offset-y: -225px;
$char-math-width: 225px;
$char-math-height: 225px;
$char-math-total-width: 1150px;
$char-math-total-height: 1150px;
$char-math-image: '/img/icons-sprites.png';
$char-math: (675px, 225px, -675px, -225px, 225px, 225px, 1150px, 1150px, '/img/icons-sprites.png', 'char_math', );
$char-money-name: 'char_money';
$char-money-x: 675px;
$char-money-y: 0px;
$char-money-offset-x: -675px;
$char-money-offset-y: 0px;
$char-money-width: 225px;
$char-money-height: 225px;
$char-money-total-width: 1150px;
$char-money-total-height: 1150px;
$char-money-image: '/img/icons-sprites.png';
$char-money: (675px, 0px, -675px, 0px, 225px, 225px, 1150px, 1150px, '/img/icons-sprites.png', 'char_money', );
$char-outdoors-name: 'char_outdoors';
$char-outdoors-x: 450px;
$char-outdoors-y: 450px;
$char-outdoors-offset-x: -450px;
$char-outdoors-offset-y: -450px;
$char-outdoors-width: 225px;
$char-outdoors-height: 225px;
$char-outdoors-total-width: 1150px;
$char-outdoors-total-height: 1150px;
$char-outdoors-image: '/img/icons-sprites.png';
$char-outdoors: (450px, 450px, -450px, -450px, 225px, 225px, 1150px, 1150px, '/img/icons-sprites.png', 'char_outdoors', );
$char-question-name: 'char_question';
$char-question-x: 675px;
$char-question-y: 675px;
$char-question-offset-x: -675px;
$char-question-offset-y: -675px;
$char-question-width: 150px;
$char-question-height: 150px;
$char-question-total-width: 1150px;
$char-question-total-height: 1150px;
$char-question-image: '/img/icons-sprites.png';
$char-question: (675px, 675px, -675px, -675px, 150px, 150px, 1150px, 1150px, '/img/icons-sprites.png', 'char_question', );
$globe-name: 'globe';
$globe-x: 0px;
$globe-y: 0px;
$globe-offset-x: 0px;
$globe-offset-y: 0px;
$globe-width: 225px;
$globe-height: 225px;
$globe-total-width: 1150px;
$globe-total-height: 1150px;
$globe-image: '/img/icons-sprites.png';
$globe: (0px, 0px, 0px, 0px, 225px, 225px, 1150px, 1150px, '/img/icons-sprites.png', 'globe', );
$hc-a-name: 'hc_A';
$hc-a-x: 0px;
$hc-a-y: 450px;
$hc-a-offset-x: 0px;
$hc-a-offset-y: -450px;
$hc-a-width: 225px;
$hc-a-height: 225px;
$hc-a-total-width: 1150px;
$hc-a-total-height: 1150px;
$hc-a-image: '/img/icons-sprites.png';
$hc-a: (0px, 450px, 0px, -450px, 225px, 225px, 1150px, 1150px, '/img/icons-sprites.png', 'hc_A', );
$hc-c-name: 'hc_C';
$hc-c-x: 450px;
$hc-c-y: 225px;
$hc-c-offset-x: -450px;
$hc-c-offset-y: -225px;
$hc-c-width: 225px;
$hc-c-height: 225px;
$hc-c-total-width: 1150px;
$hc-c-total-height: 1150px;
$hc-c-image: '/img/icons-sprites.png';
$hc-c: (450px, 225px, -450px, -225px, 225px, 225px, 1150px, 1150px, '/img/icons-sprites.png', 'hc_C', );
$hc-e-name: 'hc_E';
$hc-e-x: 450px;
$hc-e-y: 0px;
$hc-e-offset-x: -450px;
$hc-e-offset-y: 0px;
$hc-e-width: 225px;
$hc-e-height: 225px;
$hc-e-total-width: 1150px;
$hc-e-total-height: 1150px;
$hc-e-image: '/img/icons-sprites.png';
$hc-e: (450px, 0px, -450px, 0px, 225px, 225px, 1150px, 1150px, '/img/icons-sprites.png', 'hc_E', );
$hc-i-name: 'hc_I';
$hc-i-x: 225px;
$hc-i-y: 225px;
$hc-i-offset-x: -225px;
$hc-i-offset-y: -225px;
$hc-i-width: 225px;
$hc-i-height: 225px;
$hc-i-total-width: 1150px;
$hc-i-total-height: 1150px;
$hc-i-image: '/img/icons-sprites.png';
$hc-i: (225px, 225px, -225px, -225px, 225px, 225px, 1150px, 1150px, '/img/icons-sprites.png', 'hc_I', );
$hc-r-name: 'hc_R';
$hc-r-x: 0px;
$hc-r-y: 225px;
$hc-r-offset-x: 0px;
$hc-r-offset-y: -225px;
$hc-r-width: 225px;
$hc-r-height: 225px;
$hc-r-total-width: 1150px;
$hc-r-total-height: 1150px;
$hc-r-image: '/img/icons-sprites.png';
$hc-r: (0px, 225px, 0px, -225px, 225px, 225px, 1150px, 1150px, '/img/icons-sprites.png', 'hc_R', );
$hc-s-name: 'hc_S';
$hc-s-x: 225px;
$hc-s-y: 450px;
$hc-s-offset-x: -225px;
$hc-s-offset-y: -450px;
$hc-s-width: 225px;
$hc-s-height: 225px;
$hc-s-total-width: 1150px;
$hc-s-total-height: 1150px;
$hc-s-image: '/img/icons-sprites.png';
$hc-s: (225px, 450px, -225px, -450px, 225px, 225px, 1150px, 1150px, '/img/icons-sprites.png', 'hc_S', );
$icon-e-s-decision-name: 'icon-ES_decision';
$icon-e-s-decision-x: 1050px;
$icon-e-s-decision-y: 1000px;
$icon-e-s-decision-offset-x: -1050px;
$icon-e-s-decision-offset-y: -1000px;
$icon-e-s-decision-width: 50px;
$icon-e-s-decision-height: 50px;
$icon-e-s-decision-total-width: 1150px;
$icon-e-s-decision-total-height: 1150px;
$icon-e-s-decision-image: '/img/icons-sprites.png';
$icon-e-s-decision: (1050px, 1000px, -1050px, -1000px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-ES_decision', );
$icon-e-s-honest-name: 'icon-ES_honest';
$icon-e-s-honest-x: 1100px;
$icon-e-s-honest-y: 1000px;
$icon-e-s-honest-offset-x: -1100px;
$icon-e-s-honest-offset-y: -1000px;
$icon-e-s-honest-width: 50px;
$icon-e-s-honest-height: 50px;
$icon-e-s-honest-total-width: 1150px;
$icon-e-s-honest-total-height: 1150px;
$icon-e-s-honest-image: '/img/icons-sprites.png';
$icon-e-s-honest: (1100px, 1000px, -1100px, -1000px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-ES_honest', );
$icon-e-s-listen-name: 'icon-ES_listen';
$icon-e-s-listen-x: 825px;
$icon-e-s-listen-y: 675px;
$icon-e-s-listen-offset-x: -825px;
$icon-e-s-listen-offset-y: -675px;
$icon-e-s-listen-width: 50px;
$icon-e-s-listen-height: 50px;
$icon-e-s-listen-total-width: 1150px;
$icon-e-s-listen-total-height: 1150px;
$icon-e-s-listen-image: '/img/icons-sprites.png';
$icon-e-s-listen: (825px, 675px, -825px, -675px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-ES_listen', );
$icon-e-s-manage-name: 'icon-ES_manage';
$icon-e-s-manage-x: 825px;
$icon-e-s-manage-y: 725px;
$icon-e-s-manage-offset-x: -825px;
$icon-e-s-manage-offset-y: -725px;
$icon-e-s-manage-width: 50px;
$icon-e-s-manage-height: 50px;
$icon-e-s-manage-total-width: 1150px;
$icon-e-s-manage-total-height: 1150px;
$icon-e-s-manage-image: '/img/icons-sprites.png';
$icon-e-s-manage: (825px, 725px, -825px, -725px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-ES_manage', );
$icon-e-s-math-name: 'icon-ES_math';
$icon-e-s-math-x: 825px;
$icon-e-s-math-y: 775px;
$icon-e-s-math-offset-x: -825px;
$icon-e-s-math-offset-y: -775px;
$icon-e-s-math-width: 50px;
$icon-e-s-math-height: 50px;
$icon-e-s-math-total-width: 1150px;
$icon-e-s-math-total-height: 1150px;
$icon-e-s-math-image: '/img/icons-sprites.png';
$icon-e-s-math: (825px, 775px, -825px, -775px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-ES_math', );
$icon-e-s-read-name: 'icon-ES_read';
$icon-e-s-read-x: 225px;
$icon-e-s-read-y: 825px;
$icon-e-s-read-offset-x: -225px;
$icon-e-s-read-offset-y: -825px;
$icon-e-s-read-width: 50px;
$icon-e-s-read-height: 50px;
$icon-e-s-read-total-width: 1150px;
$icon-e-s-read-total-height: 1150px;
$icon-e-s-read-image: '/img/icons-sprites.png';
$icon-e-s-read: (225px, 825px, -225px, -825px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-ES_read', );
$icon-e-s-solve-name: 'icon-ES_solve';
$icon-e-s-solve-x: 275px;
$icon-e-s-solve-y: 825px;
$icon-e-s-solve-offset-x: -275px;
$icon-e-s-solve-offset-y: -825px;
$icon-e-s-solve-width: 50px;
$icon-e-s-solve-height: 50px;
$icon-e-s-solve-total-width: 1150px;
$icon-e-s-solve-total-height: 1150px;
$icon-e-s-solve-image: '/img/icons-sprites.png';
$icon-e-s-solve: (275px, 825px, -275px, -825px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-ES_solve', );
$icon-e-s-speak-name: 'icon-ES_speak';
$icon-e-s-speak-x: 325px;
$icon-e-s-speak-y: 825px;
$icon-e-s-speak-offset-x: -325px;
$icon-e-s-speak-offset-y: -825px;
$icon-e-s-speak-width: 50px;
$icon-e-s-speak-height: 50px;
$icon-e-s-speak-total-width: 1150px;
$icon-e-s-speak-total-height: 1150px;
$icon-e-s-speak-image: '/img/icons-sprites.png';
$icon-e-s-speak: (325px, 825px, -325px, -825px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-ES_speak', );
$icon-e-s-workothers-name: 'icon-ES_workothers';
$icon-e-s-workothers-x: 375px;
$icon-e-s-workothers-y: 825px;
$icon-e-s-workothers-offset-x: -375px;
$icon-e-s-workothers-offset-y: -825px;
$icon-e-s-workothers-width: 50px;
$icon-e-s-workothers-height: 50px;
$icon-e-s-workothers-total-width: 1150px;
$icon-e-s-workothers-total-height: 1150px;
$icon-e-s-workothers-image: '/img/icons-sprites.png';
$icon-e-s-workothers: (375px, 825px, -375px, -825px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-ES_workothers', );
$icon-e-s-write-name: 'icon-ES_write';
$icon-e-s-write-x: 425px;
$icon-e-s-write-y: 825px;
$icon-e-s-write-offset-x: -425px;
$icon-e-s-write-offset-y: -825px;
$icon-e-s-write-width: 50px;
$icon-e-s-write-height: 50px;
$icon-e-s-write-total-width: 1150px;
$icon-e-s-write-total-height: 1150px;
$icon-e-s-write-image: '/img/icons-sprites.png';
$icon-e-s-write: (425px, 825px, -425px, -825px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-ES_write', );
$icon-char-abc-name: 'icon-char_abc';
$icon-char-abc-x: 475px;
$icon-char-abc-y: 825px;
$icon-char-abc-offset-x: -475px;
$icon-char-abc-offset-y: -825px;
$icon-char-abc-width: 50px;
$icon-char-abc-height: 50px;
$icon-char-abc-total-width: 1150px;
$icon-char-abc-total-height: 1150px;
$icon-char-abc-image: '/img/icons-sprites.png';
$icon-char-abc: (475px, 825px, -475px, -825px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-char_abc', );
$icon-char-activity-name: 'icon-char_activity';
$icon-char-activity-x: 525px;
$icon-char-activity-y: 825px;
$icon-char-activity-offset-x: -525px;
$icon-char-activity-offset-y: -825px;
$icon-char-activity-width: 50px;
$icon-char-activity-height: 50px;
$icon-char-activity-total-width: 1150px;
$icon-char-activity-total-height: 1150px;
$icon-char-activity-image: '/img/icons-sprites.png';
$icon-char-activity: (525px, 825px, -525px, -825px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-char_activity', );
$icon-char-education-name: 'icon-char_education';
$icon-char-education-x: 575px;
$icon-char-education-y: 825px;
$icon-char-education-offset-x: -575px;
$icon-char-education-offset-y: -825px;
$icon-char-education-width: 50px;
$icon-char-education-height: 50px;
$icon-char-education-total-width: 1150px;
$icon-char-education-total-height: 1150px;
$icon-char-education-image: '/img/icons-sprites.png';
$icon-char-education: (575px, 825px, -575px, -825px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-char_education', );
$icon-char-math-name: 'icon-char_math';
$icon-char-math-x: 625px;
$icon-char-math-y: 825px;
$icon-char-math-offset-x: -625px;
$icon-char-math-offset-y: -825px;
$icon-char-math-width: 50px;
$icon-char-math-height: 50px;
$icon-char-math-total-width: 1150px;
$icon-char-math-total-height: 1150px;
$icon-char-math-image: '/img/icons-sprites.png';
$icon-char-math: (625px, 825px, -625px, -825px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-char_math', );
$icon-char-money-name: 'icon-char_money';
$icon-char-money-x: 675px;
$icon-char-money-y: 825px;
$icon-char-money-offset-x: -675px;
$icon-char-money-offset-y: -825px;
$icon-char-money-width: 50px;
$icon-char-money-height: 50px;
$icon-char-money-total-width: 1150px;
$icon-char-money-total-height: 1150px;
$icon-char-money-image: '/img/icons-sprites.png';
$icon-char-money: (675px, 825px, -675px, -825px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-char_money', );
$icon-char-outdoors-name: 'icon-char_outdoors';
$icon-char-outdoors-x: 725px;
$icon-char-outdoors-y: 825px;
$icon-char-outdoors-offset-x: -725px;
$icon-char-outdoors-offset-y: -825px;
$icon-char-outdoors-width: 50px;
$icon-char-outdoors-height: 50px;
$icon-char-outdoors-total-width: 1150px;
$icon-char-outdoors-total-height: 1150px;
$icon-char-outdoors-image: '/img/icons-sprites.png';
$icon-char-outdoors: (725px, 825px, -725px, -825px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-char_outdoors', );
$icon-char-question-name: 'icon-char_question';
$icon-char-question-x: 775px;
$icon-char-question-y: 825px;
$icon-char-question-offset-x: -775px;
$icon-char-question-offset-y: -825px;
$icon-char-question-width: 50px;
$icon-char-question-height: 50px;
$icon-char-question-total-width: 1150px;
$icon-char-question-total-height: 1150px;
$icon-char-question-image: '/img/icons-sprites.png';
$icon-char-question: (775px, 825px, -775px, -825px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-char_question', );
$icon-globe-name: 'icon-globe';
$icon-globe-x: 825px;
$icon-globe-y: 825px;
$icon-globe-offset-x: -825px;
$icon-globe-offset-y: -825px;
$icon-globe-width: 50px;
$icon-globe-height: 50px;
$icon-globe-total-width: 1150px;
$icon-globe-total-height: 1150px;
$icon-globe-image: '/img/icons-sprites.png';
$icon-globe: (825px, 825px, -825px, -825px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-globe', );
$icon-hc-a-name: 'icon-hc_A';
$icon-hc-a-x: 150px;
$icon-hc-a-y: 1000px;
$icon-hc-a-offset-x: -150px;
$icon-hc-a-offset-y: -1000px;
$icon-hc-a-width: 50px;
$icon-hc-a-height: 50px;
$icon-hc-a-total-width: 1150px;
$icon-hc-a-total-height: 1150px;
$icon-hc-a-image: '/img/icons-sprites.png';
$icon-hc-a: (150px, 1000px, -150px, -1000px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-hc_A', );
$icon-hc-c-name: 'icon-hc_C';
$icon-hc-c-x: 200px;
$icon-hc-c-y: 1000px;
$icon-hc-c-offset-x: -200px;
$icon-hc-c-offset-y: -1000px;
$icon-hc-c-width: 50px;
$icon-hc-c-height: 50px;
$icon-hc-c-total-width: 1150px;
$icon-hc-c-total-height: 1150px;
$icon-hc-c-image: '/img/icons-sprites.png';
$icon-hc-c: (200px, 1000px, -200px, -1000px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-hc_C', );
$icon-hc-e-name: 'icon-hc_E';
$icon-hc-e-x: 250px;
$icon-hc-e-y: 1000px;
$icon-hc-e-offset-x: -250px;
$icon-hc-e-offset-y: -1000px;
$icon-hc-e-width: 50px;
$icon-hc-e-height: 50px;
$icon-hc-e-total-width: 1150px;
$icon-hc-e-total-height: 1150px;
$icon-hc-e-image: '/img/icons-sprites.png';
$icon-hc-e: (250px, 1000px, -250px, -1000px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-hc_E', );
$icon-hc-i-name: 'icon-hc_I';
$icon-hc-i-x: 300px;
$icon-hc-i-y: 1000px;
$icon-hc-i-offset-x: -300px;
$icon-hc-i-offset-y: -1000px;
$icon-hc-i-width: 50px;
$icon-hc-i-height: 50px;
$icon-hc-i-total-width: 1150px;
$icon-hc-i-total-height: 1150px;
$icon-hc-i-image: '/img/icons-sprites.png';
$icon-hc-i: (300px, 1000px, -300px, -1000px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-hc_I', );
$icon-hc-r-name: 'icon-hc_R';
$icon-hc-r-x: 350px;
$icon-hc-r-y: 1000px;
$icon-hc-r-offset-x: -350px;
$icon-hc-r-offset-y: -1000px;
$icon-hc-r-width: 50px;
$icon-hc-r-height: 50px;
$icon-hc-r-total-width: 1150px;
$icon-hc-r-total-height: 1150px;
$icon-hc-r-image: '/img/icons-sprites.png';
$icon-hc-r: (350px, 1000px, -350px, -1000px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-hc_R', );
$icon-hc-s-name: 'icon-hc_S';
$icon-hc-s-x: 400px;
$icon-hc-s-y: 1000px;
$icon-hc-s-offset-x: -400px;
$icon-hc-s-offset-y: -1000px;
$icon-hc-s-width: 50px;
$icon-hc-s-height: 50px;
$icon-hc-s-total-width: 1150px;
$icon-hc-s-total-height: 1150px;
$icon-hc-s-image: '/img/icons-sprites.png';
$icon-hc-s: (400px, 1000px, -400px, -1000px, 50px, 50px, 1150px, 1150px, '/img/icons-sprites.png', 'icon-hc_S', );
$mini-icon-e-s-decision-name: 'mini-icon-ES_decision';
$mini-icon-e-s-decision-x: 480px;
$mini-icon-e-s-decision-y: 1000px;
$mini-icon-e-s-decision-offset-x: -480px;
$mini-icon-e-s-decision-offset-y: -1000px;
$mini-icon-e-s-decision-width: 30px;
$mini-icon-e-s-decision-height: 30px;
$mini-icon-e-s-decision-total-width: 1150px;
$mini-icon-e-s-decision-total-height: 1150px;
$mini-icon-e-s-decision-image: '/img/icons-sprites.png';
$mini-icon-e-s-decision: (480px, 1000px, -480px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-ES_decision', );
$mini-icon-e-s-honest-name: 'mini-icon-ES_honest';
$mini-icon-e-s-honest-x: 810px;
$mini-icon-e-s-honest-y: 1000px;
$mini-icon-e-s-honest-offset-x: -810px;
$mini-icon-e-s-honest-offset-y: -1000px;
$mini-icon-e-s-honest-width: 30px;
$mini-icon-e-s-honest-height: 30px;
$mini-icon-e-s-honest-total-width: 1150px;
$mini-icon-e-s-honest-total-height: 1150px;
$mini-icon-e-s-honest-image: '/img/icons-sprites.png';
$mini-icon-e-s-honest: (810px, 1000px, -810px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-ES_honest', );
$mini-icon-e-s-listen-name: 'mini-icon-ES_listen';
$mini-icon-e-s-listen-x: 510px;
$mini-icon-e-s-listen-y: 1000px;
$mini-icon-e-s-listen-offset-x: -510px;
$mini-icon-e-s-listen-offset-y: -1000px;
$mini-icon-e-s-listen-width: 30px;
$mini-icon-e-s-listen-height: 30px;
$mini-icon-e-s-listen-total-width: 1150px;
$mini-icon-e-s-listen-total-height: 1150px;
$mini-icon-e-s-listen-image: '/img/icons-sprites.png';
$mini-icon-e-s-listen: (510px, 1000px, -510px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-ES_listen', );
$mini-icon-e-s-manage-name: 'mini-icon-ES_manage';
$mini-icon-e-s-manage-x: 540px;
$mini-icon-e-s-manage-y: 1000px;
$mini-icon-e-s-manage-offset-x: -540px;
$mini-icon-e-s-manage-offset-y: -1000px;
$mini-icon-e-s-manage-width: 30px;
$mini-icon-e-s-manage-height: 30px;
$mini-icon-e-s-manage-total-width: 1150px;
$mini-icon-e-s-manage-total-height: 1150px;
$mini-icon-e-s-manage-image: '/img/icons-sprites.png';
$mini-icon-e-s-manage: (540px, 1000px, -540px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-ES_manage', );
$mini-icon-e-s-math-name: 'mini-icon-ES_math';
$mini-icon-e-s-math-x: 570px;
$mini-icon-e-s-math-y: 1000px;
$mini-icon-e-s-math-offset-x: -570px;
$mini-icon-e-s-math-offset-y: -1000px;
$mini-icon-e-s-math-width: 30px;
$mini-icon-e-s-math-height: 30px;
$mini-icon-e-s-math-total-width: 1150px;
$mini-icon-e-s-math-total-height: 1150px;
$mini-icon-e-s-math-image: '/img/icons-sprites.png';
$mini-icon-e-s-math: (570px, 1000px, -570px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-ES_math', );
$mini-icon-e-s-read-name: 'mini-icon-ES_read';
$mini-icon-e-s-read-x: 600px;
$mini-icon-e-s-read-y: 1000px;
$mini-icon-e-s-read-offset-x: -600px;
$mini-icon-e-s-read-offset-y: -1000px;
$mini-icon-e-s-read-width: 30px;
$mini-icon-e-s-read-height: 30px;
$mini-icon-e-s-read-total-width: 1150px;
$mini-icon-e-s-read-total-height: 1150px;
$mini-icon-e-s-read-image: '/img/icons-sprites.png';
$mini-icon-e-s-read: (600px, 1000px, -600px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-ES_read', );
$mini-icon-e-s-solve-name: 'mini-icon-ES_solve';
$mini-icon-e-s-solve-x: 630px;
$mini-icon-e-s-solve-y: 1000px;
$mini-icon-e-s-solve-offset-x: -630px;
$mini-icon-e-s-solve-offset-y: -1000px;
$mini-icon-e-s-solve-width: 30px;
$mini-icon-e-s-solve-height: 30px;
$mini-icon-e-s-solve-total-width: 1150px;
$mini-icon-e-s-solve-total-height: 1150px;
$mini-icon-e-s-solve-image: '/img/icons-sprites.png';
$mini-icon-e-s-solve: (630px, 1000px, -630px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-ES_solve', );
$mini-icon-e-s-speak-name: 'mini-icon-ES_speak';
$mini-icon-e-s-speak-x: 660px;
$mini-icon-e-s-speak-y: 1000px;
$mini-icon-e-s-speak-offset-x: -660px;
$mini-icon-e-s-speak-offset-y: -1000px;
$mini-icon-e-s-speak-width: 30px;
$mini-icon-e-s-speak-height: 30px;
$mini-icon-e-s-speak-total-width: 1150px;
$mini-icon-e-s-speak-total-height: 1150px;
$mini-icon-e-s-speak-image: '/img/icons-sprites.png';
$mini-icon-e-s-speak: (660px, 1000px, -660px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-ES_speak', );
$mini-icon-e-s-workothers-name: 'mini-icon-ES_workothers';
$mini-icon-e-s-workothers-x: 690px;
$mini-icon-e-s-workothers-y: 1000px;
$mini-icon-e-s-workothers-offset-x: -690px;
$mini-icon-e-s-workothers-offset-y: -1000px;
$mini-icon-e-s-workothers-width: 30px;
$mini-icon-e-s-workothers-height: 30px;
$mini-icon-e-s-workothers-total-width: 1150px;
$mini-icon-e-s-workothers-total-height: 1150px;
$mini-icon-e-s-workothers-image: '/img/icons-sprites.png';
$mini-icon-e-s-workothers: (690px, 1000px, -690px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-ES_workothers', );
$mini-icon-e-s-write-name: 'mini-icon-ES_write';
$mini-icon-e-s-write-x: 720px;
$mini-icon-e-s-write-y: 1000px;
$mini-icon-e-s-write-offset-x: -720px;
$mini-icon-e-s-write-offset-y: -1000px;
$mini-icon-e-s-write-width: 30px;
$mini-icon-e-s-write-height: 30px;
$mini-icon-e-s-write-total-width: 1150px;
$mini-icon-e-s-write-total-height: 1150px;
$mini-icon-e-s-write-image: '/img/icons-sprites.png';
$mini-icon-e-s-write: (720px, 1000px, -720px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-ES_write', );
$mini-icon-char-abc-name: 'mini-icon-char_abc';
$mini-icon-char-abc-x: 750px;
$mini-icon-char-abc-y: 1000px;
$mini-icon-char-abc-offset-x: -750px;
$mini-icon-char-abc-offset-y: -1000px;
$mini-icon-char-abc-width: 30px;
$mini-icon-char-abc-height: 30px;
$mini-icon-char-abc-total-width: 1150px;
$mini-icon-char-abc-total-height: 1150px;
$mini-icon-char-abc-image: '/img/icons-sprites.png';
$mini-icon-char-abc: (750px, 1000px, -750px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-char_abc', );
$mini-icon-char-activity-name: 'mini-icon-char_activity';
$mini-icon-char-activity-x: 780px;
$mini-icon-char-activity-y: 1000px;
$mini-icon-char-activity-offset-x: -780px;
$mini-icon-char-activity-offset-y: -1000px;
$mini-icon-char-activity-width: 30px;
$mini-icon-char-activity-height: 30px;
$mini-icon-char-activity-total-width: 1150px;
$mini-icon-char-activity-total-height: 1150px;
$mini-icon-char-activity-image: '/img/icons-sprites.png';
$mini-icon-char-activity: (780px, 1000px, -780px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-char_activity', );
$mini-icon-char-education-name: 'mini-icon-char_education';
$mini-icon-char-education-x: 450px;
$mini-icon-char-education-y: 1000px;
$mini-icon-char-education-offset-x: -450px;
$mini-icon-char-education-offset-y: -1000px;
$mini-icon-char-education-width: 30px;
$mini-icon-char-education-height: 30px;
$mini-icon-char-education-total-width: 1150px;
$mini-icon-char-education-total-height: 1150px;
$mini-icon-char-education-image: '/img/icons-sprites.png';
$mini-icon-char-education: (450px, 1000px, -450px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-char_education', );
$mini-icon-char-math-name: 'mini-icon-char_math';
$mini-icon-char-math-x: 840px;
$mini-icon-char-math-y: 1000px;
$mini-icon-char-math-offset-x: -840px;
$mini-icon-char-math-offset-y: -1000px;
$mini-icon-char-math-width: 30px;
$mini-icon-char-math-height: 30px;
$mini-icon-char-math-total-width: 1150px;
$mini-icon-char-math-total-height: 1150px;
$mini-icon-char-math-image: '/img/icons-sprites.png';
$mini-icon-char-math: (840px, 1000px, -840px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-char_math', );
$mini-icon-char-money-name: 'mini-icon-char_money';
$mini-icon-char-money-x: 870px;
$mini-icon-char-money-y: 1000px;
$mini-icon-char-money-offset-x: -870px;
$mini-icon-char-money-offset-y: -1000px;
$mini-icon-char-money-width: 30px;
$mini-icon-char-money-height: 30px;
$mini-icon-char-money-total-width: 1150px;
$mini-icon-char-money-total-height: 1150px;
$mini-icon-char-money-image: '/img/icons-sprites.png';
$mini-icon-char-money: (870px, 1000px, -870px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-char_money', );
$mini-icon-char-outdoors-name: 'mini-icon-char_outdoors';
$mini-icon-char-outdoors-x: 900px;
$mini-icon-char-outdoors-y: 1000px;
$mini-icon-char-outdoors-offset-x: -900px;
$mini-icon-char-outdoors-offset-y: -1000px;
$mini-icon-char-outdoors-width: 30px;
$mini-icon-char-outdoors-height: 30px;
$mini-icon-char-outdoors-total-width: 1150px;
$mini-icon-char-outdoors-total-height: 1150px;
$mini-icon-char-outdoors-image: '/img/icons-sprites.png';
$mini-icon-char-outdoors: (900px, 1000px, -900px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-char_outdoors', );
$mini-icon-char-question-name: 'mini-icon-char_question';
$mini-icon-char-question-x: 930px;
$mini-icon-char-question-y: 1000px;
$mini-icon-char-question-offset-x: -930px;
$mini-icon-char-question-offset-y: -1000px;
$mini-icon-char-question-width: 30px;
$mini-icon-char-question-height: 30px;
$mini-icon-char-question-total-width: 1150px;
$mini-icon-char-question-total-height: 1150px;
$mini-icon-char-question-image: '/img/icons-sprites.png';
$mini-icon-char-question: (930px, 1000px, -930px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-char_question', );
$mini-icon-globe-name: 'mini-icon-globe';
$mini-icon-globe-x: 960px;
$mini-icon-globe-y: 1000px;
$mini-icon-globe-offset-x: -960px;
$mini-icon-globe-offset-y: -1000px;
$mini-icon-globe-width: 30px;
$mini-icon-globe-height: 30px;
$mini-icon-globe-total-width: 1150px;
$mini-icon-globe-total-height: 1150px;
$mini-icon-globe-image: '/img/icons-sprites.png';
$mini-icon-globe: (960px, 1000px, -960px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-globe', );
$mini-icon-hc-a-name: 'mini-icon-hc_A';
$mini-icon-hc-a-x: 990px;
$mini-icon-hc-a-y: 1000px;
$mini-icon-hc-a-offset-x: -990px;
$mini-icon-hc-a-offset-y: -1000px;
$mini-icon-hc-a-width: 30px;
$mini-icon-hc-a-height: 30px;
$mini-icon-hc-a-total-width: 1150px;
$mini-icon-hc-a-total-height: 1150px;
$mini-icon-hc-a-image: '/img/icons-sprites.png';
$mini-icon-hc-a: (990px, 1000px, -990px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-hc_A', );
$mini-icon-hc-c-name: 'mini-icon-hc_C';
$mini-icon-hc-c-x: 1020px;
$mini-icon-hc-c-y: 1000px;
$mini-icon-hc-c-offset-x: -1020px;
$mini-icon-hc-c-offset-y: -1000px;
$mini-icon-hc-c-width: 30px;
$mini-icon-hc-c-height: 30px;
$mini-icon-hc-c-total-width: 1150px;
$mini-icon-hc-c-total-height: 1150px;
$mini-icon-hc-c-image: '/img/icons-sprites.png';
$mini-icon-hc-c: (1020px, 1000px, -1020px, -1000px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-hc_C', );
$mini-icon-hc-e-name: 'mini-icon-hc_E';
$mini-icon-hc-e-x: 500px;
$mini-icon-hc-e-y: 1050px;
$mini-icon-hc-e-offset-x: -500px;
$mini-icon-hc-e-offset-y: -1050px;
$mini-icon-hc-e-width: 30px;
$mini-icon-hc-e-height: 30px;
$mini-icon-hc-e-total-width: 1150px;
$mini-icon-hc-e-total-height: 1150px;
$mini-icon-hc-e-image: '/img/icons-sprites.png';
$mini-icon-hc-e: (500px, 1050px, -500px, -1050px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-hc_E', );
$mini-icon-hc-i-name: 'mini-icon-hc_I';
$mini-icon-hc-i-x: 530px;
$mini-icon-hc-i-y: 1050px;
$mini-icon-hc-i-offset-x: -530px;
$mini-icon-hc-i-offset-y: -1050px;
$mini-icon-hc-i-width: 30px;
$mini-icon-hc-i-height: 30px;
$mini-icon-hc-i-total-width: 1150px;
$mini-icon-hc-i-total-height: 1150px;
$mini-icon-hc-i-image: '/img/icons-sprites.png';
$mini-icon-hc-i: (530px, 1050px, -530px, -1050px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-hc_I', );
$mini-icon-hc-r-name: 'mini-icon-hc_R';
$mini-icon-hc-r-x: 560px;
$mini-icon-hc-r-y: 1050px;
$mini-icon-hc-r-offset-x: -560px;
$mini-icon-hc-r-offset-y: -1050px;
$mini-icon-hc-r-width: 30px;
$mini-icon-hc-r-height: 30px;
$mini-icon-hc-r-total-width: 1150px;
$mini-icon-hc-r-total-height: 1150px;
$mini-icon-hc-r-image: '/img/icons-sprites.png';
$mini-icon-hc-r: (560px, 1050px, -560px, -1050px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-hc_R', );
$mini-icon-hc-s-name: 'mini-icon-hc_S';
$mini-icon-hc-s-x: 590px;
$mini-icon-hc-s-y: 1050px;
$mini-icon-hc-s-offset-x: -590px;
$mini-icon-hc-s-offset-y: -1050px;
$mini-icon-hc-s-width: 30px;
$mini-icon-hc-s-height: 30px;
$mini-icon-hc-s-total-width: 1150px;
$mini-icon-hc-s-total-height: 1150px;
$mini-icon-hc-s-image: '/img/icons-sprites.png';
$mini-icon-hc-s: (590px, 1050px, -590px, -1050px, 30px, 30px, 1150px, 1150px, '/img/icons-sprites.png', 'mini-icon-hc_S', );
$icons-width: 1150px;
$icons-height: 1150px;
$icons-image: '/img/icons-sprites.png';
$icons-sprites: ($-e-s-decision, $-e-s-honest, $-e-s-listen, $-e-s-manage, $-e-s-math, $-e-s-read, $-e-s-solve, $-e-s-speak, $-e-s-workothers, $-e-s-write, $box-e-s-decision, $box-e-s-honest, $box-e-s-listen, $box-e-s-manage, $box-e-s-math, $box-e-s-read, $box-e-s-solve, $box-e-s-speak, $box-e-s-workothers, $box-e-s-write, $box-char-abc, $box-char-activity, $box-char-education, $box-char-math, $box-char-money, $box-char-outdoors, $box-char-question, $box-globe, $box-hc-a, $box-hc-c, $box-hc-e, $box-hc-i, $box-hc-r, $box-hc-s, $char-abc, $char-activity, $char-education, $char-math, $char-money, $char-outdoors, $char-question, $globe, $hc-a, $hc-c, $hc-e, $hc-i, $hc-r, $hc-s, $icon-e-s-decision, $icon-e-s-honest, $icon-e-s-listen, $icon-e-s-manage, $icon-e-s-math, $icon-e-s-read, $icon-e-s-solve, $icon-e-s-speak, $icon-e-s-workothers, $icon-e-s-write, $icon-char-abc, $icon-char-activity, $icon-char-education, $icon-char-math, $icon-char-money, $icon-char-outdoors, $icon-char-question, $icon-globe, $icon-hc-a, $icon-hc-c, $icon-hc-e, $icon-hc-i, $icon-hc-r, $icon-hc-s, $mini-icon-e-s-decision, $mini-icon-e-s-honest, $mini-icon-e-s-listen, $mini-icon-e-s-manage, $mini-icon-e-s-math, $mini-icon-e-s-read, $mini-icon-e-s-solve, $mini-icon-e-s-speak, $mini-icon-e-s-workothers, $mini-icon-e-s-write, $mini-icon-char-abc, $mini-icon-char-activity, $mini-icon-char-education, $mini-icon-char-math, $mini-icon-char-money, $mini-icon-char-outdoors, $mini-icon-char-question, $mini-icon-globe, $mini-icon-hc-a, $mini-icon-hc-c, $mini-icon-hc-e, $mini-icon-hc-i, $mini-icon-hc-r, $mini-icon-hc-s, );
$icons: (1150px, 1150px, '/img/icons-sprites.png', $icons-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
