/******** **********  Career trekker intro page Start ********* *********/
.career-treker-heading {
  text-align: center;
  font-size: $default-font;
  margin-bottom: 10px;
  padding: 20px 0;
  color: nth($white-shades, 1);
  @include breakpoint(sm) {
    margin-bottom: 5px;
    padding: 10px 0;
  }
}
.career-trekker-into {
  padding: 20px 20px;
  .ct-cards {
    margin: 5px;
    .card {
      box-shadow: none;
      .card-header {
        font-size: $default-font;
        font-weight: 600;
        @include breakpoint(lg-down) {
          font-size: nth($font-small-point, 4);
        }
      }
      .card-body {
        min-height: calc(12vh - 50px) !important;
        img {
          width: 70px;
        }
      }
    }
  }
  @include breakpoint ((sm, lg)) {
    .card .card-header {
      font-size: nth($font-small-point, 4);
    }
  }
  @include breakpoint(sm) {
    padding: 10px 0px;
    &.content-box {
      margin-top: 0;
    }
    .card .card-body {
      min-height: 50px;
    }
    .ct-cards {
      margin: 1px;
      .card {
        display: flex;
        padding: 2px !important;
      }
      img {
        width: 50px !important;
        margin: 0 4px;
      }
    }
  }
  @include breakpoint ((sm, lg)) {
    .card-body img {
      width: 60px;
    }
  }
}

/******** **********  Career trekker intro page End ********* *********/

/******** **********  Career trekker assessment page Start ********* *********/

.career-trekker-assessment {
  padding: 30px 20px;
  @include breakpoint(sm) {
    padding: 10px 0px;
    &.content-box {
      margin-top: 20px;
    }
    .btn-next-mobile {
      width: 40px;
      text-align: right;
    }
  }
  .question-img {
    margin: 10px 0;
    img {
      width: 100px;
    }
  }
  .mobile-design-show {
    p {
      margin-bottom: 8px;
      font-size: nth($font-small-point, 4);
    }
  }
  .information,
  .question
    {
    padding: 0 10px;
  }
  .common-row-sub {
    margin: 20px 0 !important;
  }
  .test-question {
    border-left: 2px solid nth($grey-shades, 1);
    margin: 0 0 15px 0;
    @include breakpoint(sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    ul {
      padding: 0px 10px;
    }
    @include breakpoint(sm) {
      border-left: none;
      margin: 10px 0px 0px 0px;
    }
    .question {
      width: 90%;
      font-weight: bold;
      @include breakpoint(sm) {
        width: 100%;
      }
    }
    .mat-checkbox-frame,
    .mat-checkbox-background,
    .mat-checkbox-checked.mat-accent {
      border-radius: 50%;
    }
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: nth($green-shades, 2);
    }
    li {
      display: flex;
    }
    .mat-checkbox-layout {
      white-space: inherit !important;
      align-items: center !important;
      .mat-checkbox-label {
        line-height: 15px !important;
      }
    }
  }
  .card{
    &.ct{
      border: 1px solid nth($pink-shades, 3);
    }
  }
  .mat-card:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }
  .next-level {
    @include breakpoint(sm-up) {
      right: -25px;
    }
  }
  h2 {
    color: nth($black-shades, 5);
  } 
}

/******** **********  Career trekker assessment page End ********* *********/

/******** **********  Career trekker top skills page Start ********* *********/

.career-trekker-top-skills {
  padding: 10px;
  @include breakpoint(sm) {
    &.content-box {
      margin-top: 20px;
    }
    .card img {
      width: 80px;
    }
  }
  @include breakpoint(lg-up) {
    padding: 10px 0;
  }
  .selecting-cards {
    border-radius: 5px;
    text-align: center;
    .career-treker-heading {
      border-radius: 5px 5px 0 0;
      margin: -1px -1px 0 -1px;
    }
    .common-row-sub {
      padding: 0 0 0 7px;
    }
    .card {
      margin: 2px;
      display: flex;
      height: 80px;
      padding: 2px !important;
      font-size: nth($font-small-point, 7);
      img {
        width: 40px;
      }
      .success-tick {
        @include position ($position: absolute, $right: 3px, $top: 3px);
        @include breakpoint(sm) {
          @include position ($position: absolute, $right: 1px, $top: 1px);
          font-size: nth($font-small-point, 5);
        }
      }
    }
    @include breakpoint(sm) {
      padding: 0;
      .career-treker-headding {
        font-size: nth($font-small-point, 4);
        margin: 2px;
      }
      .card {
        p {
          font-size: nth($font-small-point, 4);
        }
      }
    }
  }
  .top-two-cards {
    box-shadow: none;
    border-radius: 5px;
    .desktop-design-show .mat-card .mat-card {
      padding: 10px !important;
      @include breakpoint(sm) {
        padding:0 !important;
      }
    }
    .selected-cards .card {
      margin: 10px auto;
      box-shadow: none;
      @include width-height(150px, 150px);
      @include breakpoint(sm) {
        @include width-height(100%, 100px);
      }
    }
    .selected-cards {
      img {
        width: 80px;
        @include breakpoint(sm) {
          width:60px;
        }
      } 
    }
    .mobile-selected-cards {
      .card {
        margin: 3px ;
      }
      .card-header {
        padding: 5px 0px !important;
      }
      .selected-cards {
        p {
          font-size: nth($font-small-point, 4);
        }
      }
    }
    .selected-box {
      border-radius: 5px;
      box-shadow: none;
      .selected-box-header {
        .career-treker-heading {
          padding: 0;
        }
      }
    }
  }
  @include breakpoint(sm) {
    .next-level {
      top: 14px;
    }
  }
}

/******** **********  Career trekker top skills page End ********* *********/

/******** **********  Career trekker result page Start ********* *********/

.career-trekker-result {
  padding: 10px 14px 30px 6px;
  @include breakpoint(sm) {
    padding: 10px 0px;
    &.content-box {
      margin-top: 0;
    }
    .next-level {
      top: 0;
    }
  }
  .card-list .card {
    position: relative;
    overflow: hidden;
    margin: 2px;
  }
  .opacity-text {
    span {
      font-size: nth($font-small-point, 4);
    }
    .success-tick {
      background-color: nth($white-shades, 1);
      color: nth($green-shades, 1);
    }
    img{
      width: 30px;
    }
  }
  .questions-list {
    .questions-list__block {
      border: 1px solid nth($pink-shades, 3);
      border-radius: 5px;
      margin-bottom: 10px;
      margin-left: 10px;
      .card {
        padding: 5px;
        margin: 5px;
        box-shadow: none;
      }
      .career-treker-heading {
        background-color: nth($pink-shades, 3);
        color: nth($white-shades, 1);
        padding: 10px;
        border-radius: 5px 5px 0 0;
        margin: -1px -1px 0 -1px;
      }
    }
    .challenge-start {
      font-size: $default-font;
      padding: 10px 0 0;
    }
  }
  .challenge-button {
    font-size: nth($font-small-point, 12);
    padding: 5px 20px;
    width: auto;
    border-radius: 4px;
    @include breakpoint(sm) {
      font-size: nth($font-small-point, 3);
      padding: 2px 10px;
    }
  }
  .radio-button-text {
    padding: 5px;
    @include breakpoint(lg-down) {
      padding: 3px;
      font-size: nth($font-small-point, 1);
    }
  }
  /*** mobile part start ***/
  .mobile-part-ct {
    .mat-expansion-panel-body {
      padding: 5px !important;
    }
    p {
      margin: 5px;
    }
    .mat-horizontal-content-container {
      padding: 0;
      background: nth($grey-shades, 7);
    }
    .filter-option {
      margin-top: 10px;
      .audio-box {
        line-height: 30px !important;
        margin: 5px;
      }
    }
    @at-root {
      .filter-list {
        height: calc(59vh - 40px) !important;
        background: nth($white-shades, 1);
        border-radius: 4px;
        margin: 5px 0;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 0 5px;
        .mat-list-item {
          height: 30px !important;
          color: nth($blue-shades, 6) !important;
          border-bottom: 1px solid #c6edff;
          font-size: nth($font-small-point, 4) !important;
        }
        .mat-list-item:nth-last-child(1) {
          border-bottom: none;
        }
      }
    } 
    
    /* Here, styles are added for challenge button section height overrided by card-body for mobile view */
    .card-body{
      min-height: 65px !important;
      height: 65px !important;
    }
  }
  /*** mobile part end ***/
}

/******** **********  Career trekker result page End ********* *********/

/******** **********  Career trekker Challenge page Start ********* *********/

.Career-trek-challenge {
  padding: 5px 20px;
  .number-score,
  .progress-bar-title {
    color: nth($pink-shades, 3);
  }
  @include breakpoint(sm) {
    padding: 15px 0px;
    &.content-box {
      margin-top: 20px;
    }
    p {
      font-size: nth($font-small-point, 4);
      display: table;
      margin: auto;
    }
  }
  .image-text {
    img {
      width: 70px;
    }
    @include breakpoint(lg-down) {
      img {
        width: 50px;
      }
    }
    @include breakpoint(sm) {
      padding-right: 5px;
      img {
        width: 40px;
      }
    }
  }
  .career-treker-heading {
    margin: 0px;
    font-size: nth($font-medium-point, 2);
    color: nth($black-shades, 5);
    padding: 0 20px 0 10px;
    @include breakpoint ((sm, lg)) {
      font-size: nth($font-small-point, 12);
    }
    @include breakpoint(sm) {
      font-size: $default-font;
      padding: 0 10px 0 5px;
    }
  }
  .inform-text {
    margin: 15px 0; 
    p {
      width: 38%;
      margin: 0px 10px;
      @include breakpoint ((sm, lg)) {
        width: 66%;
      }
    }
    @include breakpoint(sm) {
      .audio-box {
        margin: 0 10px;
      }
      p {
        width: 100%;
      }
    }
  }
  .multi-options {
    @include breakpoint(lg-up) {
      padding: 0 60px;
    }
    .options-cards {
      width: 450px;
      min-height: 30px;
      border: none;
      box-shadow: none;
      background-color: nth($grey-shades, 2);
      border-radius: 5px;
      padding: 0;
      margin: 10px auto;
      color: nth($black-shades, 5);
      &:focus{
        outline: 1px auto nth($blue-shades, 10);
      }
      p {
        padding: 4px;
      }
      @include breakpoint(sm) {
        min-width: 100%;
        width: 50%;
        margin: 5px auto;
        background-color: nth($white-shades, 1);
      }
      &.red {
        background-color: nth($red-shades, 4);
        color: nth($white-shades, 1);
        .options {
          background: nth($red-shades, 4);
        }
      }
      &.green {
        background-color: nth($green-shades, 5);
        color: nth($white-shades, 1);
        .options {
          background: nth($green-shades, 5);
        }
      }
      .options {
        float: left;
        padding: 10px 20px;
        background: nth($dark-grey, 8);
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        @include breakpoint(sm) {
          padding: 8px 15px;
          background: nth($white-shades, 1);
        }
      }
      .question {
        i {
          float: right;
          margin-right:10px;
        }
      }
    }
  }
  .score-data { 
    li,
    p {
      color: nth($pink-shades, 3);
    }
    .score {
      background-color: nth($pink-shades, 3);
    }
    .mat-progress-bar-buffer {
      background-color: nth($grey-shades, 6);
    }
  }
  .audio-box {
    &.mat-raised-button {
      border-radius: 100%;
    }
  }
}

@include breakpoint(lg-down){
  .occ-explorer-card .content-box {
       background-color: transparent; 
       box-shadow: 0 0 20px 4px transparent; 
       margin-top: 20px;
  }
}
.ct-h-34{
  height: 34px;
}
/******** **********  Career trekker Challenge page End ********* *********/