.cdk-overlay-dark-backdrop+.cdk-global-overlay-wrapper .cdk-overlay-pane {
  max-width: 100% !important;
  width: 550px !important;
  top: 50%;
  transform: translateY(-50%);
  position: fixed !important;
  @include breakpoint(sm) {
    width: 300px !important;
  }
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1 !important;
}
.cdk-overlay-backdrop{
  position: fixed!important;
}
.cdk-overlay-container{
  position: absolute!important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.8) !important;
}

.mat-dialog-container {
  padding: 0px !important;
  color: nth($black-shades, 5);
  overflow-y: hidden !important;
}
.body-scroll{
    overflow-y: hidden;
}

/**** ****** Congratulations Popup start ****** ****/
.legend-text{
p{
  margin: 2px 0px;
  font-size: nth($font-small-point, 1);
}
img{
  width: 50px;
}
}
.congratulations-popup {
  img {
    width: 120px;
    margin: 10px 0px;
  }
  p {
    padding: 5px;
    line-height: 20px;
    font-size: nth($font-small-point, 4);
  }
}

.popup-footer {
  p {
    font-size: nth($font-medium-point, 2);
    color: nth($green-shades, 1);
    @include breakpoint(sm) {
      font-size:nth($font-small-point, 3);
      font-weight: normal;
    }
    .green-text {
      color: nth($green-shades, 1);
    }
  }
}

.popup-buttons {
  .common-buttons {
    @include breakpoint(sm) {
      padding: 0px 3px !important;
      line-height: 30px !important;
      font-size: nth($font-small-point, 1);
    }
    .material-icons {
      font-size: nth($font-small-point, 7) !important;
      margin: 2px;
    }
    &.ct-body-clr{
      background-color:nth($pink-shades,5);
      }
    &.ct-header-clr{
        background-color:nth($pink-shades,3);
      }
  }
  .mat-button-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  //.mat-raised-button is the sass class used for all buttons, we have changed the button color for modal popup so the style is overriding green that's why important is mentioned.
  .green{
  background-color: nth($green-shades,2)!important;
  }
  .blue{
    background-color: nth($blue-shades,14)!important;
  }
  .mat-raised-button {
    color: nth($white-shades, 1);
  }
}

/**** ****** Congratulations Popup End ****** ****/

/**** ******** Explorer Challenge Popup Start ******** ****/

.explorer-challenge-popup {
  padding: 24px;
}

/**** ******** Explorer Challenge Popup End ******** ****/

/********* ***** Common ON INIT Popup Start ********* *****/

.common-popup {
  .common-popup-header {
    h2 {
      background-color: nth($green-shades, 2);
      color: nth($white-shades, 1);
      font-weight: normal;
      font-size: nth($font-small-point, 2);
      position: relative;
      line-height: 57px;
      button {
        position: absolute;// These required styles for popup header title center align in header box
        &.volume-up {
          margin: 0;
          left: 4px;
        }
        &.quickpic-vol-btn,
        &.ct-vol-btn {
          right: 4px;
          left: inherit;
          top: 4px;
          display: block !important;
          height: 45px;
          min-width: 45px !important; // These required styles for ct-vol-btn class matched to the mat-fab wanted styles
          @include breakpoint(sm) {
            height: 34px;
            min-width: 34px !important;
          }
          .material-icons {
            font-size: nth($font-small-point, 12);
            @include breakpoint(sm) {
              font-size: $default-font;
            }
          }
        }
        &.ct-vol-btn {
          border: none !important; // These required styles for remove the mat-button unwanted border style
        }
      }
      @include breakpoint(sm) {
        font-size: nth($font-small-point, 4);
        height: 45px;

      }
      .close-icon-button {
        color: nth($white-shades, 1);
        min-width: inherit;
        padding: 0;
        box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
        .mat-button-focus-overlay {
          background-color: transparent;
        }
        span {
          font-weight: bold;
        }
      }
      .header-text {
        width: 70%;
        text-align: center;
        @include breakpoint(sm-up) {
          font-size: nth($font-small-point, 3);
        }
      }
    }
  }
  .volume-up {
    border-radius: 50% !important;
    border: none !important;
    display: flex;
    background-color: nth($green-shades, 7);
    .material-icons {
      color: nth($white-shades, 1) !important;
      @include breakpoint(sm) {
        font-size:($default-font);
      }
    }
  }
  .mat-fab {
    @include breakpoint(sm) {
      @include width-height(34px !important, 34px !important);
    }
  }
  .common-popup-body {
    line-height: 20px;
    p {
      padding: 10px;
      font-size: nth($font-small-point, 4);
      @include breakpoint(sm) {
        font-size: nth($font-small-point, 1);
        padding: 5px;
      }
    }
    ul {
      padding: 5px;
      li {
        font-size: nth($font-small-point, 4);
        padding: 5px;
        @include breakpoint(sm) {
          font-size: nth($font-small-point, 1);
        }
        .material-icons {
          padding: 0px 5px;
          font-size: $default-font !important;
          // color: nth($green-shades, 7);
          @include breakpoint(sm) {
            align-items:flex-start !important;
          }
        }
      }
    }
    .common-popup-list {
      .volume-up {
        min-width: 30px !important;
        height: 30px;
      }
      &.custom-scroll {
        margin-right: 5px;
      }
    }
    .icon-align {
      min-width: 40px;
    }
    .text-spacing {
      margin: auto 10px;
    }
  }
  .start {
    font-size: nth($default-font, 1);
    background-color: nth($green-shades, 7);
    margin: 10px;
    color: nth($white-shades, 1) !important;
    @include breakpoint(sm) {
      font-size: nth($font-small-point, 4) !important;
      line-height: 25px !important;
      min-width: 60px !important;
      width: 100%;
      box-shadow: 0 0px 3px 1px rgba(0, 0, 0, .3) !important;
    }
  }
  .navigation-challenge-footer{
    margin: 15px 0px;
  }
}
.undefined {
  .common-popup-header h2 {
    button {
      &.ct-vol-btn {
        background-color: nth($green-shades, 7);
      }
    }
  }
}
/********* ***** Common ON INIT Popup End********* *****/

/********* ***** Path Finder Congratulations Popup Start ********* *****/

.pathfinder-congratulation-popup {
  padding: 24px;
  .congratulations-popup {
    p {
      font-size: nth($font-small-point, 2);
      padding: 5px;
      line-height:1.3;
      @include breakpoint(sm) {
        font-size: nth($default-font, 1);
      }
    }
  }
}

/********* ***** Path Finder Congratulations Popup End ********* *****/

/******** ********** Career trekker congratulations Popup Start ********* *********/

.careertrekker-congratulations-popup {
  padding: 24px;
  img {
    margin: 15px 0px;
  }
  p {
    font-size: nth($default-font, 1);
    padding: 5px;
  }
  .popup-footer {
    p {
      font-weight: 900;
    }
    ;
  }
}
.pathfinder-congratulation-popup {
  .path-blue {
    background-color: nth($blue-shades, 14);
  }
}
/******** ********** Career trekker congratulations Popup End ********* *********/

/**** ****** Occupation Explorer Popup Start ****** ****/

.explorer-occ-popup {
  padding: 5px;
  overflow-x: hidden;
  @include breakpoint(sm) {
    padding: 0px;
  }
  &.profile-page-popup {
    padding: 0px !important;
    .common-occupation-popup{
      margin-bottom: 0px;
      .card-explorer {
        border: 1px solid nth($yellow-shades, 3);
        border-radius: 4px;
      }
      .card-pathfinder {
        border: 1px solid nth($blue-shades, 3);
        border-radius: 4px;
      }
      .card-trekk {
        border: 1px solid nth($pink-shades, 3);
        border-radius: 4px;
      }
      .occ-content-block {
        .career-content {
          padding: 10px 10px 0;
          h4 {
            font-size: nth($font-small-point, 3);
          }
          p {
            color: nth($black-shades, 5);
            font-size: nth($font-small-point, 1);
          }
        }
      }
    }
    .occ-popup-body {
      padding: 10px 5px;
      // important is used as it is overridden by align-items:center
      align-items: normal!important;
      @include breakpoint(sm) {
        padding: 3px 0px;
      }
    }
    .popup-footer-height{
      height: 50px;
      color: nth($black-shades, 1);
      i{
        margin-left: 7px;
      }
      .career-badge img {
        width: 20px;
        margin-left: 5px;
      }
    }
    .common-popup-header {
      h2 {
        background-color: nth($green-shades, 2);
        font-size: nth($font-small-point, 2);
        height:52px;
        position: relative;
        .volume-up {
          background-color: nth($green-shades, 7);
          position: absolute; // These required styles for popup header title center align in header box
          left: 5px;
        }
        @include breakpoint(sm) {
          font-size: nth($font-small-point, 4);
          height:38px;
        }
        // The class is created for designing "delete icon"  - custom carrer popup window
        .profilecarrer-delete{
          background-color: transparent;
          box-shadow: none;
          border:none;
          cursor: pointer;
          position: absolute;
          right: 0;
          img{
            width: 30px;
            @include breakpoint(sm) {
              width: 25px;
            }
          }
        }
        .share-btn {
          display: flex;
          align-items: center;
          background-color: nth($powder-blue, 11);
          border: 2px solid nth($white-shades, 1);
          color: nth($white-shades, 1);
          font-size: $default-font;
          margin-right: 5px;
          padding: 5px 10px;
          border-radius: 3px;
          cursor: pointer;
          position: absolute;
          right: 40px;
          box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 
                      0 6px 10px 0 rgba(0,0,0,.14), 
                      0 1px 18px 0 rgba(133, 77, 77, 0.12);
          img {
            width: 16px;
            margin-left: 10px;
          }
          @include breakpoint(sm) {
            width: 30px;
            height: 30px;
            border-radius: 50px;
            font-size: 0;
            padding: 0;
            justify-content: center;
            img {
              margin-left: 0;
            }
          }
        }
      }
    }
    .common-occ-popup-image {
      &.occ-content-image {
          flex-direction: column;
          text-align: center;
          .spriteImage {
            width: 70%;
            margin: 30px auto 10px;
          }
      }
      .spriteImage {
        margin-left: 10px;
        border: 1px solid nth($green-shades,2);
        border-radius: 4px;
        background-color: nth($white-shades, 1);
        position: relative;
        width: 50%;
        .sprite-badge {
          width: 20px;
          height: 20px;
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
      }
    }
    .common-occ-actor-text {
      margin: 10px 0 0 10px;
      border-left: none;
      p,
      i {
        color: nth($black-shades, 5);
        @include breakpoint(sm) {
          font-size: nth($font-small-point, 1);
        }
      }
      .audio-box i {
        color: nth($white-shades, 1);
      }
      ul li {
        @include breakpoint(sm) {
          font-size: nth($font-small-point, 6);
        }
      }
    }
    .tabs-card {
      //margin: 5px 0px;
      // min-height: 82%;
      // &.circle-icon-box {
      //   height: 82%;
      // }
      .mat-tab-label {
        flex: 1;
        height: 35px !important;
        min-width: inherit;
      }
      .mat-tab-body {
        .mat-tab-body-active {
          overflow-x: auto !important;
        }
        .icon-hover-styles {
          flex-wrap: wrap;
          &.hightab-class {
            padding-left: 0;
          }
          &.lowtab-class {
            padding-right: 0;
          }
        }
      }
      .tab-button {
        min-height: 25px;
        font-size: nth($font-small-point, 5);
      }
      @include breakpoint(sm) {
        padding: 0px !important;
        height: auto;
      }
      &.mat-card:not([class*=mat-elevation-z]) {
        box-shadow: none;
      }
    }
    .circle-img {
      @include width-height(35px !important, 35px !important);
      img {
        width: 25px;
        height: 25px;
      }
    }
    .mat-tab-header-pagination {
      display: none !important;
    }
    .career-group,
    .characteristics-block,
    .personality-types,
    .skill-block,
    .thought-box {
      min-width: 95%;
      @include breakpoint(sm-up) { 
        min-width: 48%;
      }
    }
    .personality-types {
      .personality-card {
        padding: 0px;
        height: 92px;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
        .legend-text {
          width: 50%;
        }
      }
      .tabs-card {
        padding: 0px !important;
      }
    }
    .career-group-card {
      height: 92px;
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
      border: 1px solid nth($green-shades, 11);
      border-radius: 4px;
      .career-icon_text,
      .career-icon {
        width: 50%;
      }
      .career-icon img {
        width: 80px;
      }
    }
    .work-value-card {
      height: 92px;
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
      border: 1px solid nth($red-shades, 9);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      .card-body {
        width: 50%;
      }
      .career-icon img {
        width: 40px;
        height: 40px;
      }
      p {
        font-size: nth($font-small-point, 1);
        padding-top: 5px;
      }
    }
    .thought-box {

      .thought-box-card {
        height: 137px;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        resize: none;
        font-weight: 600;
        color: nth($black-shades, 4);
        padding: 10px;
        font-size: nth($font-small-point, 4);
        &:focus {
          outline: none;
        }
      }
    }
  }
  .common-occupation-popup {
    padding: 0px;
    // overflow:scroll;
    
  }
  .common-occ-popup-card {
    padding-bottom: 5px;
    @include breakpoint(sm) { 
      //overrides by class .occ-popup-body with display:flex
      display: block !important;
    } 
    .card-body {
      min-height: 50px !important;
      img {
        padding: 0px 20px;
        width: 80px;
        height: 50px;
      }
    }
    @include breakpoint(sm) {
      .tabs-card {
        width: 276px;
        margin: auto;
      }
      p { 
        padding: 3px 7px;
        font-size: nth($font-small-point, 1);
      }
    }
      @include breakpoint(sm){
      .card-header{
      display: none;
      }
    }
  }
  .tabhHalfWidth{
    .tabs-card {
      .mat-tab-label {
     width: 50%;
      }
    }
  }
  .tabFullwidth{
    .tabs-card {
      .mat-tab-label {
     width: 100%;
      }
    }
  }
  .tabs-card {
    padding: 5px !important;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
    .mat-tab-label {
      opacity: 1 !important;
      height: 40px !important;
      padding: 0 !important;
      background: none !important;
    }
    .mat-tab-body-content {
      overflow-x: auto !important;
      overflow-y: hidden !important;
    }
  }
  .tab-button {
    color: nth($white-shades, 1);
    border-radius: 6px;
    padding: 2px 0px;
    min-height: 30px;
    height: 30px;
    width: 95%;
    border: none;
    box-shadow: none;
    &.green {
      background-color: nth($green-shades, 7);
    }
    &.red {
      background-color: nth($orange-shades, 2);
    }
    &.purple {
      background-color: nth($purple-shades, 1);
    }
  }
  .mat-tab-labels {
    display: flex !important;
    justify-content: center !important;
  }
  .mat-tab-label-active .green::before {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid nth($green-shades, 7);
    content: " ";
    position: absolute;
    bottom: 0px;
  }
  .mat-ink-bar {
    visibility: hidden !important;
  }
  .circle-img {
    @include custom-circle ($border-color: nth($white-shades, 1), $width: 35px, $height: 35px, $border-radius: 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 2px;
    img {
      width: 25px;
      height: 25px;
    }
  }
  .mat-tab-label-active .red::before {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid nth($orange-shades, 2);
    content: " ";
    position: absolute;
    bottom: 0px;
  }
  .mat-tab-label-active .purple::before {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid nth($purple-shades, 1);
    content: " ";
    position: absolute;
    bottom: 0px;
  }
  .common-occ-text {
    p {
      font-size: nth($font-small-point, 1);
      margin: 5px;
      &.custom-occ-text{
        @include breakpoint(sm) {
          margin-top: 0px;
          padding-top: 10px;
        }
      }
    }
  }
  .common-occ-popup-footer {
    height: 40px;
    .mobile-text-center {
      @include breakpoint(sm) {
        margin: 0px 10px;
      }
    }
    img {
      margin: 5px;
      width: 30px;
    }
  }
  .header-text {
    color: nth($white-shades, 1);
    text-align: center;
    width: 70%;
    @include breakpoint(sm) {
      width: 75%;
    }
  }
  .close {
    color: nth($white-shades, 1) !important;
    background-color: nth($powder-blue, 3);
  }
  // pathfinder personality type card styles.
  .custom-personality-type-card {
    width: 100%;
    .scout-img{
      width: 80px;
      margin-right: 10px;
    }
    .navigator-img{
      width: 50px;
      height: 50px;
      margin-left: 10px;
    }
    .icon-hovertitle.hover-title {
      margin: 0px;
      img {
        width: 50px;
        padding: 0px;
      }
    }
    &.mobile-design-show {
      .card-header{
        p {
          margin: 0px;
        }
      }
      .mobile-popup-image{
        display: flex;
      }
    }
  }
}

.occ-popup-body {
  display: flex;
  padding: 5px;
}
/**** ****** Occupation Explorer Popup End ****** ****/

/**** ****** Pathfinder Occupation Popup Start ****** ****/

.common-occ-popup {
  padding: 0px 5px;
  @include breakpoint(sm) {
    padding: 0px !important;
  }
  .card {
    margin: 5px !important;
  }
  .card-body img {
    padding: 0px !important;
    width: 50px;
    margin: 3px;
  }
  .common-occ-text {
    .common-text {
      font-size: nth($font-small-point, 1);
      margin: 10px 15px;
    }
    p {
      font-size: nth($font-small-point, 1);
    }
  }
  .occ-content-block {
    padding: 5px 0 0 5px;
  }
  .common-occ-popup-image {
    position: relative;
    .opacity-text p {
      font-size: $default-font;
    }
  }
  h2 {
    .header-text {
      color: nth($white-shades, 1);
      font-weight: normal;
      font-size: nth($font-small-point, 4);
    }
    .volume-up {
      width: 30px !important;
      height: 30px !important;
    }
  }
  .mobile-popup-image {
    img {
      width: 50px;
      margin: 5px !important;
    }
  }
  .common-occ-popup-footer img{
    margin: 5px;
    width: 30px;
  }
}

/**** ****** Pathfinder Occupation Popup End ****** ****/

/**** ****** Common Occupation Popup start ****** ****/

.common-occupation-popup {
  background-color: nth($grey-shades, 2);
  border-radius: 5px;
  padding: 5px 0;
  @include breakpoint(sm) {
    padding: 0px !important;
    .common-text.custom-occ-text {
      margin-top: 0px;
      padding-top: 10px;
    }
    .common-occ-actor-text.custom-actor-text{
      margin-bottom: 0px;
      padding-bottom: 15px;
    }
  }
  .common-occ-popup-body {
    align-items: center !important;
  }
  .common-occ-popup-image{
    overflow: hidden;
    position: relative;
    .opacity-text {
      p {
        height: 50px;
        &::before {
          height: 50px;
        }
      } 
      img {
        width: 30px;
        left: 10px;
        position: absolute;
        bottom: 10px;
      }
    }
  }
  .common-occ-text {
    h1 {
      color: nth($black-shades, 1);
      font-size: $default-font;
    }
  }
  .common-occ-actor-text {
    margin: 0 5px 10px;
    border-left: 1px solid nth($grey-shades, 4);
    word-break:break-word;  
    @include breakpoint(sm) {
      border-left:none;
    }
    p {
      font-weight: bold;
      padding: 0px 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoint(sm) {
        padding: 10px 5px 5px;
      }
      .audio-box {
        min-width: 25px !important;
        height: 25px;
        margin-left: 5px;
        .material-icons {
          font-size: $default-font;
        }
      }
    }
    ul {
      padding: 0px 5px;
      font-size: nth($font-small-point, 1);
      color: nth($black-shades, 2);
      @include breakpoint(sm) {
        padding: 0px !important;
      }
      i {
        font-size: nth($default-font, 1);
        color: nth($blue-shades, 3);
        margin-right: 3px;
      }
    }
  }
  .card {
    padding: 0px;
    .card-body {
      min-height: 80px;
    }
    .card-header {
      font-size: nth($font-small-point, 1);
      padding: 5px 30px;
      &.green {
        background-color: nth($green-shades, 7);
      }
      &.orange {
        background-color: nth($orange-shades, 2);
      }
      &.purple {
        background-color: nth($purple-shades, 1);
      }
    }
  }
  .common-occ-popup-footer {
    .mat-button {
      padding: 0px 5px;
      margin: 5px 2px;
      min-width: 0;
    }
    p {
      font-weight: bold;
      font-size: nth($font-small-point, 4);
    }
  }
}
// .pathfinder-theme {
//   .card {
//     &.mat-card:not([class*=mat-elevation-z]) {
//       box-shadow: none;
//     }
//     border: 1px solid nth($blue-shades, 3);
//   }
// }
.close {
  padding: 0px !important;
  margin: 5px 0px !important;
  line-height: 28px !important;
  color: nth($white-shades, 1) !important;
}

/**** ****** Common Occupation Popup End ****** ****/

/**** ******* Session Expired Popup Start ****** ****/

.session-expired {
  h2 {
    background-color: nth($powder-blue, 4);
    color: nth($white-shades, 1);
    font-weight: normal !important;
    padding: 10px;
  }
  .session-body {
    img {
      width: 80px;
      margin: 15px auto;
    }
    P {
      font-size: $default-font;
      margin: 5px;
      ;
    }
  }
  .session-footer button {
    background-color: nth($powder-blue, 5);
    color: nth($white-shades, 1);
    margin: 20px 10px;
  }
}

/**** ******* Session Expired Popup end ****** ****/

/**********   careerTrekker filter popup start   ********/

.careertrekker-filter-popup {
  .filter-head {
    p {
      margin: 5px;
    }
  }
  .careerTrekker-filter {
    padding: 0px 5px;
    .mat-expansion-panel-header {
      background-color: nth($blue-shades, 6) !important;
      height: 40px !important;
      .mat-expansion-panel-header-title,
      .mat-expansion-indicator::after {
        color: nth($white-shades, 1) !important;
      }
    }
    .mat-expansion-panel-body {
      padding: 5px;
    }
    .mat-expansion-panel-spacing {
      margin: 0 !important;
    }
    .mat-expansion-panel:nth-(1) .mat-expansion-panel-header,
    .mat-expansion-panel {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .mat-expansion-panel:nth-last-child(1) .mat-expansion-panel-header,
    .mat-expansion-panel {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      &.mat-expanded {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
    .hide-on-desktop {
      @include breakpoint(sm) {
        display:none !important;
      }
    }
  }
  .start {
    margin: 5px;
  }
}

/**********   careerTrekker filter popup start   ********/

/**********   explorer occupation filter popup start   ********/

.occupation-explorer {
  @include breakpoint(lg-down) {
    padding: 5px;
    .tab-button {
      color: nth($white-shades, 1);
    }
  }
  @include breakpoint((sm, lg)) {
    .mat-button-wrapper i {
        line-height: 42px;
    }
  }
}

/********** explorer occupation filter popup start   ********/

/********** Quickpic Info Popup ********/

.quickpic-info-popup {
  p {
    font-size: $default-font !important;
    margin: 5px 10px;
  }
  .common-popup-body {
    .smile-icons li {
      h4 {
        font-size: nth($font-small-point, 4);
      }
      i {
        font-size: nth($font-large-points, 2) !important;
      }
    }
  }
}

/********** Quickpic Delete Popup ********/

.quickpic-delete-popup {
  .common-popup-body {
    padding: 16px 0;
    p {
      padding-bottom: 10px;
      margin: 0 5px;
      font-size: nth($font-small-point, 4);
    }
    span {
      height: 18px;
      line-height: 20px;
      @include breakpoint(sm){
        font-size: nth($font-small-point, 1);
      }
    }
  }
  .popup-body{
    padding: 16px 0;
    p {
      padding: 0px;
      margin: 15px 10px;
      // font-size: nth($font-small-point, 4);
    }
    span {
      margin: 10px;
      @include breakpoint(sm){
        font-size: nth($font-small-point, 1);
      }
    }
  }
  // popup for delete 
  .popup-body{
    padding: 16px 0;
    p {
      padding: 0px;
      margin: 15px 10px;
      // font-size: nth($font-small-point, 4);
    }
    span {
      margin: 10px;
      @include breakpoint(sm){
        font-size: nth($font-small-point, 1);
      }
    }
  }
  .common-popup-header{
    .powder-blue{
      background-color: nth($powder-blue, 4)!important;
      // important is mentiones as the background colour is reflecting from common pop up header
    }
    span{
      padding:6px 0;
    }
  }
  .quickpic-result-text{
    text-align: justify;
  }
  .delete-popup-buttons{
    margin: 10px 10px;
    // background-color: nth($powder-blue, 5);
    // color: nth($white-shades, 1);
    button.mat-button { 
      color: nth($white-shades, 1);
      background-color: nth($teal-shades, 1);
    }
    &.powder-blue{
      background-color: nth($powder-blue, 5);
      color: nth($white-shades, 1);
    }
  }
}

/********** Quickpic Result Popup ********/

.quickpic-result-popup {
  .common-popup-header .header-text {
    margin: 0px 10px;
  }
  .common-popup-body {
    ul li {
      padding: 4px !important;
      .material-icons {
        padding: 0px 3px !important;
      }
    }
    p {
      font-size: $default-font !important;
      padding: 5px 8px;
      @include breakpoint(sm) {
        font-size: nth($font-small-point, 4) !important;
        padding: 0px 10px;
      }
    }
    .common-popup-body-image {
      height: 200px;
      overflow: hidden;
      margin: 8px 10px;
      border-radius: 5px;
      border: 2px solid white;
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
      @include breakpoint(sm) {
        height: 135px;
      }
    }
  }
}

/**** Landing Create Own occupation popup *****/

.landing-create-occ-popup {
  .save-occ-cards-row {
    @include breakpoint(sm) {
      flex-wrap: nowrap;
      display: block !important;
    }
  }
  .three-dots-btn {
    line-height: 35px;
    min-width: 20px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &.mat-raised-button {
      box-shadow: none !important;
      color: nth($white-shades, 1) !important;
    }
  }
  .delete-option {
    position: absolute;
    top: 40px;
    right: 2px;
  }
  .delete-option-button {
    position: relative;
    background-color: nth($red-shades, 4) !important;
    padding: 0;
    min-width: 70px;
    line-height: 1;
    border-radius: 5px;
    height: 25px;
    color: nth($white-shades, 1) !important;
    i {
      font-size: $default-font;
      color: nth($white-shades, 1) !important;
    }
  }
  .delete-option-button:after {
    bottom: 100%;
    right: 3px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .delete-option-button:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: nth($red-shades, 4);
    border-width: 5px;
  }
  .own-occupations {
    padding: 10px;
    height: 350px;
    overflow-y: scroll;
    .own-occ-cards {
      min-height: 135px;
      height: 135px;
      margin: 5px 0px;
      border-radius: 4px;
      &.add-more-card {
        display: flex;
        i {
          color: nth($white-shades, 1);
          font-size: nth($font-large-points, 3);
        }
      }
      @include breakpoint(sm){
        height: auto;
        // rrr
      }
      .card-header {
        padding: 2px 0px !important;
        height: 35px;
        flex-wrap: wrap;
        line-height: 15px !important;
        span {
          padding-top: 3px;
          font-size: nth($font-small-point, 1);
          text-align: left;
          margin-left: 5px;
          word-wrap: break-word;
          word-break: break-all;
        }
      }
      .card-body {
        height: 100px;
        background-color: nth($white-shades, 1);
        img {
          width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}

/***** Profile page avatar popup *****/

.avatar-popup {
  .avatar-popup-header {
    h2 {
      background-color: nth($white-shades, 6);
      color: nth($black-shades, 10);
      span {
        font-size: nth($font-small-point, 3);
        margin: 0px 9px;
        @include breakpoint(sm) {
          font-size: nth($font-small-point, 4);
        }
        .material-icons{
          margin-top: 2px;
        }
      }
      .volume-up {
        background-color: rgba(0, 0, 0, .1) !important;
        i {
          color: nth($black-shades, 10);
        }
        @include width-height(40px !important, 40px !important);
      }
    }
  }
  .avatar-popup-body {
    padding: 20px 10px;
    @include breakpoint(sm) {
      padding:10px 5px;
    }
    .user-select-list {
      .image-list {
        .profile-circle-image{
          @include custom-circle ($border-color: nth($white-shades, 1), $width:90px, $height: 90px, $border-radius:50%);
          margin: 10px auto;
          overflow: hidden;
          box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
          @include breakpoint(sm) {
            @include custom-circle ($width:70px, $height: 70px);
          }
        }
        .circle-image {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid nth($grey-shades, 1);
          margin: 10px 4px;
          overflow: hidden;
          // box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
          @include breakpoint(sm) {
            @include custom-circle ($width:30px, $height: 30px);
          }
          &.active {
            border: 2px solid nth($green-shades, 7) !important;
            box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
          }
          i {
            color: nth($grey-shades, 4);
            font-size: nth($font-large-points, 3);
            @include breakpoint(sm) {
              font-size: nth($font-large-points, 2);
            }
          }
        }
      }
      h5{
        font-size:nth($font-small-point, 4);
      }
      .save-button{
        @include breakpoint(sm) {
          margin-top: -10px;
        }
      }
    }
    .save-button{
      background-color:nth($green-shades, 1);
      color: nth($white-shades,1);
      line-height: 30px;
      min-width: 66px;
    }
    .color-sec{
      border-bottom: 1px dashed nth($grey-shades, 1);
    }
    .eyes-sec{
      border-bottom: 1px dashed nth($grey-shades, 1);
      padding-top: 6px;
    }
    p{
      font-size:  nth($font-small-point, 4);
      font-weight: bold;
    }
  }
  .refresh-button {
    @include width-height(50px !important, 50px !important);
    background-color: nth($dark-grey, 4) !important;
    color: nth($white-shades, 1);
    display: flex;
    margin: 10px;
    .mat-button-wrapper {
      line-height: 0;
    }
  }
}

/***** Scout Activity Yes/No popup *****/
.scout-activity-popup {
  height: 300px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  .common-popup-header {
    position: absolute;
    left: 5px;
    top: 5px;
  }
  .try-again-image {
    width: 150px;
  }
  &.yes-popup {
    background-image: url('/assets/Svg/chrips_transparent.svg');
    .common-popup-body img {
      width: 50px;
      padding-bottom: 20px;
    }
  }
}
/***** Navigator sucess popup *****/
.navigator-success-popup {
  height: 350px;
  align-items: center;
  display: flex;
  justify-content: center;
  .popup-buttons {
    margin: 10px 0 0;
    .common-buttons {
      color: nth($white-shades, 1);
    }
    .path-blue {
      background-color: nth($blue-shades, 14);
    }
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }0% {
    transform: rotate(360deg);
  }
 }
 .audio-loading {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: .25rem solid rgba(rgb(255,255,255), 0.2);
  border-top-color:  rgb(255,255,255);
  animation: spin 1s infinite linear;
  display: inline-block;
 }


/***** Icon hover title name *****/

.icon-hovertitle {
  position: relative;
  margin: 0 5em;
  display: flex;
  align-items: center;
  height: 100%;

  
    span {
      position: absolute;
      top: 60%;
      margin-top: 8px;
      padding: 5px;
      font-size: nth($font-small-point, 5);
      font-weight: 500;
      white-space: nowrap;
      color: nth($black-shades, 1);
      border-radius: 50px;  
      background-color: nth($white-shades, 1);
      box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 
                  0 6px 10px 0 rgba(0,0,0,.14), 
                  0 1px 18px 0 rgba(133, 77, 77, 0.12);

      pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      text-transform: capitalize;
    }
    &:hover {
      span {
        top: 100%;
        opacity: 0.9;
        @include z-index(modal);
      }
    }
  }
 .icon-hover-styles .icon-hovertitle span {
    margin-top: 0px;
    box-shadow: none;
    text-transform: capitalize;
  }
  .icon-hover-styles .icon-hovertitle:hover span {
    top: 95%;
}
  @media (max-width: 599px) {
    .icon-hovertitle span {
      font-size: 10px;
      background-color: nth($white-shades, 1);
      margin-top: 4px;
      box-shadow: none;
    }
    .icon-hovertitle:hover span {
      top: 80%;
      opacity: 0.9;
  }
  }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .circle-img-iestyles {
    min-width:40px ;
  }
 }
.circle-icon-block {
  &:last-child {
    .circle-img .icon-hovertitle span {
      right: 0;
    }
  }
}
.image-selected{
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  // border: 1px solid nth($white-shades, 1);
}
.justify-center{
  justify-content: center;
}
.lowtab-class{
  padding-right: 15px;
  justify-content: flex-end;
}
.mouth-sec{
padding-top: 6px;
}
.pf-blue-brdr{
  border: 1px solid nth($blue-shades, 14);
}
.scout-brdr{
  border: 1px solid nth($green-shades, 11);
}

.navigator-brdr{
  border: 1px solid nth($red-shades, 8);
}

/** Vertical scroll bar**/
.custom-scroll{
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
      width: 5px;
      height: 15px;
      background: url('/assets/images/line.png') repeat;
      background-size: 100% 100%;
  }
  &::-webkit-scrollbar-thumb {
      background-color: nth($black-shades, 9);
      border-radius: 50px;
  }
  &.library-career_popup {
    height: 200px;
     @include breakpoint(sm) {
          height: auto;
      }
  }
  &.profile-popup{
    @include breakpoint(sm-up){
      height: 150px;
    }
  }
}
// Career Library video 
.videobox{
  width: 100%;
  height: 180px;
}
.closevideo{
  float:right;
  background-color: transparent;
  color: black;
  font-size: 22px;
  border: none;
  box-shadow: none;
  cursor: pointer;

}