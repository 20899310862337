.rotate-animation {
  animation: rotation 6s infinite linear;
}

@keyframes rotation {
  0%    {transform: rotateY(0deg); }
  50%   {transform: rotateY(180deg); }
  100%  {transform: rotateY(360deg); }
}
.shake-animation {
    animation: shake-animation 5s ease infinite;
  }

  @keyframes shake-animation {
    0% { transform:translate(0,0) }
    1.78571% { transform:translate(5px,0) }
    3.57143% { transform:translate(0,0) }
    5.35714% { transform:translate(5px,0) }
    7.14286% { transform:translate(0,0) }
    8.92857% { transform:translate(5px,0) }
    10.71429% { transform:translate(0,0) }
    100% { transform:translate(0,0) }
 }