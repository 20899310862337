.d-table {
  display: table;
  width: 100%;
}

.d-table-cell {
  display: table-cell;
  vertical-align: middle;
  margin: auto;
}
[d-none]{
  display: none;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50px;
} 
.h-100 {
  height: 100%;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
.box-shadow-none {
  // To override the default box-shadow.
  box-shadow: none !important;
}
.box-shadow {
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2),
              0 6px 10px 0 rgba(0,0,0,0.14),
              0 1px 18px 0 rgba(0,0,0,0.12);
}

[pointer] {
  cursor: pointer;
}

.list-style-none {
  list-style: none;
}

.m-auto {
  margin: auto;
}

[h-auto] {
  height: auto;
}

[font-normal] {
  font-weight: normal;
}

[font-bold] {
  font-weight: 600;
}

.d-flex {
  display: flex !important;
}
.flex-center {
  display: flex;
  align-items: center;
}
[align-self-center]{
  align-self: center;
}
[flex-center] {
  display: flex;
  align-items: center;
}

[flex] {
  display: flex;
}

[v-end] {
  align-items: flex-end;
}

[v-center] {
  align-items: center;
}

[v-top] {
  align-items: start;
}

[h-center] {
  justify-content: center;
}

[justifi-center] {
  justify-content: center;
}

[p-0] {
  padding: 0;
}
[pt-12]{
  padding-top: 12px;
}

[m-0] {
  margin: 0;
}
[mb-0] {
  margin-bottom: 0px;
}
[mb-1]{
  margin-bottom: 5px;
}
[mb-10]{
  margin-bottom: 10px;
}
[ml-10]{
  margin-left: 10px;
}
[disable] {
  opacity: 0.3;
  cursor: not-allowed;
}
[dark-bg] {
  background-color: nth($black-shades, 3);
  color: nth($white-shades, 1);
}

[light-bg] {
  background-color: nth($grey-shades, 2);
  color: nth($black-shades, 5);
}

[float-right] {
  float: right;
}

[float-left] {
  float: left;
}

[fxLayoutWrap='wrap'] {
  flex-wrap: wrap;
}

[p-5] {
  padding: 5px;
}

[mt-5] {
  // to override the material default margin-top.
  margin-top: 5px !important;
}
[mx-1]{
  margin-left:5px;
  margin-right:5px;
}
[my-1]{
  margin-top:5px;
  margin-bottom:5px;
}
[mt-15]{
  margin-top: 15px;
}
[mr-4]{
  margin-right:4px;
}
[mr-10]{
  margin-right: 10px;
}
[pt-2] {
  padding-top: 2rem;
}
[pt-3] {
  padding-top: 3rem;
}
[pl-2]{
  padding-left: 15px;
}

[flex-basis]{
  flex-basis: auto !important;
}

[flex-grow]{
  flex-grow: 4 !important;
}

[flex-grow-one]{
  flex-grow: 1;
}
[flex-grow-inherit]{
  flex-grow: inherit !important;
  // important is mentioned as it is overiding styles from flex-grow: 1;
}
[flex-shrink-zero]{
  flex-shrink: 0 !important;
}
[d-block-mobile]{
  @include breakpoint(sm) {
    display: block !important;
  }
}

[d-block],
.d-block {
  display: block !important;
}
[d-flex-mobile]{
  @include breakpoint(sm) {
    display:flex !important;
  }
}

[justify-end]{
  justify-content: flex-end;
}

[justify-between]{
  justify-content: space-between;
}

[flex-column]{
  flex-direction: column;
}
[pointer-event-none]{
  pointer-events: none;
}