/**** Profil Desktop *****/ 

// profile-page scss
.profile-page {
  padding: 50px 10px 10px 10px;
  &.profile-pdf{
    .person-image{
      border:1px solid  nth($dark-grey, 3);
    }
    .profile-page-content .score-cards .score-active-cards {
      margin-top: 55px;
      min-width: 30%;
      @include breakpoint(lg-up){
        min-width: 24%;
      }
      .card-scout {
        .career-group-card {
          width: 110px;
          margin: auto;
          position: relative;
          top: -28px;
        }
      }
    }
  }
  .profile-page-content {
    background-color: nth($grey-shades, 2);
    border-radius: 10px;
    .person-details {
      box-shadow: 0 0 3px -7px rgba(0, 0, 0, 0.3);
      background-color: nth($grey-shades, 2);
      min-height: 80px;
      min-width: 300px;
      width: 75%;
      border-radius: 10px !important;
      display: flex;
      @include breakpoint(lg-down) {
        width:100%;
      }
      @include position ($position: relative, $top: -35px, $left: 0, $right: 0);
      .about-person {
        .person-image {
          overflow: hidden;
          width: 65px;
          height: 65px;
          background-color:nth($white-shades, 1);
          box-shadow: 0 0 0 1px nth($dark-grey, 3);
          border-radius:100%;
          img {
            width: 100%;
          }
          color: nth($black-shades, 4);
          font-size: 57px;
        }
        .person-image-edit {
          @include custom-circle ($border-color: nth($dark-grey, 3), $width: 20px, $height: 20px, $background-color:nth($white-shades, 1), $border-radius:100%);
          @include position ($position: relative, $top: 15px, $left: -15px, $right: 0, $bottom:0);
          cursor: pointer;
          i {
            color: nth($black-shades, 4);
            font-size: nth($font-small-point, 1);
          }
        }
        ul {
          margin: 0px 15px;
          li {
            margin: 5px 0px;
            span {
              white-space: nowrap;
              font-size: nth($font-small-point, 9);
              color: nth($black-shades, 4);
            }
          }
        }
      }
    }
    .score-cards {
      position: relative;
      .score-active-cards {
        min-width: 34%;
        margin: 30px 5px 20px;
        @include breakpoint(lg-up){
          min-width: 24%;
        }
      }
      .tabname{
        position: relative;
        top:-15px;
        font-weight: bold;
      }
      .card {
        border-radius: 10px !important;
        margin: 5px 0;
        min-height: 150px;
        box-shadow: none;
        .circle-image {
          @include custom-circle ($border-color: nth($white-shades, 1), $width: 65px, $height: 65px, $background-color:nth($white-shades, 1), $border-radius:100%);
          @include position ($position: relative, $top: -35px, $left: 0, $right: 0);
          box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.3);
          img {
            width: 45px;
            height: 45px;
          }
          &.navigator-pink {
            background-color: nth($red-shades, 9);
          }
          &.discoverer-pink{
            background-color: nth($pink-shades, 13);
          }
          &.scout-green{
            background-color: nth($green-shades, 11);
          }
          &.career-corner{
            background-color: nth($powder-blue, 11);
          }
          &.explorer{
            background-color: nth($yellow-shades, 3);
          }
          &.pathfinder{
            background-color: nth($blue-shades, 14);
          }
          &.trekker{
            background-color: nth($pink-shades, 3);
          }
          &.quickPic{
            background-color: nth($purple-shades, 2);
          }
        }
          &.card-explorer {
            border: 1px solid nth($yellow-shades, 3);
          }
          &.card-pathfinder {
            border: 1px solid nth($blue-shades, 14);            
          }
          &.card-trekk {
            border: 1px solid nth($pink-shades, 3);            
          }
          &.card-quickpic {
            border: 1px solid nth($purple-shades, 2);            
          }
          &.card-discoverer{
            border: 1px solid nth($pink-shades, 13);            
          }
          &.card-scout{
            border: 1px solid nth($green-shades, 11);            
          }
          &.card-navigator{
            border: 1px solid nth($red-shades, 9);            
          }
          &.card-career-corner{
            border: 1px solid nth($powder-blue, 11);            
          } 
        
        .badge-section {
          p {
            font-size: nth($font-small-point, 1);
            color: nth($black-shades, 4);
          }
        }
      }
      .badge {
        @include position ($position: absolute, $top: 10px, $right: 5px);
        .checkmark-box {
          .success-tick {
            font-size: $default-font;
          }
        }
      }
      .personality-types {
        background-color: nth($grey-shades, 2);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        min-height: 128px;
        height: 128px;
        p {
          font-size: nth($font-small-point, 9);
          color: nth($black-shades, 4);
          padding: 7px 10px;
          margin-top: 0px;
        }
        .types {
          width: 50%;
        }
        span {
          font-size: nth($font-small-point, 1);
          color: nth($black-shades, 4);
        }
        img {
          height: 40px;
          margin: 3px auto;
        }
        @include breakpoint(sm-up){
          .career-group-content{
            height: 48px;
            padding-top: 15px;
          }
        }
        &.scout-career {
          img {
            width: 60px;
            height: 45px;
          }
        }
      }
      .skills {
        background-color: nth($grey-shades, 2);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        min-height: 128px;
        height: 128px;
        padding:5px;
        p {
          color: nth($black-shades, 4);
          font-size: nth($font-small-point, 5);
        }
        .good-skills,
        .improve-skills {
          width: 50%;
          height: 95px;
          img {
            width: 30px;
            margin: 2px;
          }
          span {
            margin: 2px 2px;
            color: nth($black-shades, 4);
            font-weight: bold;
          }
        }
        .improve-skills {
          border-left: 1px solid nth($grey-shades, 11);
        }
        .skill-header{
          margin-bottom: 0;
          height: 25px;
          width: 95%;
        }
      }
      &.second-row{
        padding-top: 50px;
      }
    }
    .Occupations-cards {
      margin-top: 35px;
      border-radius: 5px;
      border: 2px solid nth($grey-shades, 3);
      box-shadow: none !important;
      padding: 0px 10px 10px 10px !important;
      .common-occupation-head {
        @include custom-circle ( $width: 100%, $height: 35px, $background-color:nth($white-shades, 1), $border-radius:20px);
        @include position ($position: relative, $top: -17px, $left: 0, $right: 0);
        border: 2px solid nth($grey-shades, 3);
        color: nth($black-shades, 4);
        img {
          width: 30px;
        }
      }
      .circle-image {
        @include custom-circle ($border-color: nth($grey-shades, 1), $width: 60px, $height: 50px, $background-color:nth($white-shades, 1), $border-radius:100%);
        @include position ($position: relative, $top: 0px, $left: -10px);
        &.thumb-icon {background-color:nth($green-shades, 2)}
      }
      &.career-occupations {
        position: relative;
        background: nth($white-shades, 1);
        padding-bottom: 0 !important; // Style important is Required for override the mat-group box padding
        .Occupations-cards {
          margin-top: 16px;
          border: 0;
          padding: 0 !important; // Style important is Required for override the mat-tab-body box padding
        }
        .common-occupation-head {
          top: 0;
          border: 0;
          border-radius: 0;
          &.dream-career-head {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          } 
          .carrer-creation-count {
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
            width: 50px;
          }
          .carrer-creation-title {
            justify-content: center;
            margin-left: 25px;
            @include breakpoint((lg, xl)){
              justify-content: flex-end;
              margin-right: 2px;
              margin-left: 40px;
            }
          }
        }
        .pencil-career-icon {
          position: absolute;
          width: 50px;
          top: -22px;
          left: 1px;
          @include z-index(nine);
          img {
            width: 30px;
          }
        }
        .own-occ-card {
          .own-occ-image {
            border: 1px solid nth($green-shades, 2);
          }
        }
      }
    }
    .skills{
      p{
        font-size: nth($font-small-point, 6);
      }
    }
    .mat-tab-group {
      position: relative;
      top: -17px;
      .mat-tab-labels {
        border: 2px solid nth($grey-shades, 3);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        .mat-tab-label {
          padding: 0;
          opacity: 1;
          height: auto;
          min-width: inherit;
          flex: 1;
          &.mat-tab-label-active,
          &.cdk-focused {
            .common-occupation-head {
              background-color: nth($grey-shades, 3);
            }
          }
          .carrer-creation-head {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;

          }
        }
        .mat-tab-label-content {
          justify-content: flex-end;
        }
      }
      .mat-tab-body {
        &.mat-tab-body-active {
          overflow-y: hidden;
        }
      }
    }
    .favorite-careers {
      .favorite-career-block {
        min-height: 424px;
      }
    }
  }
}
.profile-page-header {
  margin-top: 80px;
  .download-section {
    justify-content: flex-end;
    padding: 5px;
    margin-bottom: 20px;
    .download-button {
      background-color: nth($green-shades, 1);
      color: nth($white-shades, 1);
      border-radius: 5px;
      i {
        margin-left: 10px;
      }
    }
  }
  .landing-page-heading {
    margin-top: 0;
  }
}

.scroll-profile {
  .content-box {
    margin-top: 40px;
  }

}
.Career-trek-content {
  .card-explorer {
    border: 1px solid nth($yellow-shades, 3);
    .card-header{
      background-color: nth($yellow-shades, 3);
    }
  }
  .card-pathfinder {
    border: 1px solid nth($blue-shades, 14);
    .card-header{
      background-color: nth($blue-shades, 14);
    }
  }
  .card-trekk {
    border: 1px solid nth($pink-shades, 3);
    .card-header{
      background-color: nth($pink-shades, 3);
    }
  }
  .card-quickpic {
    border: 1px solid nth($purple-shades, 2);
    .card-header{
      background-color: nth($purple-shades, 2);
    }
  }
  .card-discoverer{
    border: 1px solid nth($pink-shades, 13);
    .card-header{
      background-color: nth($pink-shades, 13);
    }
  }
  .card-scout{
    border: 1px solid nth($green-shades, 11);
    .card-header{
      background-color: nth($green-shades, 11);
    }
    .career-header {
      margin-bottom: 20px;
    }
    .career-group-card {
      .card-body {
        padding-top: 10px;
        min-height: 50px;
      }
    }
  }
  .card-navigator{
    border: 1px solid nth($red-shades, 9) !important;
    .card-header{
      background-color: nth($red-shades, 9);
    }
  }
  .card-career-corner{
    border: 1px solid nth($powder-blue, 11);
    .card-header{
      background-color: nth($powder-blue, 11);
    }
  } 
}
.Occupations-list {
  .card {
    margin: 10px 0px !important;
    box-shadow: none !important;
    .like-card,
    .dislike-card {
      border: 1px solid nth($grey-shades, 3) !important;
    }
    .card-header {
      background-color: nth($grey-shades, 9);
      padding: 5px 0px !important;
      color: nth($black-shades, 1);
    }
    .list {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 230px;
      color: nth($black-shades, 2);
      padding: 0px !important;
      .mat-divider {
        position: relative !important;
      }
      .mat-list-item {
        font-size: nth($font-small-point, 4);
      }
    }
  }
}

.pf-details {
  width: 62px;
}

.own-occ-card {
  border: 1px solid nth($grey-shades, 3);
  display: flex !important;
  padding: 0px !important;
  border-radius: 5px !important;
  margin: 4px auto;
  width: 95%;
  min-height: 90px;
  .own-occ-image {
    border-right: 2px solid nth($grey-shades, 3);
    width: 35%;
    align-items: center;
    display: flex;
    img {
      width: 100%;
      height: auto;     
    }
  }
  .own-occ-text {
    p {
      margin: 5px 15px;
      font-size: nth($font-small-point, 4);
      color: nth($black-shades, 4);
      word-break: break-all;
      @include breakpoint(sm) {
        margin: 2px 5px;
        font-size: nth($font-small-point, 1);
      }
    }
  }
}

.score-block {
  width: 50%;
  margin: auto;
  .score-section {
    margin: 0px 10px;
    line-height: 1;
    &.blue-border {
      border-left: 2px solid nth($blue-shades, 5);
    }
    &.orange-border {
      border-left: 2px solid nth($red-shades, 2);
    }
    span {
      font-size: nth($font-small-point, 1);
      margin: 3px;
    }
    p {
      font-size: nth($font-small-point, 3);
    }
  }
}

.mat-card-section {
  height: 365px;
  &.pdf-design{
    height: auto !important;
  .fav-occ-cards {
  min-height: 90px;
  margin: 4px auto !important;
  display: flex;
  align-items: center;
  }
  }
  overflow-y: auto;
  overflow-x: hidden !important;
  .no-data-found {
    width: 100px;    
    height: 80px;
  }
  .empty-block{
    height: 60px;
  }
  @include breakpoint(sm) {
    overflow-y: initial;
    height: 100%;
  }
  .fav-occ-cards {
    width: 95%;
    margin: 4px auto;
    @include breakpoint(sm) {
      margin: 5px auto;
    }
    .circle-image {
      @include custom-circle ($border-color: nth($grey-shades, 1), $width: 60px, $height: 60px, $background-color:nth($grey-shades, 1), $border-radius:100%);
      margin: 5px 10px;
      color: nth($black-shades, 4);
      font-size: nth($font-small-point, 2);
      @include breakpoint(sm) {
        @include width-height(40px, 40px);
        font-size: nth($font-small-point, 4);
      }
    }
    p {
      font-size: nth($font-small-point, 4);
      margin: 5px 0px;
      color: nth($black-shades, 4);
      @include breakpoint(sm) {
        font-size: nth($font-small-point, 1);
        margin: 0px;
      }
    }
    &.custom-career-cards{
      .own-occ-image{
        width: 170px;
        padding: 2px;
        @include breakpoint(sm) {
          width: 145px;
        }
      }
      .career-cards-text{
        margin-top: 2px;
        margin-left: 5px;
      }
    }
  }
}

.count {
  background-color: nth($green-shades, 2);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  @include width-height(60px, 35px);
  color: nth($white-shades, 1);
  font-size: nth($font-small-point, 4);
}

/*****  Profil mobile   *****/

.profile-screen {
  background-color: nth($white-shades, 1) !important;
  padding: 5px;
  display: inline-table; // This line for height covered to bottom with white bg based on content //
  margin-bottom: 5px;
  @include breakpoint(xs) {
    padding-bottom: 40px;
  }
  .badge {
    @include position ($position: absolute, $right: 0px, $top: 40px);
  }
  .content-box {
    padding: 4px 3px 10px;
    margin-top: 30px;
    margin-bottom: 75px;
  }
  .profil-img {
    width: 120px;
    height: 120px;
    // border: 1px solid nth($grey-shades, 9);
    box-shadow: 0 0 0 1px nth($grey-shades, 9);
    background-color: nth($white-shades, 1);
    border-radius: 50%;
    margin: 12px auto;
    overflow: hidden;
    img {
      width: 100%;
    }
    color:nth($grey-shades, 9);
    font-size: 57px;
  }
  .person-image-edit {
    @include custom-circle ($border-color: nth($grey-shades, 9), $width: 25px, $height: 25px, $background-color:nth($white-shades, 1), $border-radius:100%);
    @include position ($position: absolute, $top: 80px, $left:105px, $right: 0);
    margin: auto;
    i {
      color: nth($grey-shades, 9);
      font-size: nth($font-small-point, 4);
    }
  }
  h5,
  p {
    color: nth($black-shades, 4);
    margin-bottom: 2px;
    padding: 0 3px;
  }
  .profil-card {
    padding: 10px !important;
    min-height: calc(60vh - 50px);
    margin: 0 0 5px;
    display: flex;
  }
  .score {
    .career-group-title {
      padding-bottom: 10px;
    }
    .card .card-body {
      .personality-types {
        flex: 1;
      }
      .success-tick {
        right: 0px !important;
        font-size: nth($font-small-point, 5);
        padding: 1px;
      }
      img {
        width: 40px;
        margin: auto;
      }
    }
    .card .card-header {
      padding: 5px 0 !important;
      display: flex !important;
      margin:-1px -1px 0 -1px;
      align-items: center;
      font-weight: bold;
      p {
        color: nth($white-shades, 1) !important;
        font-size: nth($font-small-point, 3);
      }
      img {
        width: 20px;
        margin-right: 5px;
      }
    }
    .card-inner-content {
      width: 50%;
      padding: 10px;
    }
    .career-group-text{
      text-align: left;
      margin-right: 20px;
      font-weight: bold;
    }
    @include breakpoint(sm) {
       .not-saved-text {
         font-weight: bold;
       } 
    }
    .card-scout {
      .career-group-card {
        min-height: 70px;
        display: flex;
        img {
          width: 60px;
        }
      }
    }
    .card-navigator {
      .card-inner-content img {
        height: 45px;
      }
    }
  }
  .d-flex{
    display: flex;
   }
  .skills-sec{
    display: flex;
    .good-skills,
    .improve-skills {
      width: 50%;
    }
    @include breakpoint(sm) {
      .skills-block {
        width: 50%;
        margin: 0 3px;
        p {
          font-weight: bold;
        }
      }
    }
    
    @include breakpoint(xs) {
      display: block;
      .good-skills,
      .improve-skills {
        width: 100%;
      }
    }
    .skills-heading{
      padding:10px 0;
      font-weight: bold;
    }
 }
  .fav-occupations {
    .mat-expansion-panel-header {
      background-color: nth($green-shades, 2)!important; //important kept because mat panelheader is overriding with white color
      height: 30px !important;
      padding: 0 !important;
      overflow: hidden;
      .mat-expansion-panel-header-title {
        color: nth($white-shades, 1) !important;
        margin: 0px;
        align-items: center;
        img {
          @include width-height(20px, 20px);
          margin-left: 10px;
        }
      }
      .mat-expansion-indicator::after {
        display: none !important;
      }
      &.mat-expanded {
        &:hover,
        &:focus {
          background-color: nth($green-shades, 2);
        }
      }
    }
    .mat-expansion-panel-header:not([aria-disabled=true]) {
      &:hover {
        background-color: nth($green-shades, 2);
      }
    }
    .mat-expansion-panel-body {
      padding: 0px !important;
      height: calc(47vh - 20px);
      overflow-x: hidden;
      justify-content:center;
      .no-data-found{
        width: 100px;
      }
    }
    .mat-expansion-panel-spacing {
      margin: 0 !important;
    }
    .mat-expansion-panel:nth-child(1) .mat-expansion-panel-header,
    .mat-expansion-panel {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .mat-expansion-panel:nth-last-child(0) .mat-expansion-panel-header,
    .mat-expansion-panel {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      &.mat-expanded {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }
    @include breakpoint(sm) {
      .material-icons {
        font-size: nth($font-medium-point, 2);
      }
    }
  }
}
// progress-bar css
.own-occ-card {
  @include breakpoint(sm) {
    min-height: 80px !important;
  }
}

.pdf-button {
  background-color: nth($green-shades, 1) !important;
  color: nth($white-shades, 1);
  bottom: 32px;
  right: 0px;
  @include z-index(modal);
  &.mat-fab {
    @include width-height(50px !important, 50px !important);
    position: fixed;
    .mat-button-wrapper {
      line-height: inherit;
    }
  }
  .material-icons {
    font-size: nth($font-small-point, 3);
  }
}

.progress {
  padding-bottom: 5px;
  text-align: center;
  display: flex;
  .circle,
  .bar {
    display: inline-block;
    background: transparent;
    border: none;
    vertical-align: top;
    padding: 0;
    position: relative;
    cursor: pointer;
    &.active{
      background-color: nth($green-shades, 2) !important;
    }
  }
  .bar {
    @include position($position: relative, $top:12px);
    @include width-height(25%, 2px);
    margin: 0 -30px 0px -30px;
    vertical-align: top;
    background-color: nth($grey-shades, 11) !important;
    @include breakpoint(sm) {
      
    }
  }
  .label {
    display: inline-block;
    @include width-height(20px, 20px);
    border-radius: 50%;
    margin-top: 3px;
    font-size: nth($font-small-point, 1);
    background-color: nth($grey-shades, 11);
    color: nth($black-shades, 1);
    @include breakpoint(sm) {
      margin-top: 5px;
    }
    i.material-icons {
      position: relative;
      top: 4px;
      font-size: nth($font-small-point,1);
      @include breakpoint(sm) {
        font-size: nth($font-small-point,1);
        top: 2px;
      }
    }
    &.scout-active{
      background-color: nth($green-shades, 11);;
      color: nth($white-shades, 1);
      margin-bottom: 4px;
    }
    &.explorer-active{
      background-color: nth($yellow-shades, 3);
      color: nth($white-shades, 1);
      margin-bottom: 4px;
    }
    &.pathfinder-active{
      background-color: nth($blue-shades, 14);
      color: nth($white-shades, 1);
      margin-bottom: 4px;
    }
    &.trekker-active{
      background-color: nth($pink-shades, 3);
      color: nth($white-shades, 1);
      margin-bottom: 4px;
    }
    &.quickpic-active{
      background-color: nth($indigo-shades, 2);
      color: nth($white-shades, 1);
      margin-bottom: 4px;
    }
    &.discoverer-active{
      background-color: nth($pink-shades, 14);
      color: nth($white-shades, 1);
      margin-bottom: 4px;
    }
    &.navigator-active{
      background-color: nth($red-shades, 9);
      color: nth($white-shades, 1);
      margin-bottom: 4px;
    }
    &.career-corner-active{
      background-color: nth($powder-blue, 12);
      color: nth($white-shades, 1);
      margin-bottom: 4px;
    }
    @include breakpoint(sm) {
      @include width-height(15px, 15px);
      line-height: 15px;
      font-size: nth($font-small-point, 5);
    }
  }
  .title {
    font-size: nth($font-small-point, 1);
    color: nth($black-shades, 1);
    display: block;
    width: 80px;
    margin-top: 3px;
    @include breakpoint(sm) {
      width: 75px;
    }
  }
}

.profile-stepper {
  .mat-stepper-horizontal {
    background-color: transparent;
  }
  .mat-horizontal-stepper-content {
    padding: 2px 5px !important;
  }
  .mat-horizontal-stepper-header {
    height: 38px;
    overflow: hidden;
    width: 100%; 
    padding: 0 !important;
  }
  .mat-step-label {
    height: auto;
    overflow: hidden;
    width: 100%;
    padding: 3px;
    text-align: center;
    background-color: nth($grey-shades, 10) !important;
    margin: 0 3px;
    color: nth($white-shades, 1) !important;
    font-size: nth($font-small-point, 5);
    white-space: pre-wrap;
    min-height: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mat-step-label-selected {
    color: nth($black-shades, 4) !important;
    background-color: nth($white-shades, 1) !important;
    &::before {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 10px solid nth($white-shades, 1);
      content: " ";
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .score {
    .card {
      @include breakpoint(sm) {
        margin-bottom: 5px;
      }
    }
  }
}
/*** custom-ooc details start  **/
.custom-occ-content{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  .d-table{
    height:100vh;
  }
  .custom-occ-header{
    padding: 0px;
  }
  @include breakpoint(sm-up) {
    .landing-page-heading {
      margin-top: 40px;
    }
  }
  .skill-block{
    .status-block li{
      @include breakpoint(sm-up){
        width: 106px;
        margin: 3px 0;
      }
    }
  }
  
}

.custom-header{
  height: auto;
  display: flex;
  word-break: break-all;
  p{
    font-weight: 600;
    font-size: 1.4rem;
    color: nth($green-shades, 10);
  }
}

.custom-occ-first{  
  height: auto;
  border: 1px solid nth($green-shades, 2);
  border-radius: 4px;
  padding: 10px;
  background-color: nth($white-shades, 1);
  .spriteImage{
    width: 100%;
  }
}
.custom-occ-first div button{
  margin: 15px 0 !important;
}
.custom-occ-first{
  @include breakpoint(sm) {
     width: 100%;
     display: block;
  }
  .div-img{
    width: 35%;
    @include breakpoint(sm) {
      width: calc(75vw - 40px);
      margin: auto;
    }
  }
  .div-para{
    width: 75%;
    margin-left: 12px;
    @include breakpoint(sm) {
      margin: auto;
      width: calc(75vw - 10px);
    }
  }
}

 .custom-occ-second {
      @include breakpoint(sm) {
    display: none;
   }
 }

.create-own-occ-detail {
  @include breakpoint(sm) {
    &.content-box {
      margin-top: 30px;
    }
  }
  .empty-attr{
    opacity:0.5;
  }
  .empty-attr-mobile{
    opacity:0.5;
  }
  .pathfinder-empty{
    height: 50px;
    width: 80%;
    min-height: 50px;
    margin: auto;
  }
  height: auto !important;

  .mat-tab-body.mat-tab-body-active{
    width: calc(80vw - 40px);
    overflow-x: auto;
  }
  @include breakpoint(sm){
  .container {
      width: 100%;
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
  }
}
  .readmore-button{
    border: 1px solid;
  }
  .bg-none{
    background-color: nth($grey-shades, 18);
    padding: 5px;
  }
  .explorer-active {
    background-color: nth($yellow-shades, 11);
    padding: 5px;
  }
  .pathfinder-active {
    background-color: nth($blue-shades, 15);
    padding: 5px;
    .title{
      font-size: nth($font-small-point, 3);
    }
    .title-values {
      font-size: nth($font-small-point, 3);
      padding-left: 18px;
    }
  }
  .trekker-active {
    background-color: nth($pink-shades, 5);
    padding: 5px;
  }
  .image_a{
      color: nth($red-shades, 1);
      font-size: 0.925rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      i{
        font-size: 0.925rem;
        margin-left: 10px;
      }
      span{         
      text-decoration: underline;  
      }
  }
  .image-type{
    width: 100px;
    @include breakpoint(sm) {
      width: 50px;
      padding: 3px 0;
    }
  }
  .image-type-emptycard{
    width: 60px;
    transform: rotate(85deg);
    @include breakpoint(sm) {
      width: 30px;
    }
  }
  .image-type-emptycard-work-values{
    width: 45px;
    transform: rotate(85deg);
    @include breakpoint(sm) {
      width: 22px;
    }
  }
.careercreator-pick{
  min-height: 110px;
  align-items: center;
  justify-content: center;
  @include breakpoint(sm) {
    min-height: 70px;
  }
}
  .medium_imgs li{
    width: 15%;
  }

  h2 {
    @include breakpoint(lg-down) {
      font-size:nth($font-small-point, 3);
    }
    @include breakpoint(sm) {
      font-size: nth($font-small-point, 4);
      margin-top: 5px;
    }
  }
  h4 {
    color: nth($black-shades, 2);
    margin: 5px 0px;
  }
  .list-scroll-card {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 30px) !important;
  }
  .second-block {
    padding: 15px;
    background-color: nth($grey-shades, 2);
    height: 100%;
    @include breakpoint ((sm, lg)) {
      padding: 5px;
    }
    h2 {
      @include breakpoint(sm) {
        margin-top: 5px;
      }
    }
    .status-card .card { 
      .card-header {
        font-weight: 600;
        &.green {
          background-color: nth($green-shades, 7);
        }
        &.red {
          background-color: nth($orange-shades, 2);
        }
        &.purple {
          background-color: nth($purple-shades, 1);
        }
      }
      .icon-sec li {
        margin: 3px 0;
        width: 106px;
      }
    }
    .skill-block {
      .card {
        .status-circle img {
            width: 50px;
        }
      }
      .card-header {
        font-weight: 600;
        &.green {
          background-color: nth($green-shades, 7);
        }
        &.red {
          background-color: nth($orange-shades, 2);
        }
        &.purple {
          background-color: nth($purple-shades, 1);
        }
      }
    }
    .tabs-status-tabs {
      .tabs-card {
        box-shadow: none !important;
        background-color: transparent !important;
        width: 100%;
        .mat-tab-label {
          min-width: 100px;
        }
      }
      .pathfinder-card {
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .card {
      box-shadow: none;
      &.explorer-characteristics{
        border: 1px solid nth($yellow-shades, 3);
      }
      &.pathfinder-personality {
        border: 1px solid nth($blue-shades, 14);
      }
      &.trekker-skills{
        border: 1px solid nth($pink-shades, 3);
      }
    }
    .career-group-block{
      .career-image {
        width: 80px;
      }
      @include breakpoint(sm-up) {
        .career-image {
          width: 100px;
        }
      }
    }
  }
  @include breakpoint(sm-up) {
    padding: 10px 16px;
    .status-circle {
      font-size: nth($font-small-point, 4);
      @include custom-circle ($border-color: nth($white-shades, 1), $width: 60px, $height: 60px, $background-color: nth($white-shades, 1), $border-radius: 50%);
      overflow: hidden;
    }
  }
  @include breakpoint(sm) {
    .card .card-body {
      min-height: 65px !important;
      background-color: nth($white-shades, 1);
      .mat-tab-header {
        border: none !important;
      }
    }
    .card.tabs-card {
      padding: 0 !important;
      width: 100% !important;
      border: 1px solid nth($purple-shades, 2);
      margin-bottom: 4px;
      .card-header {
        padding: 5px 0px !important;
      }
      .mat-tab-label {
        min-width: 33% !important;
        height: 37px !important;
      }
      .tab-button {
        min-height: 25px !important;
        height: 25px;
        border-radius: 0px !important;
      }
      .personality-types {
        width: 50%;
        img {
          width: 35px;
        }
        p {
          font-size: nth($font-small-point, 1);
        }
      }
    }
    .mat-stepper-horizontal {
      background-color: transparent !important;
    }
  }
  /**********   tabs    ********************/
  .tabs-card {
    width: 95%;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2) !important;
    background-color: nth($grey-shades, 2) !important;
    .mat-tab-label {
      opacity: 1 !important;
      height: 50px !important;
      padding: 0 !important;
      min-width: 85px;
      background-color: none !important;
      @include breakpoint ((sm, lg)) {
        height: 40px !important;
      }
    }
  }
  .tab-button {
    color: nth($white-shades, 1);
    border-radius: 6px;
    padding: 2px 0px;
    min-height: 35px;
    height: 35px;
    width: 95%;
    border: none;
    box-shadow: none;
    @include breakpoint ((sm, lg)) {
      min-height: 25px;
      height: 25px;
      margin: auto;
    }
    &.green {
      background-color: nth($green-shades, 7);
    }
    &.red {
      background-color: nth($orange-shades, 2);
    }
    &.purple {
      background-color: nth($purple-shades, 1);
    }
  }
  .mat-tab-labels {
    display: flex !important;
    justify-content: center !important;
  }
  .mat-tab-label-active .green::before {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid nth($green-shades, 7);
    content: " ";
    position: absolute;
    bottom: 0px; 
    left: 35%;
  }
  .mat-ink-bar {
    visibility: hidden !important;
  }
  .circle-img {
    @include custom-circle ($border-color: nth($white-shades, 1), $width: 55px, $height: 55px, $border-radius: 50%);
    display: flex;
    align-items: center;
    margin: 0 2px 10px;
    justify-content: center;
    overflow: hidden;
    // &.green {
    //   background-color: nth($green-shades, 3);
    // }
    // &.orange {
    //   background-color: nth($orange-shades, 2);
    // }
    // &.purple {
    //   background-color: nth($purple-shades, 1);
    // }
    img{
      width:40px;
      height: 40px;
    }
  }
  .mat-tab-label-active .red::before {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid nth($orange-shades, 2);
    content: " ";
    position: absolute;
    bottom: 0px;
  }
  .mat-tab-label-active .purple::before {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid nth($purple-shades, 1);
    content: " ";
    position: absolute;
    bottom: 0px;
  }
  /************************************/
  .side-task-list .image-card.active::after,
  .side-task-list .image-card.active::before {
    right: 0;
    padding-left: 3.5%;
    left: inherit;
  }
  .side-task-list .image-card.active::before {
    transform-origin: 20% 100%;
    transform: skewy(-70deg);
  }
  .side-task-list .image-card.active::after {
    transform-origin: 20% 100%;
    transform: skewy(70deg);
  }
  .image-card.active {
    .opacity-text {
      position: unset;
      p,
      p:before {
        position: absolute;
        top: 0;
        height: 100%;
      }
    }
  }
  .occ-detail-margin{
    margin-top: 18px;
  }
  .custom-occ-detail {
    .scout-career {
      .career-group-block {
        background-color: nth($green-shades, 12);
        padding: 5px;
        .career-item {
          background-color: nth($white-shades, 1);
          border-radius: 4px;
          padding: 10px 0;
          min-height: 110px !important; //important is needed here because it is getting overrided by some other classes
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .career-image {
          width: 100px;
          margin-right: 10px;
        }
        .title {
          font-size: nth($font-small-point, 3);
          // color: nth($black-shades, 4);
        }
        .title-print{
          font-size: nth($font-small-point, 3);
          padding-left: 18px;
        }
      }
    }
    .mat-card {
      box-shadow: none;
    }
    .custom-occ-edit-header{
      display: flex;
      font-size: 1.1rem;
      align-items: center;
      .mat-button{
        color : nth($dark-grey, 4);
        .material-icons{
          font-size: nth($font-small-point, 3);
        }
      }
      .custom-occ-edit-btn{
        padding: 0 5px;
        min-width: 20px;
        line-height: normal;
        img{
          width: 18px;
          height: 18px;
          // transform: rotate(85deg);
        }
      }
    }
    .navigator-career {
      @include breakpoint(lg-up) {
        .career-work-group {
          background-color: nth($red-shades, 8);
          padding: 5px;
          .navigator-career-card {
            padding: 20px 0;
            border: 0;
            img {
              width: 70px;
              height: 70px;
            }
            .title {
              font-size: nth($font-small-point, 3);
            }
          }
        }
      }
      .career-work-group-print {
        background-color: nth($red-shades, 8);
        padding: 5px;
        .navigator-career-card {
          padding: 20px 0;
          border: 0;
          img {
            width: 70px;
            height: 70px;
          }
          .title-values{
            padding-left: 18px ;
            font-size: nth($font-small-point, 3);
          }
        }
      }
      .navigator-career-card {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        background-color: nth($white-shades, 1);
        padding: 10px 5px;
        margin-right: 5px;
        border: 1px solid nth($red-shades, 9);
        border-radius: 4px;
        &:last-child {
          margin-right: 0;
        }
        // .career-work-values{
        //   display: flex;
        //   align-items: center;
        // }
        img {
          width: 45px;
          height: 45px;
        }
        .title {
          @include breakpoint(sm) {
            padding-left: 2px;
            font-size: nth($font-small-point, 5);
            font-weight: 500;
            // margin: 0px;
            // font-size: nth($font-small-point, 3);
          }
          padding-left: 5px;
        }
      }
    }
  }
  &.print-page {
    .second-block .skill-block .card .status-circle img {
      width: 30px;
    }
  }
}

/*** custom-ooc details end  **/
.not-saved-text{
  font-size: nth($font-small-point, 4) !important;
  // important is mention as getting font size from .badge-section p 
  margin-top: 20px;
}

//For hiding print page
.hidden-page {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
/* Style for Custom occ details Print start */
.print-page{  
  &.content-box {
    background-color: #fff;
    box-shadow: 0 0 20px 4px #c7c7c7;
    margin-top: 0;
    margin-bottom: 80px;
    padding: 10px 16px;  
  }
  .second-block {
    padding: 10px;
    background-color: #eeeeee;
    height: 100%;
    .status-card,
    .skill-block {
      .card .icon-sec li,
      .status-block li {
        margin: 3px 0;
        width: 80px;
    }
  }
}

.custom-occ-first{  
  height: auto;
  border: 1px solid nth($grey-shades, 16);
  padding: 10px;
  display: flex;
  .spriteImage{
    width: 100%;
  }
}
.custom-occ-first div button{
  margin: 15px 0 !important;
}
.custom-occ-first{  
  .div-img{
    width: 30%;  
  }
  .div-para-print{
    width: 75%;
    margin-left: 12px;
    font-size: 0.85rem !important;  
  }
}
.custom-occ-second{ 
  @include breakpoint(sm) {
    display: block;
   }
}
.card{
  .card-header {
   text-align: center;
   padding: 5px 0px;
   margin-bottom: 0 !important;
   font-size: 1.2rem;
   border-top-left-radius: 4px;
   border-top-right-radius: 4px;
   color: #fff;
   }
 }
&.create-own-occ-detail {
  .status-circle {
    font-size: nth($font-small-point, 5);
    @include custom-circle ($border-color: nth($white-shades, 1), $width: 50px, $height: 50px, $background-color: nth($white-shades, 1), $border-radius: 50%);
    overflow: hidden;
    img {
     width: 30px;
      }
  }
  .img-title{
    // width: 85%; 
    font-size: nth($font-small-point, 1);   
    margin-top: 2px;
  }
  .custom-occ-detail .navigator-career .career-work-group {
    @include breakpoint(sm) {
      background-color: nth($red-shades, 9);
      padding: 5px;
    }
    .navigator-career-card {
      padding: 10px 0;
      @include breakpoint(sm) {
        border: inherit;
      }
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
  .image-type {
    width: 70px;
  }
  .custom-occ-detail .scout-career .career-group-block {
    .career-item {
      min-height: inherit !important; //important is needed here because it is getting overrided by some other classes styles
      img {
        width: 80px;
      }
    }
  }
}

}
/* Style for Custom occ details Print end */
/* Style for profile page Print start */
.profile-pdf{
  @include breakpoint(sm) {
    .mat-card-section .fav-occ-cards p {
      font-size: nth($font-small-point, 4);
    }
    .common-occupation-head {
      .circle-image span {
        font-size: $default-font;
      }
    } 
    .own-occ-card .own-occ-text p {
      font-size: nth($font-small-point, 4);
    }
  }
.not-saved-text p{
  padding: 0 !important;
  margin: 0;
}
.white-space {
  white-space: break-spaces; /* This style property supports and removes the text overlapping in Mac OS */
  margin: 0;
}
.mat-card-section .no-data-found {
  width: 100px;
  height: auto;
}
.empty-block {
  margin-top: 50px;
  margin-bottom: 25px;
}
.profile-page-content .score-cards {
  .tabname {
    font-size: 1rem;
}
  .skills {
    .not-saved-text p{
      font-size: 1rem;
    }
    .improve-skills {
      height: 110px;
      span {
        font-size: 0.6rem; 
        margin: 2px 2px 2px 10px;     
      }
    }
  p {
    font-size: 0.7rem;
    }
  }
.personality-types{
  .not-saved-text p{
    font-size: 1rem;
  }
  p{
    font-size: 0.75rem;
  } 
span { 
  margin-top: 12px;
}
}
}
.profile-page-content .person-details {
  width: 75%;
}
.fav-occ-cards.custom-career-cards .own-occ-image {
  width: 175px;
}
.progress {
.label {
  width: 20px;
  height: 20px;
  line-height: 19px;  
  i.material-icons {
    top: 2px;
  }
}
.bar {
  position: relative;
  top: 12px;
  height: 2px;
}
.title {
 width: 80px;
}
}
.own-occ-card {
  //Here override the own-occ-card min-height for all breakpoints
  min-height: 90px !important;
  .own-occ-text p{
    margin: 5px 15px;
    height: auto;
    word-break: break-all;
  }
}
.mat-card-section{
  .fav-occ-cards p{
margin: 5px 0;
  }
}

.mat-card-section {
  &.pdf-design{
    overflow-y: auto;
  }
}
}
.badgeFlex{
  display: flex;
}

// Below style properties are need to design custom tooltop looks same as hover text on circle icon
.mat-tooltip.custom-tooltip {
  margin-top: 4px;
  padding: 5px;
  font-size: nth($font-small-point, 5);
  font-weight: 500;
  max-width: unset;
  color: nth($black-shades, 1);
  border-radius: 50px;  
  background-color: nth($white-shades, 1);
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 
              0 6px 10px 0 rgba(0,0,0,.14), 
              0 1px 18px 0 rgba(0,0,0,.12);
}
/* Style for profile page Print end */
