.mat-card-avatar {
  height: auto !important;
  border-radius: 0 !important;
}

.card {
  border: 1px solid nth($grey-shades, 1);
  border-radius: 4px !important;
  padding: 0 !important;
  box-shadow: 0 0 10px 1px nth($grey-shades, 1);
  .card-header {
    min-height: 20px;
    text-align: center;
    padding: 10px 0px;
    margin-bottom: 0 !important;
    font-size: nth($font-small-point, 3);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: nth($white-shades, 1);
    @include breakpoint ((sm, lg))  {
      font-size: $default-font;
    }
    @include breakpoint(sm) {
      font-size: nth($font-small-point, 4);
      padding: 5px 0px;
    }
  }
  .card-footer {
    min-height: 20px;
    text-align: center;
    padding: 3px 5px;
    color: nth($white-shades, 1);
    margin: 0;
    font-weight: 600;
  }
  .card-header-icon {
    font-size: $default-font;
    display: flex !important;
    min-height: 50px;
    text-align: left;
    padding: 5px 10px;
    margin-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid nth($grey-shades, 1);
    box-shadow: 0 0 1px 1px nth($grey-shades, 1);
    img {
      width: 36px;
      height: 36px
    }
  }
  .card-body {
    min-height: 100px;
    margin-bottom: 0;
    &[flex] {
      display: flex !important;
    }
    .pathfinder-result & {
      padding: 10px 0;
      @include breakpoint(sm-up) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    @include breakpoint(sm) {
      min-height: 130px;
    }
  }
}