.mat-button,
.mat-flat-button,
.mat-icon-button,
.mat-stroked-button {
  margin: 2px !important;
}

.mat-button {
  color: nth($white-shades, 1);
  margin: 0 20px;
  width: auto !important;
  &.green {
    background-color: nth($green-shades, 4) !important;
  }
  &.red {
    background-color: nth($red-shades, 2) !important;
  }
  &.pink {
    background-color: nth($pink-shades, 7);
  }
  &.light-pink {
    background-color: nth($pink-shades, 5);
  }
  &.dark-blue {
    background-color: nth($blue-shades, 4);
  }
}
.mat-raised-button{
  &.red {
    background-color: nth($red-shades, 1) !important;
    color: nth($white-shades,1)!important;
  }
}
.mat-fab {
  color: nth($white-shades, 1);
  border: 2px solid nth($white-shades, 1) !important;
  @include width-height(45px !important, 45px !important);
  i {
    color: nth($white-shades, 1);
  }
  @include breakpoint(sm) {
    @include width-height(30px !important, 30px !important);
    line-height: 1px !important;
    i {
      font-size: $default-font;
    }
  }
  
  .mat-button-wrapper {
    padding: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height:30px;
  }
  &.green {
    background-color: nth($green-shades, 3) !important;
  }
  &.orange {
    background-color: nth($orange-shades, 1) !important;
  }
  &.purple {
    background-color: nth($purple-shades, 1) !important;
  }
  &.light-red {
    background-color: nth($red-shades, 3);
  }
  &.yellow {
    background-color: nth($yellow-shades, 3);
  }
  &.pink {
    background-color: nth($pink-shades, 7);
  }
  &.dark-pink {
    background-color: nth($pink-shades, 3);
  }
}

button.card {
  padding: 0;
  width: 100%;
  background-color: nth($white-shades, 1);
}

.audio-box {
  color: nth($white-shades, 1) !important;
  padding: 0 !important;
  box-shadow: none;
  border: none;
  min-width: 34px !important;
  height: 34px;
  line-height: 1!important;
  border-radius: 100%;
  .material-icons {
    font-size: nth($font-small-point, 3);
  }
  // important is placed as css is overriding from .mat-raised-button class
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 
              0 6px 10px 0 rgba(0,0,0,.14), 
              0 1px 18px 0 rgba(0,0,0,.12) !important;
              // Above style for mat-fab same box-shadow is effecting for this button
  @include breakpoint(sm) {
    min-width: 30px !important; // This style with !important for mat-raised-button width is effecting for this button
    height: 30px;
    &.trekker-volume-btn{
      margin-left: 15px;
    }
    .material-icons {
      font-size: $default-font;
    }
  }
}

.next-level {
  @include position ($position: absolute !important, $top: 45%, $right: 5px);
  // z-index: 9;
  @include z-index(nine);
  &.mat-fab{
    .mat-button-wrapper{
      line-height: initial;
    }
  } 
  @include breakpoint((sm)){
   top: 35%;
  }  
  @include breakpoint((sm, lg)) {
    right: -3px;
  }
  @include breakpoint(xl-up) {
    right: -15px;
  }
}

.go-button {
  @include z-index(minus1);
  .go-button-text {
    @include custom-circle ( $width: 80px, $height:30px, $background-color: nth($green-shades, 3) !important);
    box-shadow: 0 0 5px 1px nth($grey-shades, 1) !important;
    line-height: 10px !important;
    border: none;
  }
}

.playagain-button-text {
  height: 30px;
  line-height: 32px !important;
}

.animation-Y {
  min-height: 100px;
  .card-go {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.43);
    min-height: 100px;
    height: 100px;
    position: relative;
  }
}

.cdk-program-focused {
  .mat-button-focus-overlay {
       opacity: 0 !important; // hide button mat-ripple overlay on close button in modal popup.
   }
}