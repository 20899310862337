/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./lib/Abstracts/functions.scss";
@import "./lib/Abstracts/mixins.scss";
@import "./lib/Abstracts/variables.scss";
@import "./lib/Abstracts/Helping-Class.scss";
@import "./lib/Layout/wellcome.scss";
@import "./lib/Layout/sidemenu.scss";
@import "./lib/Layout/header.scss";
@import "./lib/Layout/footer.scss";
@import "./lib/Layout/landing.scss";
@import "./lib/Layout/profile-page.scss";
@import "./lib/Pages/explorer-module.scss";
@import "./lib/Pages/pathfinder-module.scss";
@import "./lib/Pages/quick-pic.scss";
@import "./lib/Pages/save-own-occ.scss";
@import "./lib/Pages/discoverer.scss";
@import "./lib/Pages/career-corner.scss";
@import "./lib/Pages/scout.scss";
@import "./lib/Pages/navigator.scss";
@import "./lib/Pages/career-trek.scss";
@import "./lib/Pages/occ-explorer.scss";
@import "./lib/Components/animations.scss";
@import "./lib/Components/progress-bar.scss";
@import "./lib/Components/card.scss";
@import "./lib/Components/buttons.scss";
@import "./lib/Components/carousel.scss";
@import "./lib/Components/common.scss";
@import "./lib/Components/tabs.scss";
@import "./lib/Components/modals.scss";
@import "./lib/Base/reset.scss";
@import "./lib/sprite/sprites.index.scss";
/****  Wellcom screen ****/
