.career-activity {
    padding: 50px 0;
    @include breakpoint(sm) {
        &.content-box {
            margin-top: 0;
        }
    }
    .carrer-frame-block {
        flex-flow: wrap;
        align-items: flex-end;
        .carrer-frame {
            text-align: center;
            margin-bottom: 30px;
            position: relative;
            .frame-card {
                width: 170px;
                margin: auto;
                cursor: pointer;
                position: relative;
                @include z-index(nine);
            }
            .mat-checkbox {
                img {
                    width: 155px;
                }
            }
            .career-image {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -60%);
                img {
                    width: 125px;
                }
                &.inner-card_1 {
                    transform: translate(-48%, -68%);
                    img {
                        height: 104px;
                    }
                }
                &.inner-card_2 {
                    transform: translate(-45%, -56%);
                    img {
                        height: 85px;
                    }
                }
                &.inner-card_3 {
                    transform: translate(-45%, -72%);
                }
                &.inner-card_4 {
                    transform: translate(-44%, -74%);
                    img {
                        height: 90px;
                    }
                }
                &.inner-card_5 {
                    transform: translate(-46%, -69%);
                    img {
                        height: 100px;
                    }
                }
                &.inner-card_6 {
                    transform: translate(-45%, -53%);
                }
            }
        }
        @include breakpoint(sm) {
            .carrer-frame {
                width: 50%;
            }
        }
        @include breakpoint(xs) {
            align-items: center;
            flex-direction: column;
            .carrer-frame {
                width: 170px;
            }
        }
        @include breakpoint(sm-up) {
            width: 70%;
            margin: 0 auto;
            .carrer-frame {
                width: 50%;
            }
        }
        @include breakpoint(lg-up) {
            .carrer-frame {
                width: 33%;
            }
        }
    }
    &.career-corner_activity {
        .carrer-frame-block {
            .carrer-frame {
                .career-image {
                    @include z-index(nine);
                    transform: translate(-40%, -30%);
                    img {
                        width: 130px;
                    }
                }
                .career-badge {
                    position: absolute;
                    bottom: 20px;
                    right: 30px;
                    z-index: 9;
                    img {
                        width: 16px;
                    }
                }
                &:nth-of-type(2) {
                    .career-badge {
                        bottom: 26px;
                    }
                }
                &:nth-of-type(7) {
                    .career-image {
                        img {
                            width: 100px;
                        }
                    }
                    .career-badge {
                        bottom: 40px;
                        right: 102px;
                    }
                }
            }
        }
    }
}