.ct-footer {
  background-color: nth($black-shades, 7);
  @include position ($position: fixed, $bottom: 0px);
  color: nth($white-shades, 1);
  width: 100%;
  @include z-index(modal);
  p {
    padding: 5px 0px;
    text-align: center;
    font-size: nth($font-small-point, 4);
    margin: 0;
    color: nth($grey-shades, 6);
    @include breakpoint(sm) {
      font-size: nth($font-small-point, 8);
    }
  }
  .black-bg {
    background-color: nth($black-shades, 8);
    height: 5px;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  @include position ($position: fixed, $bottom: 0px);
  text-align: center;
  @include z-index(two);
}
