.navigator-intro {
  padding: 30px 0;
  .navigator-cards {
    margin: 5px;
    .mat-card {
      box-shadow: none;
      border: 1px solid nth($red-shades, 9);
    }
    .mat-card:not([class*="mat-elevation-z"]) {
      box-shadow: none;
    }
    .card-header {
      padding: 10px 0 0;
    }
    .card-body {
      min-height: 80px;
      img {
        width: 60px;
        height: 70px;
      }
    }
    &.support-card {
      .card-body {
        img {
          width: 90px;
        }
      }
    }
  }
  @include breakpoint(sm-up) {
    padding: 30px 20px;
    .navigator-cards {
      .card-body {
        min-height: 130px;
        img {
          width: 90px;
          height: 90px;
        }
      }
      &.support-card {
        .card-body {
          img {
            width: 130px;
          }
        }
      }
    }
  }
  &.content-box {
    @include breakpoint(sm) {
      margin-top: 0;
    }
  }
}

.navigator-activity {
  @include breakpoint(xl-up) {
    padding: 30px 20px;
  }
  @include breakpoint((lg, xl)) {
    padding: 30px 40px;
  }
  @include breakpoint(sm) {
    &.content-box {
      margin-top: 0;
    }
    .navigator-activity-two-main {
      margin-top: 30px;
    }
  }
  padding: 30px 0;
  .navigator-activity-block {
    display: flex;
    flex-direction: column;
    .emoji-outer {
      border: 2px dashed nth($black-shades, 5);
      border-radius: 100%;
      width: 250px;
      height: 250px;
      margin: auto;
      img {
        border: 40px solid nth($grey-shades, 21);
        border-radius: 100%;
        background: nth($grey-shades, 21);
        width: 100%;
      }
    }
    .work-activity {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 20px;
      .activity-image {
        position: relative;
        &::before {
          position: absolute;
          content: "";
          border: 3px solid nth($red-shades, 9);
          left: 5px;
          top: 3px;
          width: 90%;
          height: 90%;
          border-radius: 50%;
        }
      }
      img {
        width: 100px;
      }
      .audio-box {
        min-width: 26px !important;
        height: 26px;
        .material-icons {
          font-size: $default-font;
        }
      }
    }
    @include breakpoint(lg-up){
      &:first-child {
        .work-activity {
          &:nth-child(2n) {
            width: 212px;
          }
        }
      }
    }
    &:last-child {
      .work-activity {
        justify-content: start;
        @include breakpoint(lg-up){
          &:nth-child(2n) {
            width: 227px;
          }
        }
      }
    }
    @include breakpoint(lg-down) {
      flex-direction: row;
      justify-content: center;
      .work-activity {
        display: block;
        padding: 20px 10px;
        text-align: center;
        flex: 1;
        .activity-image {
          width: 70px;
          margin: auto;
          &::before {
            left: 4px;
            top: 3px;
            width: 89%;
            height: 88%;
          }
        }
        img {
          width: 70px;
        }
      }
    }
  }
  .navigator-activity-two {
    .mobile-heading {
      position: absolute;
      @include z-index(nine);
      &.right-arrow {
        right: 16px;
      }
    }
  }
  .next-level-button {
    width: 50px !important;
    height: 35px !important;
    border-radius: inherit;
    @include breakpoint(sm-up){
      bottom: 15px;
      right: 0;
      left: 0;
      top: inherit;
      border-radius: inherit;
      margin: auto !important;
      width: 60px !important;
      height: 40px !important;
    }
  }
}

.navigator_top-two-groups {
  padding: 30px 0;
  &.content-box {
      @include breakpoint(sm){
          margin-top: 0;
      }
  }
  .top-two-groups_body {
      @include breakpoint(lg-up){
          display: flex;
          align-items: center;
          justify-content: center;
      }
  }
  .top-two-values {
      @include breakpoint(lg-down){
          display: flex;
          .selected-work {
              flex: 1;
              width: 50%;
              text-align: center;
              position: relative;
              .inner-section {
                  margin-bottom: 100px;
                  img {
                      width: 70px;
                      height: 70px;
                      min-height: 75px;
                      margin-top: 15px;
                  }
              }
          }
      }
      .selected-work {
          .dashed-line {
              width: 120px;
              height: 1px;
              position: absolute;
              right: -20px;
              border: 1px dashed nth($black-shades, 5);
              transform: rotate(50deg);
              bottom: 45px;
          }
          &:last-child {
              .dashed-line {
                  transform: rotate(-50deg);
                  left: -20px;
                  @include breakpoint(lg-up){
                      transform: rotate(-25deg);
                      top: 40px;
                      left: inherit;
                  }
              } 
          }
          @include breakpoint(lg-up){
              height:170px;
              display: flex;
              .inner-section {
                  width: 275px;
                  justify-content: space-between;
                  display: flex;
                  align-items: center;
              }
              img{
                width: 40%;
                height: 100px;
                } 
              .dashed-line {
                  width: 200px;
                  position: relative;
                  right: -6px;
                  transform: rotate(25deg);
                  top: 125px;
              }
          }
      }
  }
  .navigator-activity-block {
      display: flex;
      flex-direction: column;
      .emoji-outer {
          border: 2px dashed nth($black-shades, 5);
          border-radius: 100%;
          width: 200px;
          height: 200px;
          margin: auto;
          img {
              border: 20px solid nth($grey-shades, 21);
              border-radius: 100%;
              background: nth($grey-shades, 21);
              width: 100%;
          }
          @include breakpoint(lg-up){
              width: 300px;
              height: 300px;
          }
      }
  }
}  

.navigator-results {
  padding: 30px 20px;
  &.content-box {
      @include breakpoint(sm){
          margin-top: 0;
      }
  }
  @include breakpoint((sm, lg)){
    .card-main-body {
      display: flex;
      .navigator-result-block {
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
        .card {
          .card-body {
            padding-bottom: 0;
          }
          .image-card {
            margin-bottom: 10px;
          }
        } 
        .card .image-card,
        .image-box img {
          width: 220px;
        }
      }      
    }    
  }
  
  .card {
    .card-body {
      @include breakpoint(lg-up){
        flex-direction: row !important; // This style required to override the base flex direction view
      }      
    }
      .image-card {
        margin: 0 10px;
        @include breakpoint(sm){
          width: 252px;
          margin: auto auto 5px;
        }
          .image-box {
              border: 2px solid nth($red-shades, 9);
          }
      }
  }
  .navigator-challenge {
      text-align: center;
      .you-are-text {
          padding: 10px;
      }
  }
  .navigator-result-block {
    margin-bottom: 20px;
    .image-box img {
      @include breakpoint(sm-up){
        width: 250px;
        height: 175px;

      }
    }
    .active-bar {
      .opacity-text p {
        &::before {
            background-color: nth($red-shades, 9);
        }
      }
    }
  }
} 

.navigator-tie {
  .navigator-two-tiecards {
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 1px nth($grey-shades, 1) !important;
    border: 1px solid nth($red-shades, 9) !important;
    @include breakpoint(sm) {
      width: auto;
    }
  }
  .nav-tie-list{
    padding: 10px !important;
  }
  @include breakpoint(sm){
    .select-cards .card {
      min-height: 100px;
    }

  }
}

.setDisplay{
  display: block !important;
}
.deSelectDisplay{
  display: none !important;
}