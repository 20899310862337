@use '@angular/material' as mat;
@import '@angular/material/theming';
@import "./lib/Abstracts/variables.scss";
@import "./lib/Abstracts/mixins.scss";
@include mat.core();
/*******    Explorer Theme Start   *********/
$mat-yellow-palette: (
  100: #fff9c4,
  200: #fff59d,
  300: #fff176,
  400: #fbcb52,
  500: #ffeb3b,
  600: #fdd835,
  700: #fbc02d,
  800: #f9a825,
  900: #f57f17,
  contrast: (100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text )
);
$explorer-custom-primary: mat.define-palette($mat-yellow-palette, 800);
$explorer-custom-accent: mat.define-palette($mat-yellow-palette, 400);
$explorer-custom-warn: mat.define-palette($mat-yellow-palette, 400);
$explorer-add-btn: mat.define-palette($mat-orange-palette, 700);
$explorer-custom-theme: mat.define-light-theme($explorer-custom-primary, $explorer-custom-accent, $explorer-custom-warn);

.explorer-theme {
  @include mat.all-component-themes($explorer-custom-theme);
  @include mat.button-color($explorer-custom-theme); // @include _mat-button-theme-color($explorer-custom-theme, color, $color: 'default');

  .common-popup .common-popup-header h2 {
    background-color: mat.get-color-from-palette($explorer-custom-primary);
  }

  /**** background color for theme ****/
  [background-color="primary"] {
    background-color: mat.get-color-from-palette($explorer-custom-primary);
  }

  [background-color="accent"] {
    background-color: mat.get-color-from-palette($explorer-custom-accent);
  }

  [background-color="warn"] {
    background-color: mat.get-color-from-palette($explorer-custom-warn);
  }

  /**** background color for theme ****/
  /**** text color for theme ****/
  [text-color="primary"] {
    color: mat.get-color-from-palette($explorer-custom-primary) !important;
  }

  .add-more-card {
    background-color: nth($yellow-shades, 6);
  }

  .mat-fab {
    &.mat-accent {
      background-color: mat.get-color-from-palette($explorer-custom-primary);
      /**** background color for explorer-theme mat-buttons ****/
    }
  }

  .volume-up .material-icons {
    background-color: transparent;
  }

  /**** text color for theme ****/
  /** ********************************************************************************** **/
  /** color: white and background-color:white for some design **/
  .mat-fab i,
  .mat-raised-button .mat-button-wrapper {
    color: nth($white-shades, 1);
  }

  .mat-progress-bar-buffer {
    background-color: nth($white-shades, 1);
  }

  .occupation-explorer .mat-tab-label-active .tab-button,
  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: mat.get-color-from-palette($explorer-custom-primary);
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: mat.get-color-from-palette($explorer-custom-primary);
  }

  /** ********************************************************************************** **/
  /** border for task page side image list **/
  .explorer-occuptation .side-task-list .image-card.active {

    &::after,
    &::before {
      border: 3px solid mat.get-color-from-palette($explorer-custom-primary);
      border-left: 0;
    }

    &::after,
    &::before {
      border-top: 6px solid mat.get-color-from-palette($explorer-custom-primary);
      border-bottom: 6px solid mat.get-color-from-palette($explorer-custom-primary);
    }

    .sprite-img-border {
      border-top: 3px solid mat.get-color-from-palette($explorer-custom-primary);
      border-right: 3px solid mat.get-color-from-palette($explorer-custom-primary);
      border-bottom: 3px solid mat.get-color-from-palette($explorer-custom-primary);
    }

    .sprite-img-border div {
      border-top: 2px solid mat.get-color-from-palette($explorer-custom-primary);
      border-bottom: 2px solid mat.get-color-from-palette($explorer-custom-primary);
    }

    .opacity-text p::before {
      background-color: mat.get-color-from-palette($explorer-custom-primary);
    }
  }

  /*** ******** ***/
  .explorer-intro .card {
    border: 1px solid mat.get-color-from-palette($explorer-custom-primary) !important;
  }

  @include breakpoint(sm) {
    .explorer-intro .card {
      border: 1px solid mat.get-color-from-palette($explorer-custom-primary) !important;

      .card-body {
        border-right: 1px solid mat.get-color-from-palette($explorer-custom-primary);
      }
    }
  }

  /*********** occ-library*******************/
  .custom-tabs {
    .explorer {

      .mat-tab-body,
      .circle-image-list-status.custom-library-circle .circle-img {
        border: 2px solid mat.get-color-from-palette($explorer-custom-primary);
      }

      .mat-tab-label {
        border-bottom: 2px solid mat.get-color-from-palette($explorer-custom-primary);
      }

      .mat-tab-label-active::before {
        border-top: 2px solid mat.get-color-from-palette($explorer-custom-primary);
        border-left: 2px solid mat.get-color-from-palette($explorer-custom-primary);
        border-right: 2px solid mat.get-color-from-palette($explorer-custom-primary);
      }
    }
  }

  /** ********************************************************************************** **/
}

/*******    Explorer Theme End   *********/

/*******    Pathfinder Theme Start   *********/
$mat-light-blue-palette: (
  100: #7fd9fe,
  200: #81d4fa,
  300: #4fc3f7,
  400: #29b6f6,
  500: #03a9f4,
  600: #029ce2,
  700: #0288d1,
  800: #0277bd,
  900: #01579b,
  contrast: (100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text )
);
$pathfinder-custom-primary: mat.define-palette($mat-light-blue-palette, 600);
$pathfinder-custom-accent: mat.define-palette($mat-light-blue-palette, 800);
$pathfinder-custom-warn: mat.define-palette($mat-light-blue-palette, 100);
$pathfinder-add-btn: mat.define-palette($mat-light-blue-palette, 700);
$pathfinder-custom-theme: mat.define-light-theme($pathfinder-custom-primary, $pathfinder-custom-accent, $pathfinder-custom-warn);

.pathfinder-theme {
  @include mat.all-component-themes($pathfinder-custom-theme);

  .common-popup .common-popup-header h2 {
    background-color: mat.get-color-from-palette($pathfinder-custom-primary);
  }

  /**** background color for theme ****/
  [background-color="primary"] {
    background-color: mat.get-color-from-palette($pathfinder-custom-primary);
  }

  [background-color="accent"] {
    background-color: mat.get-color-from-palette($pathfinder-custom-accent);
  }

  [background-color="warn"] {
    background-color: mat.get-color-from-palette($pathfinder-custom-warn);
  }

  /**** background color for theme ****/
  /**** text color for theme ****/
  [text-color="primary"] {
    color: mat.get-color-from-palette($pathfinder-custom-primary) !important;
  }

  .add-more-card {
    // background-color: mat-color($pathfinder-add-btn);
    background-color: nth($blue-shades, 15);
  }

  .mat-fab {
    &.mat-accent {
      background-color: mat.get-color-from-palette($pathfinder-custom-primary);
      /**** background color for pathfinder-theme mat-buttons ****/
    }

    .top-skills-layout .pathfinder-cards .top-two-cards.mat-card {
      // to override the default border of the mat-card.
      border: 1px solid mat.get-color-from-palette($pathfinder-custom-primary);
    }
  }

  @include breakpoint(sm) {
    .pathfinder-result .pathfinder-cards .mat-card {
      background-color: mat.get-color-from-palette($pathfinder-custom-primary);
    }

    .top-skills-layout .pathfinder-cards .mat-card.card-border,
    .pathfinder-result .pathfinder-cards .image-card {
      border: 1px solid mat.get-color-from-palette($pathfinder-custom-primary) !important;
    }

    .pathfinder-result .pathfinder-cards .image-card,
    .front_card,
    .back_card {
      color: mat.get-color-from-palette($pathfinder-custom-primary);
    }
  }

  /** ********************************************************************************** **/
  /** color: white and background-color:white for some design **/
  .mat-raised-button .mat-button-wrapper,
  .timer-panal p {
    color: nth($white-shades, 1) !important;
  }

  /************ occ-library***************/
  .custom-tabs {
    .pathfinder {

      .mat-tab-body,
      .circle-image-list-status.custom-library-circle .circle-img {
        border: 2px solid mat.get-color-from-palette($pathfinder-custom-primary);
      }

      .mat-tab-label {
        border-bottom: 2px solid mat.get-color-from-palette($pathfinder-custom-primary);
      }

      .mat-tab-label-active::before {
        border-top: 2px solid mat.get-color-from-palette($pathfinder-custom-primary);
        border-left: 2px solid mat.get-color-from-palette($pathfinder-custom-primary);
        border-right: 2px solid mat.get-color-from-palette($pathfinder-custom-primary);
      }
    }
  }

  .pathfinder-cards,
  .shared-occs-carousel,
  .carousel-card {
    .opacity-text p::before {
      background-color: mat.get-color-from-palette($pathfinder-custom-primary);
    }

    .image-card-inactive p::before {
      background-color: rgba(0, 0, 0, 0.6);
      ;
    }
  }

  .pathfinder-body .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: mat.get-color-from-palette($pathfinder-custom-primary);
  }

  /** ********************************************************************************** **/
}

/*******    Pathfinder Theme End   *********/

/*******   Careertrekker Theme Start   *********/
$mat-pink-palette: (
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #e91e63,
  600: #da316a,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  contrast: (200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text )
);
$careertrekker-primary: mat.define-palette($mat-pink-palette, 600);
$careertrekker-accent: mat.define-palette($mat-pink-palette, 700);
$careertrekker-warn: mat.define-palette($mat-pink-palette, 300);
$careertrekker-add-btn: mat.define-palette($mat-pink-palette, 500);
$careertrekker-theme: mat.define-light-theme($careertrekker-primary, $careertrekker-accent, $careertrekker-warn);

.careertrekker-theme {
  @include mat.all-component-themes($careertrekker-theme);
  @include mat.button-color($careertrekker-theme);
  @include mat.card-color($careertrekker-theme);

  .common-popup .common-popup-header h2 {
    background-color: mat.get-color-from-palette($careertrekker-primary);
  }

  [background-color="primary"] {
    background-color: mat.get-color-from-palette($careertrekker-primary);
  }

  [background-color="accent"] {
    background-color: mat.get-color-from-palette($careertrekker-accent);
  }

  [background-color="warn"] {
    background-color: mat.get-color-from-palette($careertrekker-warn);
  }

  [text-color="primary"] {
    color: mat.get-color-from-palette($careertrekker-primary) !important;
  }

  .mat-fab {
    &.mat-accent {
      background-color: mat.get-color-from-palette($careertrekker-primary);
    }
  }

  .add-more-card {
    background-color: nth($pink-shades, 5);
  }

  .mat-raised-button,
  .mat-raised-button.mat-warn {
    color: nth($white-shades, 1);
  }

  @include breakpoint(sm-up) {

    .career-trekker-into .card,
    .career-trekker-top-skills .selecting-cards,
    .career-trekker-top-skills .top-two-cards,
    .selected-cards .card {
      border: 1px solid mat.get-color-from-palette($careertrekker-primary);
    }
  }

  @include breakpoint(sm) {

    .career-trekker-top-skills .selecting-cards .card,
    .career-trekker-into .card {
      border: 1px solid mat.get-color-from-palette($careertrekker-primary);
    }
  }

  .occupation-explorer .mat-tab-label-active .tab-button,
  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: mat.get-color-from-palette($careertrekker-primary);
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: mat.get-color-from-palette($careertrekker-primary);
  }

  /********************** occ-library************************/
  .custom-tabs {
    .careertrekker {

      .mat-tab-body,
      .circle-image-list-status.custom-library-circle .circle-img {
        border: 2px solid mat.get-color-from-palette($careertrekker-primary);
      }

      .mat-tab-label {
        border-bottom: 2px solid mat.get-color-from-palette($careertrekker-primary);
      }

      .mat-tab-label-active::before {
        border-top: 2px solid mat.get-color-from-palette($careertrekker-primary);
        border-left: 2px solid mat.get-color-from-palette($careertrekker-primary);
        border-right: 2px solid mat.get-color-from-palette($careertrekker-primary);
      }
    }
  }

  // .career-trekker-result .opacity-text p::before {
  //   background-color: mat-color($careertrekker-primary);
  // }
  .career-trekker-result,
  .shared-occs-carousel {
    .opacity-text p::before {
      background-color: mat.get-color-from-palette($careertrekker-primary);
    }

    .image-card-inactive p::before {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
}

/*******   Careertrekker Theme End   *********/

/*******    Scout Theme Start   *********/
$mat-apple-green: (
  100: #dcedc8,
  200: #c5e1a5,
  300: #A4E64E,
  400: #9ccc65,
  500: #86C723,
  600: #7cb342,
  700: #689f38,
  800: #558b2f,
  900: #33691e,
  contrast: (100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text )
);
$scout-custom-primary: mat.define-palette($mat-apple-green, 500);
$scout-custom-accent: mat.define-palette($mat-apple-green, 300);
$scout-custom-warn: mat.define-palette($mat-apple-green, 300);
$scout-add-btn: mat.define-palette($mat-apple-green, 700);
$scout-custom-theme: mat.define-light-theme($scout-custom-primary, $scout-custom-accent, $scout-custom-warn);

.scout-theme {
  @include mat.all-component-themes($scout-custom-theme);
  @include mat.button-color($scout-custom-theme); // @include _mat-button-theme-color($scout-custom-theme, color, $color: 'default');

  .common-popup .common-popup-header h2 {
    background-color: mat.get-color-from-palette($scout-custom-primary);
  }

  /**** background color for theme ****/
  [background-color="primary"] {
    background-color: mat.get-color-from-palette($scout-custom-primary);
  }

  [background-color="accent"] {
    background-color: mat.get-color-from-palette($scout-custom-accent);
  }

  [background-color="warn"] {
    background-color: mat.get-color-from-palette($scout-custom-warn);
  }

  /**** background color for theme ****/
  /**** text color for theme ****/
  [text-color="primary"] {
    color: mat.get-color-from-palette($scout-custom-primary) !important;
  }

  .add-more-card {
    background-color: mat.get-color-from-palette($scout-custom-accent);
  }

  .mat-fab {
    &.mat-accent {
      background-color: mat.get-color-from-palette($scout-custom-primary);
      /**** background color for scout-theme mat-buttons ****/
    }
  }
}

/*******   scout Theme End   *********/

/*******    Discoverer Theme Start   *********/
$mat-deep-purple-palette: (
  100: #d1c4e9,
  200: #D164B7,
  300: #9541AC,
  400: #7e57c2,
  500: #673ab7,
  600: #5e35b1,
  700: #512da8,
  800: #4527a0,
  900: #311b92,
  contrast: (100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text )
);
$discoverer-custom-primary: mat.define-palette($mat-deep-purple-palette, 300);
$discoverer-custom-accent: mat.define-palette($mat-deep-purple-palette, 200);
$discoverer-custom-warn: mat.define-palette($mat-deep-purple-palette, 200);
$discoverer-add-btn: mat.define-palette($mat-deep-purple-palette, 700);
$discoverer-custom-theme: mat.define-light-theme($discoverer-custom-primary, $discoverer-custom-accent, $discoverer-custom-warn);

.discoverer-theme {
  @include mat.all-component-themes($discoverer-custom-theme);
  @include mat.button-color($discoverer-custom-theme); // @include _mat-button-theme-color($discoverer-custom-theme, color, $color: 'default');

  .common-popup .common-popup-header h2 {
    background-color: mat.get-color-from-palette($discoverer-custom-primary);
  }

  /**** background color for theme ****/
  [background-color="primary"] {
    background-color: mat.get-color-from-palette($discoverer-custom-primary);
  }

  [background-color="accent"] {
    background-color: mat.get-color-from-palette($discoverer-custom-accent);
  }

  [background-color="warn"] {
    background-color: mat.get-color-from-palette($discoverer-custom-warn);
  }

  /**** background color for theme ****/
  /**** text color for theme ****/
  [text-color="primary"] {
    color: mat.get-color-from-palette($discoverer-custom-primary) !important;
  }

  .add-more-card {
    background-color: mat.get-color-from-palette($discoverer-custom-accent);
  }

  .mat-fab {
    &.mat-accent {
      background-color: mat.get-color-from-palette($discoverer-custom-primary);
      /**** background color for discoverer-theme mat-buttons ****/
    }
  }
}

/*******   Discoverer Theme End   *********/

/*******   Navigator Theme Start   *********/
$mat-red-orange: (
  100: #ffccbc,
  200: #ffab91,
  300: #ff8a65,
  400: #ff7043,
  500: #F74D4D,
  600: #F79787,
  700: #e64a19,
  800: #d84315,
  900: #bf360c,
  contrast: (100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text )
);
$navigator-custom-primary: mat.define-palette($mat-red-orange, 500);
$navigator-custom-accent: mat.define-palette($mat-red-orange, 600);
$navigator-custom-warn: mat.define-palette($mat-red-orange, 200);
$navigator-add-btn: mat.define-palette($mat-red-orange, 700);
$navigator-custom-theme: mat.define-light-theme($navigator-custom-primary, $navigator-custom-accent, $navigator-custom-warn);

.navigator-theme {
  @include mat.all-component-themes($navigator-custom-theme);
  @include mat.button-color($navigator-custom-theme); // @include _mat-button-theme-color($navigator-custom-theme, color, $color: 'default');

  .common-popup .common-popup-header h2 {
    background-color: mat.get-color-from-palette($navigator-custom-primary);
  }

  /**** background color for theme ****/
  [background-color="primary"] {
    background-color: mat.get-color-from-palette($navigator-custom-primary);
  }

  [background-color="accent"] {
    background-color: mat.get-color-from-palette($navigator-custom-accent);
  }

  [background-color="warn"] {
    background-color: mat.get-color-from-palette($navigator-custom-warn);
  }

  /**** background color for theme ****/
  /**** text color for theme ****/
  [text-color="primary"] {
    color: mat.get-color-from-palette($navigator-custom-primary) !important;
  }

  .add-more-card {
    background-color: mat.get-color-from-palette($navigator-custom-accent);
  }

  .mat-fab {
    &.mat-accent {
      background-color: mat.get-color-from-palette($navigator-custom-primary);
      /**** background color for navigator-theme mat-buttons ****/
    }
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: mat.get-color-from-palette($navigator-custom-primary);
  }

  /************ occ-library***************/
  .occupation-explorer .custom-tabs {
    .navigator {

      .mat-tab-body,
      .circle-image-list-status .circle-img,
      .circle-image-list-status.custom-library-circle .circle-img {
        border: 2px solid mat.get-color-from-palette($navigator-custom-primary);
      }

      .mat-tab-label {
        border-bottom: 2px solid mat.get-color-from-palette($navigator-custom-primary);
      }

      .mat-tab-label-active::before {
        border-top: 2px solid mat.get-color-from-palette($navigator-custom-primary);
        border-left: 2px solid mat.get-color-from-palette($navigator-custom-primary);
        border-right: 2px solid mat.get-color-from-palette($navigator-custom-primary);
      }

      .occupation-explorer .tab-button {
        &.navigator {
          background-color: mat.get-color-from-palette($navigator-custom-primary);
        }
      }
    }
  }
}

/*******  Navigator Theme End   *********/

/*******   Career Corner Theme Start   *********/
$mat-cyan-palette: (
  100: #b2ebf2,
  200: #80deea,
  300: #4dd0e1,
  400: #2AC7B8,
  500: #00F5D4,
  600: #00acc1,
  700: #0097a7,
  800: #00838f,
  900: #006064,
  contrast: (100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text )
);
$career-corner-custom-primary: mat.define-palette($mat-cyan-palette, 400);
$career-corner-custom-accent: mat.define-palette($mat-cyan-palette, 500);
$career-corner-custom-warn: mat.define-palette($mat-cyan-palette, 200);
$career-corner-add-btn: mat.define-palette($mat-cyan-palette, 700);
$career-corner-custom-theme: mat.define-light-theme($career-corner-custom-primary, $career-corner-custom-accent, $career-corner-custom-warn);

.career-corner-theme {
  @include mat.all-component-themes($career-corner-custom-theme);
  @include mat.button-color($career-corner-custom-theme); // @include _mat-button-theme-color($career-corner-custom-theme, color, $color: 'default');

  .common-popup .common-popup-header h2 {
    background-color: mat.get-color-from-palette($career-corner-custom-primary);

    .volume-up {
      background-color: mat.get-color-from-palette($career-corner-custom-primary);
    }
  }

  /**** background color for theme ****/
  [background-color="primary"] {
    background-color: mat.get-color-from-palette($career-corner-custom-primary);
  }

  [background-color="accent"] {
    background-color: mat.get-color-from-palette($career-corner-custom-accent);
  }

  [background-color="warn"] {
    background-color: mat.get-color-from-palette($career-corner-custom-warn);
  }

  /**** background color for theme ****/
  /**** text color for theme ****/
  [text-color="primary"] {
    color: mat.get-color-from-palette($career-corner-custom-primary) !important;
  }

  .add-more-card {
    background-color: mat.get-color-from-palette($career-corner-custom-accent);
  }

  .mat-fab {
    &.mat-accent {
      background-color: mat.get-color-from-palette($career-corner-custom-primary);
      /**** background color for career-corner-theme mat-buttons ****/
    }
  }
}

/*******  Career Corner Theme End   *********/

/**********************************************************************/
$mat-teal-palette: (
  100: #81ccc5,
  200: #80cbc4,
  300: #4db6ac,
  400: #26a69a,
  500: #009688,
  600: #01756a,
  700: #00796b,
  800: #00695c,
  900: #004d40,
  contrast: (100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text )
);
$occ-explorer-primary: mat.define-palette($mat-teal-palette, 600);
$occ-explorer-accent: mat.define-palette($mat-teal-palette, 400);
$occ-explorer-warn: mat.define-palette($mat-teal-palette, 100);
$occ-explorer-theme: mat.define-light-theme($occ-explorer-primary, $occ-explorer-accent, $occ-explorer-warn);

.occ-explorer-theme {
  @include mat.all-component-themes($occ-explorer-theme);

  .common-popup .common-popup-header h2 {
    background-color: mat.get-color-from-palette($occ-explorer-primary);
  }

  [background-color="primary"] {
    background-color: mat.get-color-from-palette($occ-explorer-primary);
  }

  [text-color="primary"] {
    color: mat.get-color-from-palette($occ-explorer-primary) !important;
  }

  .occupation-explorer .tab-button {
    &.navigator {
      background-color: mat.get-color-from-palette($navigator-custom-primary);
    }

    &.scout {
      background-color: mat.get-color-from-palette($scout-custom-primary);
    }

    &.explorer {
      background-color: mat.get-color-from-palette($explorer-custom-primary);
    }

    &.pathfinder {
      background-color: mat.get-color-from-palette($pathfinder-custom-primary);
    }

    &.careertrekker {
      background-color: mat.get-color-from-palette($careertrekker-primary);
    }
  }

  .custom-tabs {
    .navigator {
      .mat-tab-label-container {
        border-right: 2px solid mat.get-color-from-palette($navigator-custom-primary);
        border-left: 2px solid mat.get-color-from-palette($navigator-custom-primary);
        border-top: 2px solid mat.get-color-from-palette($navigator-custom-primary);
      }

      .mat-tab-body {
        border-right: 2px solid mat.get-color-from-palette($navigator-custom-primary);
        border-left: 2px solid mat.get-color-from-palette($navigator-custom-primary);
        border-bottom: 2px solid mat.get-color-from-palette($navigator-custom-primary);
      }

      .circle-image-list-status.custom-library-circle .circle-img {
        border: 2px solid mat.get-color-from-palette($navigator-custom-primary);
      }
    }

    .scout {
      .mat-tab-label-container {
        border-right: 2px solid mat.get-color-from-palette($scout-custom-primary);
        border-left: 2px solid mat.get-color-from-palette($scout-custom-primary);
        border-top: 2px solid mat.get-color-from-palette($scout-custom-primary);
      }

      .mat-tab-body {
        border-right: 2px solid mat.get-color-from-palette($scout-custom-primary);
        border-left: 2px solid mat.get-color-from-palette($scout-custom-primary);
        border-bottom: 2px solid mat.get-color-from-palette($scout-custom-primary);
      }

      .circle-image-list-status.custom-library-circle .circle-img {
        border: 2px solid mat.get-color-from-palette($scout-custom-primary);
      }
    }

    .explorer {
      .mat-tab-label-container {
        border-right: 2px solid mat.get-color-from-palette($explorer-custom-primary);
        border-left: 2px solid mat.get-color-from-palette($explorer-custom-primary);
        border-top: 2px solid mat.get-color-from-palette($explorer-custom-primary);
      }

      .mat-tab-body {
        border-right: 2px solid mat.get-color-from-palette($explorer-custom-primary);
        border-left: 2px solid mat.get-color-from-palette($explorer-custom-primary);
        border-bottom: 2px solid mat.get-color-from-palette($explorer-custom-primary);
      }

      .circle-image-list-status.custom-library-circle .circle-img {
        border: 2px solid mat.get-color-from-palette($explorer-custom-primary);
      }
    }

    .pathfinder {
      .mat-tab-label-container {
        border-right: 2px solid mat.get-color-from-palette($pathfinder-custom-primary);
        border-left: 2px solid mat.get-color-from-palette($pathfinder-custom-primary);
        border-top: 2px solid mat.get-color-from-palette($pathfinder-custom-primary);
      }

      .mat-tab-body {
        border-right: 2px solid mat.get-color-from-palette($pathfinder-custom-primary);
        border-left: 2px solid mat.get-color-from-palette($pathfinder-custom-primary);
        border-bottom: 2px solid mat.get-color-from-palette($pathfinder-custom-primary);
      }

      .circle-image-list-status.custom-library-circle .circle-img {
        border: 2px solid mat.get-color-from-palette($pathfinder-custom-primary);
      }
    }

    .careertrekker {
      .mat-tab-label-container {
        border-right: 2px solid mat.get-color-from-palette($careertrekker-primary);
        border-left: 2px solid mat.get-color-from-palette($careertrekker-primary);
        border-top: 2px solid mat.get-color-from-palette($careertrekker-primary);
      }

      .mat-tab-body {
        border-right: 2px solid mat.get-color-from-palette($careertrekker-primary);
        border-left: 2px solid mat.get-color-from-palette($careertrekker-primary);
        border-bottom: 2px solid mat.get-color-from-palette($careertrekker-primary);
      }

      .circle-image-list-status.custom-library-circle .circle-img {
        border: 2px solid mat.get-color-from-palette($careertrekker-primary);
      }
    }
  }

  .mat-fab {
    &.mat-accent {
      background-color: mat.get-color-from-palette($occ-explorer-primary);
      /**** background color for explorer-theme mat-buttons ****/
    }
  }

  .scout-body .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: mat.get-color-from-palette($scout-custom-primary);
  }

  .navigator-body .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: mat.get-color-from-palette($navigator-custom-primary);
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: mat.get-color-from-palette($occ-explorer-primary);
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: mat.get-color-from-palette($occ-explorer-warn);
    ;
  }

  .explorer-body {

    .mat-radio-button.mat-accent .mat-radio-inner-circle,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: mat.get-color-from-palette($explorer-custom-primary);
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
      border-color: mat.get-color-from-palette($explorer-custom-warn);
      ;
    }
  }

  .pathfinder-body .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: mat.get-color-from-palette($pathfinder-custom-primary);
  }

  .trekker-body {

    .mat-radio-button.mat-accent .mat-radio-inner-circle,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: mat.get-color-from-palette($careertrekker-primary);
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
      border-color: mat.get-color-from-palette($careertrekker-warn);
      ;
    }
  }
}

/**********************************************************************/
$quickpic-primary: mat.define-palette(mat.$indigo-palette, 500);
$quickpic-accent: mat.define-palette(mat.$indigo-palette, 900);
$quickpic-warn: mat.define-palette(mat.$indigo-palette, 300);
$quickpic-theme: mat.define-light-theme($quickpic-primary, $quickpic-accent, $quickpic-warn);

.quick-pic-theme {
  @include mat.all-component-themes($quickpic-theme);

  .common-popup .common-popup-header h2 {
    background-color: mat.get-color-from-palette($quickpic-primary);
  }

  [background-color="primary"] {
    background-color: mat.get-color-from-palette($quickpic-primary);
  }

  [background-color="accent"] {
    background-color: mat.get-color-from-palette($quickpic-accent);
  }

  [text-color="primary"] {
    color: mat.get-color-from-palette($quickpic-primary) !important;
  }

  .instruction-data li:before {
    color: mat.get-color-from-palette($quickpic-primary);
  }

  .second-block .mat-button,
  .quickpic-restore .card .card-body {
    border: 1px solid mat.get-color-from-palette($quickpic-primary);
    border-radius: 5px;
  }

  .quickpic-result .side-task-list .active.image-card {

    &::after,
    &::before {
      border: 3px solid mat.get-color-from-palette($quickpic-primary);
      border-right: 0;
    }

    &::after,
    &::before {
      border-top: 10px solid mat.get-color-from-palette($quickpic-primary);
      border-bottom: 10px solid mat.get-color-from-palette($quickpic-primary);
    }

    .image-border {
      border-top: 3px solid mat.get-color-from-palette($quickpic-primary);
      border-left: 3px solid mat.get-color-from-palette($quickpic-primary);
      border-bottom: 3px solid mat.get-color-from-palette($quickpic-primary);
    }

    .opacity-text p {
      &::before {
        background-color: mat.get-color-from-palette($quickpic-primary);
      }
    }
  }
}

$own-occ-theme-primary: mat.define-palette(mat.$blue-grey-palette, 700);
$own-occ-theme-accent: mat.define-palette(mat.$blue-grey-palette, 600);
$own-occ-theme-warn: mat.define-palette(mat.$blue-grey-palette, 500);
$own-occ-theme-theme: mat.define-light-theme($own-occ-theme-primary, $own-occ-theme-accent, $own-occ-theme-warn);

.own-occ-theme {
  @include mat.all-component-themes($own-occ-theme-theme);

  [background-color="primary"] {
    background-color: mat.get-color-from-palette($own-occ-theme-primary) !important;
  }

  [background-color="accent"] {
    background-color: mat.get-color-from-palette($own-occ-theme-accent);
  }

  [text-color="primary"] {
    color: mat.get-color-from-palette($own-occ-theme-primary) !important;
  }

  [exp-theme-bg] {
    background-color: mat.get-color-from-palette($explorer-custom-primary);
  }

  @include breakpoint(sm) {
    [pf-theme-border-xs] {
      border: 1px solid mat.get-color-from-palette($pathfinder-custom-primary);
    }
  }
}

/*******  Career Adding page header buttons style globally   *********/
.common-occ-header-button {
  border-radius: 5px;
  line-height: 36px;
  padding: 0 16px;
  border: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: nth($white-shades, 1) !important;
  // Kept important to override the default mat-button margin. 
  margin-left: 10px !important;

  &.download-pdf-btn {
    background-color: nth($green-shades, 1);
  }

  &.delete-occ-header-btn {
    background-color: nth($red-shades, 2);
  }

  &.share-occ-header-btn {
    background-color: nth($powder-blue, 11);
  }

  &.save-occ-header-btn {
    background-color: nth($pink-shades, 13);
  }

  img {
    width: 24px;
  }

  i,
  img {
    margin-left: 10px;
  }

  @include breakpoint(sm) {
    display: block;
    position: fixed;
    right: 10px;
    bottom: 20px;
    padding: 0;
    @include z-index(nine);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 10px;

    &.download-pdf-btn {
      background-color: nth($green-shades, 1);
    }

    &.delete-occ-header-btn {
      bottom: 70px;
      background-color: nth($red-shades, 2);
    }

    &.share-occ-header-btn {
      bottom: 120px;
      background-color: nth($powder-blue, 11);
    }

    &.save-occ-header-btn {
      bottom: 120px;
    }

    img {
      margin: 0px;
      width: 20px;
    }

    i {
      margin-left: 0;
      font-size: nth($font-small-point, 3);
    }
  }
}