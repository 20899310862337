@mixin width-height($width, $height) {
  width: $width;
  height: $height;
}
// z-index
$z-index: (
  minus1             : -1,
  zero               : 0,
  one                : 1,
  two                : 2,
  nine               : 9,
  modal              : 99,
  sidemenu           : 999,
  loader             :1000
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}

@mixin custom-circle ($border-color: null, $width: null, $height: null, $background-color: null, $border-radius: null) {
  border: 2px solid $border-color;
  background-color: $background-color;
  color: nth($white-shades, 1);
  @include width-height($width, $height);
  border-radius: $border-radius;
  &.green {
    border: 2px solid nth($green-shades, 3);
  }
  &.orange {
    border: 2px solid nth($orange-shades, 1);
  }
  &.purple {
    border: 2px solid nth($purple-shades, 1);
  }
  &.yellow {
    background-color: nth($yellow-shades, 2);
  }
  &.light-blue {
    background-color: nth($blue-shades, 2);
  }
  &.pink {
    background-color: nth($pink-shades, 5);
  }
  &.indigo {
    background-color: nth($indigo-shades, 2);
  }
}

@mixin position ($position: null, $top: null, $bottom: null, $left: null, $right: null) {
  position: $position;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
}

@mixin after-before ($border: null, $top: null, $bottom: null, $right: null, $left: null, $border-radius: null, $width: null, $height: null, $z-index: null) {
  position: absolute;
  display: block;
  border: $border;
  top: $top;
  bottom: $bottom;
  right: $right;
  left: $left;
  width: $width;
  height: $height;
  border-radius: $border-radius;
  z-index: $z-index;
  background-color: nth($white-shades, 1)
}

/*********************
CUSTOM-SASS-VAR-BREAKPOINTS
*********************/
@mixin breakpoint($point) {
  @if $point == sm-h-up {
    @media (min-height:480px){
      @content;
    }
  }
  @if $point == xl-up {
    @media (min-width: 1280px) { 
      @content; 
    }
  }
  @if $point == xl-down {
    @media (max-width: 1279px) { 
      @content; 
    }
  }
  @if $point == xl-min {
    @media (min-width: 1200px) { 
      @content; 
    }
  }
  @if $point == lg-up {
    @media (min-width: 960px) { 
      @content; 
    }
  }
  @else if $point == lg-down {
    @media (max-width: 959px) { 
      @content; 
    }
  }
  @else if $point == sm-max {
    @media (max-width: 767px) { 
      @content; 
    }
  }
  @else if $point == sm-up {
    @media (min-width: 600px) { 
      @content; 
    }
  }
  @else if $point == sm {
    @media (max-width: 599px) { 
      @content; 
    }
  }
  @else if $point == xs-max {
    @media (max-width: 479px) { 
      @content; 
    }
  }
  @else if $point == xs {
    @media (max-width: 420px) { 
      @content; 
    }
  } 
  @else if $point == (xs, sm) {
    @media screen and (min-width: 480px) and (max-width: 599px) {
      @content;
    }
  }
  @else if $point == (sm, lg) {
    @media screen and (min-width: 600px) and (max-width: 959px) {
      @content;
    }
  }
  @else if $point == (lg, xl) {
    @media screen and (min-width: 960px) and (max-width: 1279px) {
      @content;
    }
  }
}