$default-font: 1rem;
$font-small-point: 0.75rem 1.35rem 1.2rem 0.85rem 0.70rem 0.65rem 0.95rem 0.60rem 0.80rem 0.90rem 1.75rem 1.5rem 1.07rem;
$font-medium-point: 2.50rem 2rem;
$font-large-points: 5rem 3rem 4rem 9rem;
$corner-radius: 8px;
$frame-margin: 2px;
$white-shades: #fff #e0dcdb #ededed #e5e3e4 #ebebeb #e4e4e4 #f3fff3 #f4f4f4 #eff5ef #ececec;
$black-shades: #000 #555555 #303030 #4e4e4e #616161 #333333 #474747 #272727 #6f6f6f #717171 #999999 #00000099;
$grey-shades: #ccc #eeeeee #d2d4d2 #b19c9c #dcd1d1 #d1ceca #e5e3e3 #607d8a #a2a2a2 #aba7a4 #c9c9c9 #cac7c7 #cbcbcb #c6edff #d6f2ff #a6a6a6 #b6b6b6 #d6d6d6 #d8d8d8 #c7c7c7 #f5f5f5;
$green-shades: green #00c853 #38bb15 #00bf36 #27bc04 #00c300 #00ac47 #08aa17 #008c3a #138641 #86C723 #A4E64E #18b237;
$pink-shades: #ec3773 #e96d96 #da316a #e91e63 #F06292 #6e156a #ab47bc #ea6d97 #ed3774 #ca1e58 #da316a #f06292 #9541AC #D164B7;
$yellow-shades: #f0be0f #fecb52 #f9a825 #ffb400 #d79803 #fecc53 #eaa22c #ffecb3 #f9c922#f9a825 #fbcb52;
$blue-shades: #0c0c0c #28b6f6 #039de3 #01579b #0082BE #0a82b9 #26b5f7 #508eff #02a8f2 #0288c5 #007bff #0056b3 #3498db #029ce2 #7fd9fe #0277bd;
$powder-blue: #26A69A #4DB6AC #1a8e83 #20a094 #11b9a9 #1b8e83 #0097A7 #00838F #209f94 #11b9a8 #2AC7B8 #00F5D4 #029ce2e6;
$purple-shades: #7986cb #3f51b5 #4051b5 #AE8AF4;
$indigo-shades: #5867bb #4e63d0 #3d52c7;
$teal-shades: #01756a #81ccc5;
$orange-shades: #fa7b26 #ff692e #f87a25 #ff5722 #ff9900;
$red-shades: red #ff6767 #ff4f4f #fc4747 #FF4C4C #ff8182 #e74c3c #F79787 #F74D4D #f3695c;
$dark-grey: #527b8c #455f6c #546f7a #817f81 #8a8a8a #444 #ffffff3d #c3bebe61 #a09b9b #868e96 #7E7E7E #999 #757070;
$board-box-colors: #ff6767 #f50057 #4b830d #512da7 #004b9f #f4511e #1665c1;
$board-box-colors-coo:  #4b830d  #1665c1 #f50057 #512da7  #ff6767;