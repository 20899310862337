.occupation-explorer{
  // margin-top: 60px;
  margin-bottom: 35px;
}

// .infinet-scroll-height {
//   height: 500px;
//   // overflow-x: hidden;
//   // overflow-y: scroll;
// }

.occupation-explorer {
  padding: 10px 14px 12px 6px;
  .occ-card-no-result{
    max-width: 100%!important; // This is overrrided by Flex widths of device resolution max-width
    @include breakpoint(lg-up) {
      display: flex;
      align-items: center;
    }
  }
  @include breakpoint(sm) {
    .career-challenge-block {
      .card-body {
        min-height: 55px;
        padding: 10px 0;
      }
      .challenge-button {
        font-size: nth($font-small-point, 3);
        padding: 0px 20px;
        border-radius: 4px;
        margin: auto;
      }
    }
  }
  .mat-tab-label-content {
    white-space: pre-wrap !important;
    margin: 1px;
  }
  .card-list {
    position: relative;
    .active-bar{
      background-color: nth($yellow-shades, 3);
    }
    .card {
      overflow: hidden;
      overflow-x: hidden;
      padding: 0px !important;
      min-height: 175px;
      max-height:175px;
      .opacity-text {
        p {
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            height: 40px;
          }
          span {
            font-size: nth($font-small-point, 4);
          }
          img {
            width: 25px;
          }
        }
      }
    }
    &.largedevice-design-show {
      .opacity-text {
        p.scout {
          &::before {
            background-color: nth($green-shades, 11);
          }
        }
        p.navigator {
          &::before {
            background-color: nth($red-shades, 9);
          }
        }
        p.explorer {
          &::before {
            background-color: nth($yellow-shades, 3);
          }
        }
        p.pathfinder {
          &::before {
            background-color: nth($blue-shades, 14);
          }
        }
        p.careertrekker {
          &::before {
            background-color: nth($pink-shades, 3);
          }
        }
      }
    }
  }
  .circle-img {
    margin: 0px 10px;
    .mat-checkbox label {
      margin-bottom: 0;
    }
  }
  .mat-tab-label {
    img {
      width: 24px;
      margin: 0 2px 0 0;
    }
    span {
      margin: 0px 2px;
    }
  }
  .tab-button {
    color: nth($white-shades, 1);
    border-radius: 6px;
    padding: 2px 0px;
    min-height: 36px;
    height: 36px;
    width: 100%;
    border: none;
    box-shadow: none;
    &.library-single-tab{
      width: 100%;
    }
  }
  .mat-tab-label-active {
    .tab-button {
      background-color: nth($white-shades, 1);
      color: nth($black-shades, 5);
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .tab-name {
        font-weight: 600;
      }
    }
  }
  .mat-tab-body-content {
    @include breakpoint(lg-down) {
      height: calc(72vh - 40px);
      padding: 5px;
    }
  }
  .sprite {
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    background-repeat: no-repeat;
    position: relative;
    width: 220px !important;
    height: 128px !important;
  }
  .card {
    box-shadow: none;
    margin: 5px;
    @include breakpoint(lg-down) {
      margin: 2px;
    }
  }
  .mat-tab-label,
  .mat-tab-label-content {
    // width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .mat-tab-header-pagination-before,
  .mat-tab-header-rtl .mat-tab-header-pagination-after,
  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none !important;
  }
  .mat-step-header,
  .mat-stepper-horizontal-line {
    display: none;
  }
  .mat-horizontal-content-container {
    padding: 0;
    @include breakpoint(lg-down) {
      background: nth($grey-shades, 7);
    }
  }
  @include breakpoint(lg-down){
    .filter-option {
      width: 50%;
      min-height: 45px;
      height: 45px;
      position: relative;
      overflow: hidden;
      .filter-button {
        position: relative;
        height: 100%;
        right: 0;
      }
      .audio-box {
        line-height: 25px !important;
      }
      p {
        font-size: $default-font;
      }
    }
    .filter-list {
      .thumb-icon {
        width: 24px;
      }
      // height: calc(65vh - 50px);
      height: inherit !important;
      background: nth($white-shades, 1);
      border-radius: 4px;
      margin: 5px 0 35px;
      overflow-y: auto;
      overflow-x: hidden;
      display:flex;
      padding: 0 5px;
      .mat-list-item {
        height: 30px !important;
        color: nth($blue-shades, 6) !important;
        border-bottom: 1px solid nth($grey-shades, 14);
        font-size: nth($font-small-point, 4) !important;
        cursor: pointer;
        .navigator {
          color: nth($red-shades, 9);
        }
        .scout {
          color: nth($green-shades, 11);
        }
        .explorer {
          color: nth($yellow-shades, 3);
        }
        .pathfinder {
          color: nth($blue-shades, 14);
        }
        .careertrekker {
          color: nth($pink-shades, 3);
        }
      }
      .mat-list-item:nth-last-child(1) {
        border-bottom: none;
      }
      .mat-list {
        padding: 0;
        border-bottom: 1px solid nth($grey-shades, 15);
      }
      .mat-list:last-child {
        border-bottom: 0px solid nth($grey-shades, 15);
      }
    }
    .mat-fab {
      box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.3) !important;
    }
  }
  @include breakpoint(lg-down) {
    .infinite-scroll-block {
      display: none !important;
    }
    .mat-tab-body-content {
      height: inherit;
      padding: 0;
    }
    .mat-tab-label-active {
      .tab-button {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

    }
  }
  .next-level {
    @include breakpoint(lg-up) {
      display: none !important;
    }
    @include breakpoint(lg-down) {
      display: block;
    }
  }
  .custom-nodata-available-text{  
    @include width-height(100%, 300px); //aligned content at top when no data was found for all modules in career-library
    margin: auto;
  }
}
// Below style properties are need to design custom tooltop looks same as hover text on tabs
.mat-tooltip.custom-tooltip {
  padding: 5px;
  font-size: nth($font-small-point, 5);
  font-weight: 500;
  white-space: nowrap;
  color: nth($black-shades, 1);
  border-radius: 50px;  
  background-color: nth($white-shades, 1);
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 
              0 6px 10px 0 rgba(0,0,0,.14), 
              0 1px 18px 0 rgba(0,0,0,.12);
}

.tooltip {
  display: inline;
  position: relative;
}
.tooltip:hover:after{
  display: flex;
  background: nth($dark-grey, 6);
  border-radius: 8px;
  color: nth($white-shades, 1);
  content: attr(title);
  position: absolute;
  margin: -51px auto 0;
  font-size: 13px;
  padding: 12px;
}
.tooltip:hover:before{
  border: solid;
  border-color: nth($dark-grey, 6) transparent;
  border-width: 6px 6px 0 6px;
  content: "";
  left: 20%;
  bottom: 28px;
  position: absolute;
}
.filter-list-height-mobile{
  height: 240px;
  overflow-x: hidden;
  overflow-y: scroll;
}