/********** Landing page **********/
$header-yellow: #f9a825;
$header-sky-blue: #029ce2;
$header-teal:#01756a;
$header-indigo:  #3F51B5;
$body-yellow: #fbcb52;
$body-sky-blue:  #7fd9fe;
$body-pink: #f06292;
$body-teal:#81ccc5;
$body-indigo:  #AE8AF4;
$blk-clr:#363636;
.Career-trek-content {
 /* All pages popup scrolling style */
    overflow-y: auto;
    //The below property was letting the scroll happen and not obstructing in achieving the infinity scroll in large screen.
    &.occs-content-page {
      overflow-y: inherit;
    }
  &.landing-img {
    &.scroll-profile {
      overflow-y: auto !important;
    }
    
    @include breakpoint(sm) {
      background-color: nth($white-shades, 4);
    }
  }
  .career-creator-nav {
    margin-top: 10px;
    padding-bottom: 20px;
    img {
      width: 45px;
      margin-right: 5px;
    }
    .creator-text {
      padding: 20px 0;
    }
    .creator-button {
      font-size: nth($font-small-point, 12);
      border-radius: 4px;
      padding: 5px 20px;
      &.mat-raised-button {
        color: nth($white-shades, 1);
      }
    }
    @include breakpoint(sm) {
      img {
        width: 35px;
      }
      .creator-button {
        font-size: nth($font-small-point, 3);
        padding: 2px 10px;
      }
    }
  }
  .heading-height{ 
    @include breakpoint(sm-up) {
      height: 35px; //To give a specific height for header text in Career library page    
    }
  }
  .landing-page-heading {
    margin: 90px 10px 0;
    color: nth($dark-grey, 13);
    font-size: nth($font-small-point, 11);
    text-align: center;
    @media(min-width: 768px){
    height: 35px;
    }
    .audio-box {
      margin-left: 5px;
      vertical-align: middle;
    }
    .custom-occ-audio {
      background-color: nth($green-shades, 2);
    }
    @include breakpoint ((sm, lg)) {
      font-size: nth($font-small-point, 2);
    }
    @include breakpoint(sm) {
      margin: 80px 10px 10px;
      font-size: nth($font-small-point, 3);
    }
  }
  .delete-occ-btn {
    margin: 0px  0px  30px;
    img {
      width: 20px;
    }
  }
  .two-cards {
    .occupation-library {
      @include breakpoint(lg-down) {
        display: none;
      }
    }
    .card {
      .card-body {
        min-height: 198px !important;
        height: 198px !important;
        @include breakpoint(lg-down) {
          min-height: 130px !important;
          height: 130px !important;
        }
        @include breakpoint(sm) {
          min-height: 70px !important;
          height: 70px !important;
        }
      }
    }
    .create-occupation {
      @include breakpoint(lg-down) {
        display: none !important;
      }
    }
  }
  .tab-design-show {
    @include breakpoint(lg-up) {
      display:none;
    }
    @include breakpoint(lg-down) {
      display: block;
      padding-top: 5px;
    }
  }
  @include width-height(100%, 100%);
  position: absolute;
  .landing-page {
    padding: 10px;
    .carousel-section {
      overflow: hidden;
      position: relative;
    }
    .jump {
      outline: 0;
    }

    &.content-box {
      @include breakpoint(sm) {
        width: 460px;
        margin: 20px auto 0;
        background-color: nth($white-shades, 1);    
      }
      @include breakpoint(xs-max) {
        width: 300px;  
      }
    }
    .card {
      button {
        border: none;
        width: 100%;
        background: nth($white-shades, 1);
        padding: 0;
      }
      .opacity-color {
        @include position ($position: absolute, $bottom: 0px, $left: 0px);
        width: 100%;
        padding: 5px;
        text-align: center;
        p {
          position: relative;
          color: nth($white-shades, 1) !important;
        }
        &.yellow {
          background-color: nth($yellow-shades, 1) !important;
        }
        &.blue {
          background-color: nth($blue-shades, 3) !important;
        }
        &.pink {
          background-color: nth($pink-shades, 3) !important;
        }
        &.teal {
          background-color: nth($powder-blue, 3) !important;
        }
        &.indigo {
          background-color: nth($indigo-shades, 2) !important;
        }        
      }
      @include breakpoint(sm) {
        border: 2px solid nth($white-shades, 1) !important;
      }
      .card-header {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        position: absolute;
        width: 100%;
        font-size: $default-font;
        font-weight: bold;
        @include breakpoint(sm) {
          padding: 10px 0;
        }
        &.header-yellow {
          background-color: nth($yellow-shades, 3);
        }
        &.header-sky-blue {
          background-color: nth($blue-shades, 14);
        }
        &.header-pink {
          background-color:nth($pink-shades, 3);
        }
        &.header-teal {
          background-color: nth($teal-shades, 1);
        }
        &.header-indigo {
          background-color: nth($purple-shades, 2);
        }
        &.header-violet {
          background-color: nth($pink-shades, 13);
        }
        &.header-apple-green {
          background-color: nth($green-shades, 11);
        }
        &.header-red-orange {
          background-color: nth($red-shades, 9);
        }
        &.header-aqua {
          background-color: nth($powder-blue, 11);
        }
        .checkmark-box {
          @include breakpoint(xl-down){
            padding: 10px 3px;
          }
          @include breakpoint(lg-down){
            padding: 10px;
          }
        }
      }
      .card-body {
        min-height: 345px;
        height: 345px;
        @include breakpoint(lg-down) {
        min-height: 130px;
        height: 130px;
        }
        .circle-image {
          position: relative;
          &.navigator-module-image {
            img {
              width: 120px;
              height: 120px;
            }
            @include breakpoint(lg-down) {
              img {
                width: 70px;
                height: 70px;
              }
            }
          }
          img {
            width: 100px;
            height: 100px;
          }
          @include breakpoint(lg-down) {
            img {
              width: 50px;
              height: 50px;
            }
            top: 15px;
          }
          @include breakpoint(sm) {
            @include custom-circle ($border-color: none, $width: null, $height: null, $border-radius: 0, $background-color: transparent !important);
            border: none;
          }
        }
        &.pink {
          background-color: nth($pink-shades, 5);
        }
        &.yellow {
          background-color: nth($yellow-shades, 11);
        }
        &.sky-blue {
          background-color: nth($blue-shades, 15);
        }
        &.teal {
          background-color: nth($teal-shades, 2);
          height: 165px;
          @include breakpoint(lg-up) {
            height: 398px;
          }
        }
        &.indigo {
          background-color: nth($purple-shades, 4);
          height: 165px;
          @include breakpoint(lg-up) {
            height: 398px;
          }
        }
        &.violet {
          background-color: nth($pink-shades, 14);
        }
        &.apple-green {
          background-color: nth($green-shades, 12);
        }
        &.red-orange {
          background-color: nth($red-shades, 8);
        }
        &.aqua {
          background-color: nth($powder-blue, 12);
          height: 165px;
          @include breakpoint(lg-up) {
            height: 398px;
          }
        }
      }
      
      .create-own-occ {
        color: nth($dark-grey, 4);
        .score-section {
          box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.2);
          padding: 10px 0px;
          @include breakpoint(lg-down) {
            padding: 5px 0px;
            border:none;
          }
          &.yellow{
            background-color: nth($yellow-shades, 3);
          }
          &.sky-blue{
            background-color: nth($blue-shades, 3);
          }
          &.pink{
            background-color: nth($pink-shades, 3);
          }
          span {
            margin: 2px auto;
            font-size: nth($font-small-point, 4);
            width: 40%;
            font-weight: bold;
            color: nth($dark-grey, 4);
          }
          button {
            box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
            width: 45%;
            background-color: nth($grey-shades, 2);
            line-height: 25px;
            margin: auto;
            @include breakpoint(lg-down) {
              width: 40%;
              line-height: 20px;
              font-size: nth($font-small-point, 1);
            }
          }
        }
        .occupation-section {
          min-height: 53px;
          height: 53px;
          background-color:nth($grey-shades,7) ;
          p {
            height: 53px;
          }
          @include breakpoint(lg-down) {
            min-height: 35px;
            height: 35px;
            border:none;
            p {
              height: 35px;
            }
          }
          &.explorer{
            border: 2px solid $header-yellow;
          }
          &.path-finder{
            border: 2px solid $header-sky-blue;
          }
          &.career-trekker{
            border: 2px solid nth($pink-shades, 3);
          }
          &.violet{
            border: 2px solid nth($pink-shades, 13);
            .career-title {
              color: nth($pink-shades, 13);
            }
          }
          &.apple-green{
            border: 2px solid nth($green-shades, 11);
            .career-title {
              color: nth($green-shades, 11);
            }
          }
          &.red-orange{
            border: 2px solid nth($red-shades, 9);
            .career-title {
              color: nth($red-shades, 9);
            }
          }
          img {
            width: 40px;
            height: 40px;
            margin: 5px;
            @include breakpoint(lg-down) {
              width:30px;
              height: 30px;
              margin: 2px;
            }
          }
          i {
            margin: 5px;
          }
          span {
            font-size: nth($font-small-point, 4);
          }
          // &:focus{
          //   display: inline-block;
          //   width: 99.7%;
          // }
        }
      }
    }
    .checkmark-box {
      padding: 10px;
      position: absolute; /* These styles for tick mark box right corner alignment on card header box, And not effect to center align header text */
      right: 0;
      top: 0;
      .success-tick {
        background-color: nth($white-shades, 1);
        color: nth($green-shades, 1);
        right: 0;
      }
    }
  }
  .mobile-design-show {
    .mobile-card {
      margin: 2px 0 !important;
      display: flex !important;
      border: none !important;
      box-shadow: 0 0px 8px 1px rgba(0, 0, 0, 0.1) !important;
      .occ-title {
        min-height: calc(14vh - 10px);
        height: calc(14vh - 10px) ;
        height: 92%;
        margin: 2px !important;
        span {
          color: nth($white-shades, 1);
          font-size: $default-font;
        }
        &.yellow {
          background-color: nth($yellow-shades, 3);
        }
        &.blue {
          background-color: nth($blue-shades, 14);
        }
        &.pink {
          background-color: nth($pink-shades, 3);
        }
        &.indigo {
          background-color: nth($purple-shades, 3);
        }
        &.teal {
          background-color: nth($teal-shades, 1);
          padding: 10px;
        }
        img {
          width: 45px;
          margin: 10px 5px;
          height: 45px;
        }
      }
      .score-section {
        span {
          font-size: nth($font-small-point, 5);
        }
        button {
          width: 100%;
          background-color: nth($green-shades, 8);
          color: nth($white-shades, 1);
          height: 20px;
          min-width: 65px !important;
          .mat-button-wrapper {
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px 2px;
            font-weight: normal !important;
          }
        }
      }
      .border-left {
        border-left: 2px solid nth($grey-shades, 3);
      }
      .create-occ-section {
        img {
          width: 25px;
        }
        i {
          font-size: $default-font;
          color: nth($dark-grey, 4);
        }
        span {
          font-size: nth($font-small-point, 7);
        }
      }
    }
  }
}
.landing-page{
  .yellow-text{
    color: $header-yellow;
  }
}
.landing-page{
  .pink-text{
    color: nth($pink-shades, 3);
  }
}
.landing-page{
  .skyblue-text{
    color: $header-sky-blue;
  }
}

.mat-snack-bar-container.mat-snack-bar-center {
  transform: translate(-50%, 0) !important; /* These styles for snackbar bottom alignment without extra outer scroll glitch */
}
.mat-snack-bar-handset {
  width: auto !important;
  .mat-snack-bar-container {
    margin: 0 auto !important;
    width: 260px !important;
    max-width: 260px !important; /* These styles for snackbar box */
  }
}
.mat-simple-snackbar {
  justify-content: center !important; /* These styles for snackbar box text center */
}
.carousel-onecard-width{

  @include breakpoint ((sm, lg)) {
    width: 533px!important;
  }
  @include breakpoint (xl-down) {
    width: 470px;
  }
  width: 600px;
}
.carousel-twocard-width{
  width: 366px;
  @include breakpoint ((sm, lg)) {
    width: 267px!important;
     // important is used as it is overiding width from xl-down
  }
  @include breakpoint (xl-down) {
    width: 310px;
  }
}
.carousel-threecard-width{
  width: 267px;
  @include breakpoint (xl-down) {
    width: 227px;
  }
  @include breakpoint (sm) {
    width: 218px;
  }
  @include breakpoint(xs-max) {
    width: 276px;  
  }
  @include breakpoint ((sm, lg)) {
    width: 267px;
  }

}
.carousel-section {
  .right,
  .left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 47px;
    @include z-index(one);
    .material-icons {
      font-weight: bold;
      font-size: nth($font-large-points, 2);
    }
  }
 .right{
    right: 0;
  }
  .left{
    left: 0;
  }
}
.carousel-card {
	overflow: hidden;
  @include z-index(one);
  position: relative;
    @include breakpoint(lg-up){
      .carousel-width{
        // width: 600px;
        overflow: hidden;
        @include z-index(one);
        position: relative;
        // .carousel-card-width{
        //   // width:600px;
        // }
      }
      .image-card {
        display: flex;
        justify-content: center;
        @include z-index(one);
      }
    }
  }
  .shared-occs-carousel{
    width: 100%;
    // overflow: hidden;
    position: relative;
    @include breakpoint (xl-down) {
      width: 909px;
    }
    @include breakpoint ((sm, lg)) {
      width: 536px;
    }
    @include breakpoint (sm) {
      width: 440px;
    }
    @include breakpoint (xs-max) {
      width: 280px;
    }
  }
  .carousel {
    position:relative;
    button {
      width: auto !important;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
  @include breakpoint (xl-up) {
   .lg-down-chevrolet{
     display: none;
  }
}
@include breakpoint ((lg, xl)) {
  .lg-up-chevrolet{
    display: none;
 }
}
@include breakpoint ((sm, lg)) {
  .lg-down-chevrolet{
    display: none;
 }
}
