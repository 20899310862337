.score-data {
  margin: 0;
  color: nth($white-shades, 1);

  @include breakpoint(sm) {
    color:nth($pink-shades, 4) !important;
    font-weight: bold !important;
  }
  font-size: nth($font-small-point, 3);
  p {
    color: nth($white-shades, 1);
    font-size: nth($font-small-point, 4);
    font-weight: bold;
    margin: 0;
    @include breakpoint(sm) {
      color: nth($pink-shades, 4);
    }
  }
  .progress-bar {
    background: nth($grey-shades, 1);
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    @include breakpoint(sm) {
      width: 85%;
    }
    @include breakpoint(xs) {
      width: 80%;
    }
  }
  .score {
    font-weight: bold;
    color: nth($white-shades, 1);
    font-size: nth($font-small-point,4);
    line-height: 1;
    @include custom-circle ($border-color: nth($white-shades, 1), $width: 30px, $height: 30px, $background-color: nth($yellow-shades, 3), $border-radius: 50%);
  }
  @include breakpoint(sm) {
    color: nth($white-shades, 1);
    font-size: nth($font-small-point, 7);
    .score-data .progress-bar {
      padding: 2px;
    }
    .score-data p {
      color: nth($blue-shades, 4) !important;

    }
    .score {
      margin-top: 0px;
    }
  }
}
